export const Action = { 
  VIEW: 'view',
  CREATE: 'create', 
  UPDATE: 'update'
}

export const EntryStatus = {
  ACTIVE: 'active',
  PENDING: 'pending',
  DEACTIVATED: 'deactivated'
}

export const SystemSettings = {
    MAINTENANCE_MODE: 'maintenance_mode',
    SYSTEM_NOTIFICATION: 'system_notification'
}
