import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Router from "vue-router"
import router from './router/router'
import axios from "axios"
import VueAxios from "vue-axios"
import Vuex from 'vuex'
import moment from 'moment'
import store from './store/store.js'
import 'vuetify/dist/vuetify.min.css'

Vue.config.productionTip = false

Vue.use(Router)
Vue.use(VueAxios, axios)
Vue.use(Vuex)

let host = window.location.origin
Vue.axios.defaults.baseURL = `${host}/api`

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY')
  }
})

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm')
  }
})

Vue.filter('formatShortDate', function(value) {
  if (value) {
    return moment(String(value)).format('MMM DD, YYYY')
  }
})

Vue.filter('formatShortDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('MMM DD, YYYY HH:mm')
  }
})

Vue.filter('truncate', function (text, length, suffix) {
  if (text?.length > length) {
      return text.substring(0, length) + suffix;
  } else {
      return text
  }
});

async function init() {
  await store.init()
  new Vue({
    vuetify,
    router: router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
init()