<template>
	<div class='EntryList'>
		<Loading v-if="loading" />
		<div v-if="message" class="message">
			{{ message }}
		</div>
		<div v-for="entry of matchedEntries" :key="entry.sys.id"
			:class="{
				entry: true,
				selectable: selectable,
				selected: entry.selected,
				marked: entry.marked,
			}"
			@click="clickEntry(entry)">
			<v-row>
				<v-col>
					<!-- TODO: locale -->
					{{ entry.fields.title ? entry.fields.title.de : 'NO_TITLE' }}
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay'

export default {
	name: 'EntryList',
	components: { Loading },
	props: {
		locale: String,
		filter: Object,
		selectable: Boolean,
		markEntries: Array,
	},
	data: () => ({
		loading: false,
		entries: [],
		timeout: null,
		currentFilter: {
			contentType: null,
			search: '',
		},
		message: '',
	}),
	watch: {
		filter: {
			deep: true,
			handler() {
				this.filterChanged()
			},
		},
	},
	mounted() {
		this.filterChanged()
	},
	methods: {
		async filterChanged() {
console.log('filterChanged', this.filter, this.currentFilter)
			if (this.filter.contentType != this.currentFilter.contentType) {
				await this.loadEntries()
				this.currentFilter.contentType = this.filter.contentType
			}
			for (let entry of this.entries) {
				entry.match = false
					|| this.filter.search == ''
					|| entry.search.indexOf(this.filter.search.toLowerCase()) >= 0
					|| entry.sys.id.toLowerCase() == this.filter.search.toLowerCase()
			}
		},
		async loadEntries() {
			// TODO: instead of preventing: load only part
			//       prevent picking of type "media" - there are too many entries!
			if (this.filter.contentType == 'media') {
				this.entries = []
				this.message = 'too many entries.'
				return
			}
			this.message = ''

			this.loading = true
			try {
				let res = await this.$httpGet(`/content/entries`, {
					contentType: this.filter.contentType,
					// search: this.filter.search,
					// TODO: service implementation for this!
					//       only load certain data (sys.id, fields.title.*, sys.contentType.sys.id)!
					select: 'minimal',
				})
				let marks = {}
				if (this.markEntries) {
					for (let entry of this.markEntries) {
						marks[entry.sys.id] = entry
					}
				}
				for (let entry of res) {
					entry.match = false
					entry.search = entry.fields.title ? JSON.stringify(Object.values(entry.fields.title)).toLowerCase() : ''
					entry.selected = false
					entry.marked = !!marks[entry.sys.id]
				}
				this.entries = res
				this.loading = false
			}
			catch (e) {
				this.message = e.message == 'Request failed with status code 403' ? 'Unauthorized' : 'Error occured'
				this.entries = [];
				this.loading = false
			}
			
		},
		clickEntry(entry) {
			if (this.selectable) {
				entry.selected = !entry.selected
				this.$emit('input', this.entries.filter((e) => e.selected))
			}
			else {
				this.$emit('input', [ entry ])
			}
		},
	},
	computed: {
		matchedEntries() {
			let r = []
			for (let entry of this.entries) {
				if (!entry.match) continue
				r.push(entry)
			}
			return r
		},
	},
}
</script>

<style scoped>
.entry { margin-bottom: 10px; padding:10px; border-radius: 5px; border: 1px solid #ccc; cursor: pointer; }
.entry.selectable:hover { border-color: dodgerblue; }
.entry.selected { border-color: dodgerblue; background-color: aliceblue; }
.entry.marked { border-left: 3px solid dodgerblue; }
.header { color: gray; font-size: smaller; }
</style>