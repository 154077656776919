<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>
					&nbsp;<h2>{{ $store.state.selectedProject }} Release {{ release.version }}</h2>
				</v-row>
			</div>
		</div>
		
		<SideBar>
			<div class="buttonBlock">
				<v-btn block class="btn blue mt-3" elevation="0" dark @click="upsertRelease()"><v-icon>mdi-check</v-icon>Save Changes</v-btn>
				<v-btn block class="btn red mt-3" elevation="0" dark @click="deleteRelease()" v-if="$store.state.selectedRelease"><v-icon>mdi-delete</v-icon>Delete Release</v-btn>
				<v-select outlined required hide-details class="mt-3"
					v-model="selectedCampaign"
					return-object
					:items="emmaCampaigns"
					:item-text="campaign => campaign.name"
					:item-value="campaign => campaign.id"
					placeholder="EMMA Campaign"
				/>
				<v-btn block class="btn orange mt-3" elevation="0" :disabled="!selectedCampaign" @click="sendEmmaCampaign()"><v-icon>mdi-mail</v-icon>Send Campaign</v-btn>
				<div v-if="selectedCampaign" style="text-align: center; margin-top: 5px;">Save before sending! Make sure, German and English texts are filled!</div>
			</div>
			<br/>
			<div class="buttonBlock">
				<p class="sidebar-title">Languages</p>
				<v-select outlined dense required hide-details class="mt-3"
					v-model="selectedLocale"
					:items="locales"
					:item-text="locale => locale.name"
					:item-value="locale => locale.code"
				/>
			</div>
		</SideBar>

		<div class="pa-3" style="width: 100%">
			<Disclosure title="Contents" :expanded="true">
				<div class="field left-border">
					<v-label>Version <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required 
						:hide-details="!versionError.length"
						v-model="release.version"
						:error-messages="versionError"
					/>
				</div>
				<Field label="Intro" translateable v-model="release.emmaInfo.emailBody"
					@translated="$forceUpdate()"
				>
					<v-textarea outlined required hide-details
						v-model="release.emmaInfo.emailBody[selectedLocale]"
					/>
				</Field>

				<fieldset v-for="feature, f of release.emmaInfo.features" :key="f">
					<v-btn class="delete" @click="release.emmaInfo.features.splice(f, 1)"><v-icon>mdi-delete</v-icon></v-btn>
					<Field label="Title" translateable v-model="feature.title" translateButtonMargin="60px">
						<v-text-field outlined required hide-details v-model="feature.title[selectedLocale]" />
					</Field>
					<Field label="Text" translateable v-model="feature.text">
						<v-textarea outlined required hide-details v-model="feature.text[selectedLocale]" />
					</Field>
					<div style="display: flex; gap: 10px;">
						<Field label="Button Label" translateable v-model="feature.buttonLabel" style="flex-grow: 1;">
							<v-text-field outlined hide-details v-model="feature.buttonLabel[selectedLocale]" />
						</Field>
						<div class="field left-border" style="flex-grow: 1;">
							<v-label>Button Link</v-label>
							<v-text-field outlined hide-details v-model="feature.buttonLink" />
						</div>
					</div>
				</fieldset>
				<v-btn @click="addFeature">Add Feature</v-btn>
<!--
				<div class="field left-border">
					<v-label>'More Information' Link</v-label>
					<v-text-field outlined required hide-details
						v-model="release.emmaInfo.moreInfoLink"
					/>
				</div>
				<div class="field left-border">
					<v-label>Customer Feedback Confluence Page Link</v-label>
					<v-text-field outlined required hide-details
						v-model="release.emmaInfo.testingFeedbackLink"
					/>
				</div>-->
			</Disclosure>
		</div>

		<!-- Linked Jira Issues -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Jira Issues" :expanded="true">
				<div class="field left-border">
					<v-label>Jira Release Label</v-label>
					<v-text-field outlined 
						:hide-details="!labelError.length"
						v-model="release.label"
						:error-messages="labelError"
						@keyup.enter="getJiraIssues()"
					/>
				</div>
				<div>This is only a search: type a label and ENTER. The shown issues are not automatically added to the email. If you want to mention an issue in the email, please add to the email body!</div>
				<div v-if="issues" class="issues">
					<h2>Features</h2>
					<template v-for="issue of issues">
						<div v-if="issue.type == 'Task'" :key="issue.id">
							<span class="issueKey">{{ issue.key }}</span>
							{{ issue.summary }}
						</div>
					</template>
					<h2>Improvements</h2>
					<template v-for="issue of issues">
						<div v-if="issue.type != 'Task'" :key="issue.id">
							<span class="issueKey">{{ issue.key }}</span>
							{{ issue.summary }}
						</div>
					</template>
				</div>
			</Disclosure>
		</div>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import SideBar from "@/components/common/SideBar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Field from '@/components/common/Field.vue'

export default {
	name: "ReleasenDetailView",
	mixins: [ Common ],
	components: { Loading, SideBar, Alert, Disclosure, LanguageFlag, Field },
	provide() { return {
		host: this,
	}},
	data() {
		return {
			emmaCampaigns: [],
			selectedCampaign: null,
			release: {
				id: this.$store.state.selectedRelease?.id ? this.$store.state.selectedRelease.id : '',
				projectKey: this.$store.selectedProject ? this.$store.selectedProject : 'MYS',
				version: '',
				label: '',
				status: '',
				emmaInfo: {
					emailBody: {de:'',en:'',fr:'',it:''},
					features: [
						this.newFeature(),
					],
					moreInfoLink: '',
					testingFeedbackLink: '',
					testingNotification: {
						sent: false,
						sentDate: ''
					},
					releaseNotification: {
						sent: false,
						sentDate: ''
					}
				},
				issues: []
			},
			emailRegex: '/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/',
			issues: null,
			featureIcons: [ 'mdi-check' ],
		}
	},
	computed: {
		versionError() {
			if (this.runValidation && !this.release?.version?.length) {
				return 'Version is required'
			}
			return ''
		},
		labelError() {
			if (this.runValidation && !this.release?.label?.length) {
				return 'Jira Release Label is required'
			}
			return ''
		},
	},
	methods: {
		newFeature() {
			return {
				title: {de:'',en:'',fr:'',it:''},
				text: {de:'',en:'',fr:'',it:''},
				buttonLabel: {de:'',en:'',fr:'',it:''},
				buttonLink: '',
			}
		},
		addFeature() {
			this.release.emmaInfo.features.push(this.newFeature())
		},
		goback() {
			this.$router.push('/releases')
		},
		emailError(email) {
			if (email?.length > 0) {
				if (!this.emailRegex.test(String(email).toLowerCase())) {
					return 'Invalid e-mail address'
				}
			}
		},
		async getEmmaCampaigns() {
			try {
				this.emmaCampaigns = await this.$httpGet(`/campaigns/${ this.release.projectKey }`)
			}
			catch (error) {
				if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error)
			}
		},
		async getReleaseDetail() {
			this.loading = true
			try {
				const r = await this.$httpGet(`/release/${ this.release.id }`)
				if (!r.emmaInfo.features) r.emmaInfo.features = []
				this.release = r
			}
			catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async getJiraIssues() {
			this.loading = true
			try {
				if (this.release.id != '') {
					const issues = await this.$httpGet(`/jiraIssues/${ this.release.projectKey }/${ this.release.label }`)
					issues.sort((a, b) => (a.key > b.key) ? 1 : -1)
					this.issues = issues
				}
			}
			catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		validateReleaseDetails() {
			this.runValidation = true
			let isValid = true

			if (isValid === false) {
				this.errorTitle = 'ERROR'
				this.errorDetail = 'Please enter the required information'
			}
			
			return isValid
		},
		async upsertRelease() {
			const isValid = this.validateReleaseDetails()
			if (!isValid) return

			this.loading = true
			
			try {
				const data = {
					originalId: this.$store.state.selectedRelease?.id,
					release: this.release
				}

				this.release = await this.$httpPost(`/release`, data)
				
				this.successTitle = 'UPDATE RELEASE'
				this.successDetail = 'Release updated successfully'

			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		async deleteRelease() {
			this.loading = true
			try {
				const id = this.$store.state.selectedRelease?.id
				await this.$httpDelete(`/release/${ id }`)
				this.goback()
			}
			catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async sendEmmaCampaign(campaignId) {
			await this.upsertRelease()

			this.loading = true
			
			try {
				await this.$httpPost(`/send-emma-campaign`, {
					campaignId: this.selectedCampaign.campaignId,
					version: this.release.version,
				})
				
				this.successTitle = 'SENT EMMA Campaign'
				this.successDetail = 'EMMA Campaign sent successfully'
			}
			catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
	},
	async mounted() {
		await this.getEmmaCampaigns()
		if (this.$store.state.selectedRelease)
			await this.getReleaseDetail()
/*
		if (this.$store.state.selectedRelease) {
			this.release = this.$store.state.selectedRelease
			this.release.issues.sort((a, b) => (a.key > b.key) ? 1 : -1)
		}*/
	},
}
</script>

<style scoped>
.flag { position: absolute; z-index: 10; top: 54px; margin-left: -26px; zoom: 0.8; }
.issueKey { color: #4DB6AC }
fieldset { position: relative; padding: 0 15px; border-radius: 10px; border: 0; box-shadow: 1px 1px 10px rgba(0,0,0,0.2) inset; background: #fafafa; margin-bottom: 10px; }
fieldset .v-input { box-shadow: 1px 1px 10px rgba(0,0,0,0.2); background: white; }
fieldset .delete { position: absolute; top: 0; right: 0; background: red !important; color: white !important; box-shadow: -15px 15px 10px -10px rgba(0,0,0,0.2) inset; border-radius: 10px; border-top-left-radius: 0; border-bottom-right-radius: 0; z-index: 1; }
.field { margin-top: 0; }
</style>