<template>
	<div>
		<LinksField v-model="entries" :def="def" :title="title" :max="1" 
			:userMayEditFields="userMayEditFields"
			@subedit="$emit('subedit', $event)"
			@subcreate="$emit('subcreate', $event)"
		></LinksField>
	</div>
</template>

<script>
import LinksField from './LinksField.vue'

export default {
	name: 'LinkField',
	components: { LinksField },
	props: {
		value: Object,
		def: Object,
		title: String,
		userMayEditFields: Boolean
	},
	data() {
		return {
		entries: [],
	}},
	watch: {
		value(n) {
			this.entries = n ? [n] : []
		},
		entries(n) {
			let v = n.length ? n[0] : null
			this.$emit('input', v)
		},
	},
	mounted() {
		this.entries = this.value ? [this.value] : []
	},
}
</script>