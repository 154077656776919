<template>
	<div>
		<EntryFilter v-model="filter" :def="def" style="margin-bottom: 15px;" />
		<EntryList :filter="filter" :selectable="true"
			:mark-entries="markEntries"
			@input="$emit('input', $event)"
		/>
	</div>
</template>

<script>
import EntryFilter from './EntryFilter.vue'
import EntryList from './EntryList.vue'

export default {
	name: 'EntryPicker',
	components: { EntryFilter, EntryList },
	props: {
		locale: String,
		filter: Object, // { contentType: '', search: '' }
		def: Object,
		markEntries: Array,
	},
}
</script>