<template>
	<v-container style="padding:0px" fluid>
		<div class="field left-border">
			<v-label>Service Provider ID</v-label>
			<v-text-field outlined dense readonly
				v-model="serviceProvider.de.sys.id" 
				hint="field is read only and cannot be changed" persistent-hint
			/>
		</div>
		<div class="field left-border">
			<v-label>Service Provider Name</v-label>
			<v-text-field outlined dense readonly
				v-model="serviceProvider.de.fields.title.de"
				hint="field is read only and cannot be changed" persistent-hint
			/>
		</div>
		<div class="field left-border">
			<v-label>Home Marketplace</v-label>
			<v-text-field outlined dense readonly
				v-model="serviceProviderHomebase"
				hint="field is read only and cannot be changed" persistent-hint
			/>
		</div>
	</v-container>
</template>

<script>
export default {
	name: 'ServiceProviderDetail',
	props: {
		serviceProvider: Object
	},
	data() {
		return {
			serviceProviderHomebase: ''
		}
	},
	
	mounted() {
		if (this.serviceProvider?.de?.fields?.clientAssignments?.de?.length > 0) {
			for (const clientAssignment of this.serviceProvider.de.fields.clientAssignments.de) {
				if (clientAssignment.fields.isHomebase.de === true) {
					this.serviceProviderHomebase = clientAssignment.fields.client.de.fields.title.de
					break
				}
			}
		}
	},
}
</script>
