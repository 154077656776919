
export class User {
    constructor() {
      return {
        sys: {
					id: ''
				},
				fields: {
					keycloakId: {de: ''},
					name: {de: ''},
					email: {de: ''},
					password: {de: ''},
					enabled: {de: false},
					type: {de: ''},
					mainOperator: {de: false},

					canManageTags: {de: []},
					canSeeObjectsWithTags: {de: []},
					applications: {de: []},
					clients: {de: []},
					dashboard: {de: []},

					serviceProviderId: {de: ''},
					preferredLanguage: {de : 'de'}
				}
    }
  }
}