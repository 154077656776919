import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import en from 'vuetify/lib/locale/en'
import de from 'vuetify/lib/locale/de'
import fr from 'vuetify/lib/locale/fr'
import it from 'vuetify/lib/locale/it'


Vue.use(Vuetify)

export default new Vuetify({
	lang: {
		locales: { en, de, fr, it },
		current: 'en',
	  }
});
