<template>
	<div :class="{
		Field: true,
		LinkField: true,
		many: value && value.length > 5,
	}">
	<label class="fieldTitle" v-if="title">{{ title }}</label>
		<div class="entries">
			<div class="entry" v-for="(val, v) of value" :key="val.sys ? val.sys.id : val.id">
				<div @click="editAt(v, val)">
					<v-row class="pa-3">
						<v-col class="header" v-if="!!val.sys.contentType">
							{{ val.sys.contentType.sys.id }}
						</v-col>
						<v-col class="text-right">
							<v-menu rounded="5" offset-y style="float: right;" v-if="userMayEditFields">
								<template v-slot:activator="{ attrs, on }">
									<v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
								</template>
								<v-list>
									<v-list-item link>
										<!-- TODO: sometimes the remove does not actually remove the entry -->
										<v-list-item-title v-text="'edit'" @click="editAt(v, val)"></v-list-item-title>
									</v-list-item>
									<v-list-item link>
										<v-list-item-title v-text="'remove'" @click="deleteAt(v, val)"></v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-col>
					</v-row>
					<v-divider></v-divider>
					<v-row class="pa-3">
						<v-col>
							{{ val.fields?.title ? val.fields.title.de : 'ID: ' + val?.sys?.id }}
						</v-col>
					</v-row>
				</div>
			</div>
		</div>

		<div class="addEntry" v-if="(!max || value.length < max) && userMayEditFields">
			<v-dialog v-model="picker" width="600px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn outlined v-bind="attrs" v-on="on">
						<v-icon>mdi-plus</v-icon>
						Add Existing {{ typeName }}
					</v-btn>
				</template>
				<v-card class="pickerCard">
					<v-card-title class="headline white" style="padding: 0; padding-bottom: 10px;">
						Add existing entry
						<v-spacer></v-spacer>
						<v-icon @click="picker = false">mdi-close</v-icon>
					</v-card-title>
					<!-- the v-if here forces a fresh component every time the dialog opens (would otherwise retain state) -->
				<EntryPicker v-if="picker" :filter="filter" :def="def.type == 'Array' ? def.items : def"
						:mark-entries="value"
						@input="entrySelected($event)"
						style="overflow-y: auto; height: 70vh;"
					></EntryPicker>

					<v-card-actions style="background-color: white; padding: 0; padding-top: 10px;">
						<v-btn v-if="selected.length == 0" color="primary" @click="commit" disabled>
							add
						</v-btn>
						<v-btn class="btn blue" elevation="0" dark
							v-if="selected.length > 0"  @click="commit" :disabled="!(!max || selected.length <= max)">
							add {{ selected.length }} {{ selected.length > 1 ? 'entries' : 'entry' }}
						</v-btn>
						<span style="margin-left: 10px;" v-if="!!max">select up to {{ max }} {{ max > 1 ? 'entries' : 'entry' }}</span>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-btn outlined style="margin-left: 10px;" @click="addNew">
				<v-icon>mdi-plus</v-icon> New..
			</v-btn>
		</div>
	</div>
</template>

<script>
import EntryPicker from './EntryPicker.vue'

// TODO: remove the values from the list that were already added before
// TODO: add an appearance where we only show checkboxes for all existing entries matching

export default {
	name: 'LinksField',
	components: { EntryPicker },
	props: {
		value: Array,
		def: Object,
		max: Number,
		filter: Object, //{ contentType: '', search: '' },
		title: String,
		userMayEditFields: Boolean
	},

	data: () => ({
		picker: false,
		selected: [],
	}),

	computed: {
		typeName() {
			if (this.def.type == 'Array') {
				if (!this.def.items.linkContentType) return '...'
				if (this.def.items.linkContentType.length > 1) return '...'
				return this.def.items.linkContentType[0]
			}
			if (!this.def.validations?.length) return '...'
			if (this.def.validations?.length > 1) return '...'
			return this.def.validations[0].linkContentType[0]
		},
	},

	methods: {
		editAt(v, val) {
			this.$emit('subedit', val)
		},
		deleteAt(v, val) {
			this.value.splice(v, 1)
		},
		entrySelected(entries) {
			this.selected = entries
		},
		commit() {
			// TODO: prevent duplicates - already prevent click of marked?
			for (let entry of this.selected) {
				this.value.splice(1000, 0, entry)
			}
			this.picker = false
			this.selected = 0
		},
		// send subcreate command up the chain
		addNew() {
			// TODO: this currently does NOT support multi-type create. actually we'd need to ask the user for the type there.
			if (this.def.type == 'Array') {
				this.$emit('subcreate', {
					contentType: this.def.items.linkContentType[0],
					callback: this.addNewCompleted,
				})
				return
			}
			this.$emit('subcreate', {
				contentType: this.def.validations[0].linkContentType[0],
				callback: this.addNewCompleted,
			})
		},
		addNewCompleted(o) {
			if (!o) return
			this.value.splice(1000, 0, o)
		},
	}
}
</script>

<style scoped>
.Field { padding-left: 10px; border-left: 3px solid #ddd; }
.entry { border: 1px solid #c0c0c0; margin-bottom: 10px; border-radius: 5px; }
.fieldTitle { color: gray; font-size: smaller; }
.header { color: gray; font-size: smaller; text-transform: capitalize; }
.pickerCard { border-radius: 10px; border: 0; padding: 15px; }
.addEntry { border: 1px dashed silver; padding: 20px; border-radius: 5px; text-align: center; }
</style>

<style>
/* compensate for a vuetify bug where the backdrop does not show */
.v-overlay--active { background: rgba(0,0,0,0.7) }
</style>