<template>
	<v-container class="main" fluid fill-width>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="toolbar">
			<v-row align="center">
				<v-text-field outlined dense clearable hide-details height="48"
					prepend-inner-icon="mdi-magnify"
					placeholder='Search'
					style="padding-right:10px"
					v-model="searchString"
					@keyup.enter="search()"
					@click:clear="clearSearch()">
				</v-text-field>

				<!-- Filters -->
				<div style="padding-left:20px;padding-right:20px">
					<v-menu offset-y bottom left origin="top right" class="filter" :close-on-content-click="false" v-model="isOpened">
						<template v-slot:activator="{ on }" class="filter">
							<v-row justify="end" v-on="on">
								<v-badge color="error" style="z-index:999" overlap v-if="$store.state.filter"></v-badge>
								<v-btn class="btn" elevation="0" x-small>
									<v-icon>mdi-filter</v-icon>
									<span class="d-none d-md-inline">Filter</span>
									<v-icon style="cursor:pointer;">mdi-menu-down</v-icon>
								</v-btn>
							</v-row>
						</template>

						<v-list class="filter">
							<v-list-item class="filter pa-5">
								<v-list-item-title>
									<div @click="closeFilter()">
										<span style="width:100%">Applications</span>
										<v-select filled solo class="btn"
											ref="filterApplications"
											v-model="selectedApplications"
											:items="applications"
											:item-text="item => item.fields.title.en"
											:item-value="item => item.sys.id"
											multiple small-chips hide-details
											:menu-props="{ offsetY: true  }">
											<template #selection="{ item }">
												<v-chip>{{item.fields.title.en}}</v-chip>
											</template>
										</v-select>
									</div>
								</v-list-item-title>
							</v-list-item>

							<v-list-item class="filter pa-5">
								<v-list-item-title>
									<div @click="closeFilter()">
										<span style="width:100%">Clients</span>
										<v-select filled solo class="btn"
											ref="filterClients"
											v-model="selectedClients"
											:items="clients"
											:item-text="item => item.fields.title.en"
											:item-value="item => item.sys.id"
											multiple small-chips hide-details
											:menu-props="{ offsetY: true  }">
											<template #selection="{ item }">
												<v-chip>{{item.fields.title.en}}</v-chip>
											</template>
										</v-select>
									</div>
								</v-list-item-title>
							</v-list-item>

							<v-list-item class="filter pa-5">
								<v-list-item-title>
									<span>Log Date</span>
									<br/><br/>
									<v-row>
										<v-btn class="btn flex-grow-1 mr-4 ml-3" elevation="0" @click="toggleDatePicker('from')">{{validFromText}}</v-btn>
										<v-btn class="btn flex-grow-1 mr-3" elevation="0" @click="toggleDatePicker('to')">{{validToText}}</v-btn>
									</v-row>
									<br/><br/>
									<v-row justify="center">
										<v-date-picker v-if="showFromDate" no-title v-model="logFromDate" :first-day-of-week="1" color="green lighten-1" @input="resetDates()"/>
										<v-date-picker v-if="showToDate" no-title v-model="logToDate" :first-day-of-week="1" color="green lighten-1" :min="logFromDate" @input="showToDate=false"/>
									</v-row>
								</v-list-item-title>
							</v-list-item>
							<v-divider/>

							<v-row class="pl-6 pr-6 pb-4 pt-6">
								<v-btn class="btn" elevation="0" style="width:48%;margin-right:4%" @click="clearFilter()">Clear Filter</v-btn>
								<v-btn class="btn green" elevation="0" dark style="width:48%;" @click="applyFilter()">Apply Filter</v-btn>
							</v-row>
						</v-list>
					</v-menu>
				</div>
			</v-row>
		</div>

		<!-- Content -->
		<v-card class="tableCard" style="margin-top:10px">
			<v-data-table fixed-header hide-default-footer disable-sort 
				v-model="selected"
				:headers="headers"
				:items="auditEntries"
				:items-per-page="limit"
				item-key="sys.id"
				class="elevation-0">
				
				<template v-slot:item="{item}">
          <tr @click="showDetail(item)">
            <td>{{ item.summary }}</td>
						<td>{{ item.application?.fields?.title?.en }}</td>
						<td>{{ item.client?.fields?.title?.en }}</td>
						<td>{{ item.contentType }}</td>
            <td>{{ item.user?.fields.name.de }}</td>
            <td>{{ item.createdAt | formatDateTime }}</td>
					</tr>
        </template>
			</v-data-table>
			<v-card class="tableFooter">
				<v-card-actions>
					<TablePaginator v-model="offset" :limit="limit" :total="itemCount" @input="getAuditLog()" :results="auditEntries" />
				</v-card-actions>
			</v-card>
		</v-card>

		<!-- Audit Entry Details -->
		<Dialog ref="auditDetailDialog"
			:showClose="true"
      title="Audit Log Detail"
			:height="'90%'"
			:width="'60%'">
			<template #content>
        <v-container class="pa-5">
          <div class="pa-5">
            <v-row class="detail"><span class="label">Summary</span>{{selectedItem.summary}}</v-row>
            <v-row class="detail"><span class="label">Updated by</span>{{selectedItem.user.fields.name.de}}</v-row>
            <v-row class="detail"><span class="label">Updated at</span>{{selectedItem.createdAt | formatDateTime}}</v-row>
						<br/><br/>
						<v-row class="detail"><span class="label">Entry Id</span>{{selectedItem.entry?.sys?.id}}</v-row>
						<v-row class="detail"><span class="label">Entry Name</span>{{selectedItem.entry?.fields?.title?.de ?? selectedItem.title}}</v-row>
						<v-row class="detail"><span class="label">Content Type</span>{{selectedItem.contentType}}</v-row>
          </div>

          <div class="audit-entry-container">
            <v-row style="padding:12px">
              <div class="audit-entry-header-box">
                <div class="audit-entry-header"><h3>Original</h3></div>
              </div>
              <div class="audit-entry-header-box">
                <div class="audit-entry-header"><h3>Changes</h3></div>
              </div>
            </v-row>

						<div v-for="key of Object.keys(selectedItem.detail.original)" :key="key">
							<div v-if="haveDifferences(selectedItem.detail.original[key], selectedItem.detail.new[key], key)" class="audit-entry-row">
									<!-- Original Data Column -->
									<div class="audit-entry">
										<DiffData :contentType="selectedItem.contentType" :propKey="key" :entry="selectedItem.detail.original[key] ? selectedItem.detail.original[key] : null"/>
									</div>

									<!-- New Data Column -->
									<div class="audit-entry">
										<DiffData :contentType="selectedItem.contentType" :propKey="key" :entry="selectedItem.detail.new[key] ? selectedItem.detail.new[key] : null"/>
									</div>
							</div> 
						</div>
          </div> 

        </v-container>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Dialog from '@/components/common/Dialog.vue'
import DiffData from '@/components/auditLog/DiffData.vue'
import Common from '@/mixins/Common.vue'
import { Action } from '@/plugins/enum.js'
import _ from 'lodash'

export default {
	name: 'LogViewer',
	components: { Loading, Alert, TablePaginator, Dialog, DiffData },
	mixins: [ Common ],

	data() {
		return {
			Action: Action, 

			offset: this.$store.state.offset ? this.$store.state.offset : 0,
			limit: 15,
			
			selected: [],
			singleSelect: '',
			auditEntries: [],
			itemCount: 0,
			searchString: this.$store.state.searchString,

			selectedItem: {},
			
			isOpened: false,
			showFromDate: false,
			showToDate: false,
			logFromDate: '',
			logToDate: '',
			clients: [],
			applications: [],
			selectedClients: this.filter ? this.filter.selectedClients : [],
			selectedApplications: this.filter ? this.filter.selectedApplications : [],
		}
	},

	computed: {
		headers () {
			return [
				{ text: 'Summary', value: 'summary', cellClass: 'truncate', width: '26%' },
				{ text: 'Application', value: 'application', cellClass: 'truncate', width: '17%' },
				{ text: 'Client', value: 'client', cellClass: 'truncate', width: '17%' },
				{ text: 'Entry', value: 'entry', cellClass: 'truncate', width: '20%' },
				{ text: 'Updated By', value: 'user', cellClass: 'truncate', width: '20%' },
				{ text: 'Updated At', value: 'created_at', width: '200' }
			]
		},
		validFromText() {
			return this.logFromDate !== '' ? this.logFromDate : 'From Date'
		},
		validToText() {
			return this.logToDate !== '' ? this.logToDate : 'To Date'
		},
	},

	async mounted() {
		await this.getClients()
		await this.getApplications()
		await this.getAuditLog()
	},

	methods: {
		async getClients() {
			try {
				const res = await this.$httpGet('/clients?skip=0&limit=999')
				this.clients = res.clients
			} catch (error) {
				this.showError(error)
			}
		},
		async getApplications() {
			try {
				const res = await this.$httpGet('/applications?skip=0&limit=999')
				this.applications = res.applications
			} catch (error) {
				this.showError(error)
			}
		},
		async getAuditLog() {
			this.loading = true
			try {
				await this.$store.commit('setOffset', this.offset)
				const url = `/audit-logs?skip=${this.offset}&limit=${this.limit}&searchString=${encodeURIComponent(this.searchString)}&filter=${JSON.stringify(this.$store.state.filter)}`
				
				const res = await this.$httpGet(url)

				this.auditEntries = res.auditEntries
				this.itemCount = res.total

			} catch (error) {
				if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error)
			}
			this.loading = false
		},
		async showDetail(item) {
			this.selectedItem = item
      this.$refs.auditDetailDialog.show = true
		},
		haveDifferences(oldValue, newValue, key) {
			return !_.isEqual(oldValue, newValue)
		},
		toggleDatePicker(pickerToToggle) {
			if (pickerToToggle === 'from') {
				this.showToDate = false
				this.showFromDate = true
			} else {
				this.showFromDate = false
				this.showToDate = true
			}
		},
		resetDates() {
			this.logToDate = ''
			this.showFromDate = false
		},
		async search() {
			await this.$store.commit('setSearchString', this.searchString)
				
			if (this.searchString?.length > 0) {
				this.offset = 0
				this.getAuditLog()
			} else {
				this.clearSearch()
			}
		},
		async clearSearch() {
			this.offset = 0
			this.searchString = ''
			await this.$store.commit('setSearchString', '')
			
			this.getAuditLog()
		},
		async applyFilter() {
			this.isOpened = false
			this.offset = 0
			const filter = {
				applications: this.selectedApplications,
				clients: this.selectedClients,
				logFromDate: this.logFromDate,
				logToDate: this.logToDate
			}

			await this.$store.commit('setFilter', filter)
			await this.getAuditLog()
		},
		async clearFilter() {
			this.isOpened = false
			this.selectedClients = []
			this.offset = 0

			await this.$store.commit('setFilter', null)
			this.getAuditLog()
		},
	  closeFilter() {
			if (!this.dropdownOpen) {
				this.$refs.filterApplications.focus()
				this.dropdownOpen = true
			} else {
				this.$refs.filterApplications.blur()
				this.dropdownOpen = false
			}
		}
	}
}
</script>

<style scoped>
h3 { font-weight: normal; color: rgb(17, 27, 43); }
.label { width: 15%; font-size: 13pt; font-weight: bold; color: rgb(17, 27, 43); }
.detail { font-size: 13pt; color: rgb(17, 27, 43); }
.audit-entry-container { -moz-box-flex: 1; flex-grow: 1; max-width: 100%; margin-top:20px; }
.audit-entry-header-box { max-width: 50%; height: 48px; display: flex; flex: 1 0 0%; position: relative; background-color: rgb(247, 249, 250); border: 1px solid rgb(219, 219, 219); }
.audit-entry-header { max-width: 50%; position: absolute; inset: 0px; cursor: pointer; padding-left: 20px; padding-top: 13px; }
.audit-entry-row { display: flex; padding: 0px; }
.audit-entry { max-width: 50%; width: 50%; padding: 20px; flex: 1; border: 1px solid rgb(219, 219, 219); }
.audit-entry-name { line-height: 1.5; color: rgb(90, 101, 124); }
</style>
