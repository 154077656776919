<template>
  <div style="height: 0">
    <v-navigation-drawer
        fixed
        hide-overlay
        :permanent="true"
        width="300"
        class="sidebar"
        style="z-index:3"
    >
      <slot></slot>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "SideBar"
}
</script>

<style scoped lang="scss">
.sidebar {
  top: 56px !important;
  z-index: 4 !important;

  ::v-deep .v-navigation-drawer__content {
    padding: 12px 12px 150px 12px;
  }

  ::v-deep #navlist li {
    padding-left: 0;
  }
}
</style>