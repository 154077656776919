<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>
		
		<!-- toolbar -->
		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>
					&nbsp;<h2>Application Detail View</h2>
				</v-row>
			</div>
		</div>

		<SideBar>
			<div class="sidebar-block">
				<p class="sidebar-title">Actions</p>
				<div class="buttonBlock">
					<v-btn block class="btn blue mt-3" elevation="0" dark @click="upsertApplication()">Save Changes</v-btn>
					<v-btn v-if="applicationStatus===EntryStatus.ACTIVE" block class="btn red mt-3" elevation="0" dark @click="changeStatus('deactivated')">Deactivate Application</v-btn>
					<v-btn v-if="applicationStatus!==EntryStatus.ACTIVE" block class="btn green mt-3" elevation="0" dark @click="changeStatus('active')">Activate Application</v-btn>
					<v-btn v-if="action!==Action.CREATE" block class="btn red mt-3" elevation="0" dark @click="$refs.deleteDialog.show=true">Delete Application</v-btn>
					<v-btn v-if="action!==Action.CREATE" block class="btn mt-3" elevation="0" @click="removeAppFromUsers()">Remove App from Users</v-btn>
				</div>
			</div>

			<div class="sidebar-block">
				<p class="sidebar-title">Status</p>
				<Status :status="applicationStatus" class="mt-3" />
			</div>

			<div class="sidebar-block">
				<p class="sidebar-title">Languages</p>
			
				<v-select outlined dense required hide-details class="mt-3"
					v-model="selectedLocale"
					:items="locales"
					:item-text="locale => locale.name"
					:item-value="locale => locale.code"
				/>
			</div>

			<div class="sidebar-block">
				<p class="sidebar-title">Preview</p>
				<div v-if="application.fields.icon.de.fields" class="preview-container">
					<div class="preview-component">
						<ApplicationCard 
							:application="application"
							:locale="selectedLocale" 
							:isPreview="true"
						/>
					</div>
				</div>
			</div>

			<div class="sidebar-block" v-if="action!==Action.CREATE">
				<p class="sidebar-title">Info</p>
				<v-label>{{application.sys.id}}</v-label>
			</div>

			<div v-if="application.addl && application.addl.users.length > 0">
				<p class="sidebar-title">Links</p>
				<p v-if="application.addl.users.length > 0">There are {{application.addl.users.length}} other entries that link to this entry:</p>
				<ul>
					<li v-for="user of application.addl.users" :key="user.id">{{user.name}}</li>
				</ul>
			</div>
		</SideBar>

		<!-- General Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="General Information" :expanded="true" :error="sectionMessage.generalInfo.error" :message="sectionMessage.generalInfo.message">
				<div class="field left-border">
					<v-label>Application Name <span class="mandatory">(required)</span></v-label>
					<LanguageFlag v-model="selectedLocale" class="flag" style="margin-top:10px !important"/>
					<v-text-field outlined required
						:hide-details="!applicationNameError.length"
						v-model="application.fields.title[selectedLocale]"
						:error-messages="applicationNameError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Tooltip <span class="mandatory">(required)</span></v-label>
					<LanguageFlag v-model="selectedLocale" class="flag" style="margin-top:10px !important"/>
					<v-text-field outlined required
						:hide-details="!tooltipError.length"
						v-model="application.fields.tooltip[selectedLocale]"
						:error-messages="tooltipError"
					/>
				</div>
			</Disclosure>
		</div>

		<!-- Display Settings -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Display Settings" :expanded="true" :error="sectionMessage.displaySettings.error" :message="sectionMessage.displaySettings.message">
				<v-row>
					<v-col>
						<div class="field left-border">
							<v-label>Colour <span class="mandatory">(required)</span></v-label>
							<v-color-picker
								class="ma-2"
								canvas-height="300"
								show-swatches
								swatches-max-height="300px" 
								v-model="application.fields.colour.de" 
								:mode.sync="colourMode"
								:hide-details="!colourError.length"
								:error-messages="colourError"
							/>
						</div>
					</v-col>

					<v-col>
						<div class="field left-border">
							<v-row style="padding:10px">
								<v-label>Icon <span class="mandatory">(required)</span></v-label>
								<v-spacer/>
								<v-btn v-if="isUpdateIcon" class="btn" elevation="0" dense @click="cancelUpload()">Cancel Upload</v-btn>
							</v-row>

							<MediaCard 
								v-if="!isUpdateIcon && application.fields.icon.de.fields"
								:media="application.fields.icon.de" 
								:canEdit="true" 
								:canSelect="false"
								:showTitle="false"
								@show-media-update="showUpdateIcon()"
							/>
							<br/>
							<FileUpload 
								v-if="isUpdateIcon || !application.fields.icon.de.fields"
								:options="dropOptions" 
								ref="fileUpload" 
								@thumbnail-created="onThumbnailCreated" 
								@file-removed="cancelUpload"
							/>
						</div>
					</v-col>
				</v-row>
			</Disclosure>
		</div>

		<!-- Application Permissions -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Application Permissions" :expanded="true" :error="sectionMessage.appPermissions.error" :message="sectionMessage.appPermissions.message">
				<div class="field left-border">
					<p class="subTitle">User Types</p>
					<v-row v-for="userType of userTypes" :key="userType.name">
						<v-checkbox class="pa-2"
							v-model="userType.selected"
							:label="userType.name"
						/>
					</v-row>
					<br/>
					<v-switch
						v-model="autoAssignApp"
						label="Automatically assign to all users with the selected user roles" 
					/>
				</div>

				<div class="field left-border">
					<p class="subTitle">Clients</p>
					<p class="helpText">Select the clients that should have access to this application</p>
					
					<v-checkbox class="pa-2"
						v-model="selectAll"
						label="Select all"
						@click="toggleClientSelect()"
					/>

					<v-row no-gutters>
						<v-col v-for="client of clients" :key="client.name" cols="12" sm="4">
							<v-card class="pa-2" tile flat style="background-color:transparent">
								<v-checkbox
									v-model="client.selected"
									:label="client.name"
								/>
							</v-card>
						</v-col>
					</v-row>
				</div>
			</Disclosure>
		</div>

		<!-- Integration Settings -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Integration Settings" :expanded="true" :error="sectionMessage.integrationSettings.error" :message="sectionMessage.integrationSettings.message">
				<div class="field left-border">
					<v-label>Application URL <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required
						:hide-details="!applicationURLError.length"
						v-model="application.fields.link.de"
						:error-messages="applicationURLError"
					/>
				</div>

				<div class="field left-border">
					<v-switch
						v-model="application.fields.externalComponent.de"
						label="Third party application"
					/>
				</div>
			</Disclosure>
		</div>

		<!-- App Config -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Application Config" :expanded="true">
				<div class="field left-border">
					<JsonEditor v-model="application.fields.appConfig.de" :plus="true" :options="editorOptions" />
				</div>
			</Disclosure>
		</div>

		<!-- Change Log -->
		<div v-if="action!==Action.CREATE" class="pa-3" style="width: 100%">
			<AuditLog :entryId="application.sys.id" :contentType="'appComponent'"/>
		</div>	

		<!-- Confirm Delete -->
		<Dialog ref="deleteDialog"
			confirmLabel="Delete"
			cancelLabel="Cancel"
			:confirm-handler="deleteApplication"
			:cancel-handler="onActionCancelled"
			:showClose="false"
      title="Delete Application"
			:height="'200px'"
			:width="'600px'">
			<template #content>
				<div class="pa-3" style="width:100%">
					<v-container fluid>
						<div>Are you sure you want to delete this application?</div>
					</v-container>
				</div>
			</template>
		</Dialog>	
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import SideBar from "@/components/common/SideBar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import Dialog from '@/components/common/Dialog.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import ApplicationCard from '@/components/common/ApplicationCard.vue'
import MediaCard from '@/components/common/MediaCard.vue'
import FileUpload from '@/components/common/FileUpload'
import JsonEditor from '@/components/common/JsonEditor.vue'
import Status from '@/components/common/Status.vue'
import AuditLog from '@/components/auditLog/AuditLog.vue'
import Common from '@/mixins/Common.vue'
import { Action, EntryStatus } from '@/plugins/enum.js'

export default {
	name: "ApplicationDetailView",
	mixins: [ Common ],
	components: { Loading, SideBar, Alert, Disclosure, Dialog, LanguageFlag, Status, ApplicationCard, MediaCard, FileUpload, JsonEditor, AuditLog },
	
	props: {
		initialAction: { type: String }
	},

	data() {
		return {
			Action: Action,
			EntryStatus: EntryStatus,
			action: this.initialAction,

			userTypes: [],
			clients: [],
			auditEntries: [],

			selectAll: false,
			originalIcon: {},
			colourMode: 'hexa',
			isUpdateIcon: false,
			newIcon: {},
			autoAssignApp: false,
			dropOptions: {
				acceptedFiles: '.svg',
				addRemoveLinks: true,
				maxFilesize: 10,
				maxFiles: 1,
			},
			editorOptions: {
				mode: "code",
				modes: ["tree", "code"],
				onEditable: this.onEditable,
			},
			sectionMessage: {
				generalInfo: {error:false,warning:false,message:''},
				displaySettings: {error:false,warning:false,message:''},
				appPermissions: {error:false,warning:false,message:''},
				integrationSettings: {error:false,warning:false,message:''}
			},
			application: {
				sys: {id:''},
				fields: {
					title: {},
					tooltip: {},
					icon: {de: {}},
					link: {de: ''},
					colour: {de: '#000000'},
					userRoles: {de: []},
					externalComponent: {de: false},
					appConfig: {de:{}},
					status: {de: 'pending'},
					clients: {de: []}
				}
			}
		}
	},
	
	computed: {
		applicationNameError() {
			if (this.runValidation && !this.checkRequiredForAllLocales(this.locales, this.application.fields.title)) {
				return 'Application Name is required for all languages'
			}
			return ''
		},
		tooltipError() {
			if (this.runValidation && !this.checkRequiredForAllLocales(this.locales, this.application.fields.tooltip)) {
				return 'Tooltip is required for all languages'
			}
			return ''
		},
		colourError() {
			if (this.runValidation && !this.application.fields.colour.de.length) {
				return 'Colour is required'
			}
			return ''
		},
		iconError() {
			if (this.runValidation && 
				(!this.application.fields.icon.de.fields && !this.newIcon.fields)) {
				return 'Icon is required'
			}
			return ''
		},
		applicationURLError() {
			if (this.runValidation && !this.application.fields.link.de.length) {
				return 'Application URL is required'
			}
			return ''
		},
		applicationStatus() {
			return this.application.fields.status.de
		}
	},

	async mounted() {
		this.getUserTypes()
		this.getClients()
		
		if (this.$store.state.selectedApplication) {
			this.application = this.$store.state.selectedApplication
			this.originalIcon = JSON.parse(JSON.stringify(this.application.fields.icon.de))
		}
	},

	methods: {
		async getUserTypes() {
			try {
				let userTypes = await this.$httpGet('/user-types')
				let haveUserType = ''

				if (userTypes?.length) {
					for (const userType of userTypes) {
						haveUserType = this.application.fields.userRoles.de.find(userRole => userRole === userType)
						this.userTypes.push({
							name: userType,
							selected: haveUserType?.length ? true : false
						})
					}
				}
			}
			catch (error) {
				if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error)
			}
		},
		async getClients() {
			try {
				let response = await this.$httpGet(`/clients`)
				let haveClient = ''

				if (response?.clients?.length) {
					for (const client of response.clients) {
						haveClient = this.application.fields.clients?.de?.find(appClient => appClient === client.sys.id)
						this.clients.push({
							id: client.sys.id,
							name: client.fields.title.de,
							selected: haveClient?.length ? true : false
						})
					}
				}
			}
			catch (error) {
				if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error)
			}
		},
		validateApplicationDetails() {
			this.runValidation = true
			let isValid = true

			if (this.applicationNameError.length || this.tooltipError.length) {
				this.sectionMessage.generalInfo.error = true
				this.sectionMessage.generalInfo.message = 'Please enter the required information'
				isValid = false
			} else {
				this.sectionMessage.generalInfo.error = false
			}

			if (this.colourError.length || this.iconError.length) {
				this.sectionMessage.displaySettings.error = true
				this.sectionMessage.displaySettings.message = 'Please enter the required information'
				isValid = false
			} else {
				this.sectionMessage.displaySettings.error = false
			}

			const haveUserRoleSelected = this.userTypes.find(x => x.selected === true)

			if (!haveUserRoleSelected) {
				this.sectionMessage.appPermissions.error = true
				this.sectionMessage.appPermissions.message = 'Please select at least one user role'
				isValid = false
			} else {
				this.sectionMessage.appPermissions.error = false
			}

			if (this.applicationURLError.length) {
				this.sectionMessage.integrationSettings.error = true
				this.sectionMessage.integrationSettings.message = 'Please enter the required information'
				isValid = false
			} else {
				this.sectionMessage.integrationSettings.error = false
			}

			if (isValid === false) {
				this.errorTitle = 'ERROR'
				this.errorDetail = 'Please enter the required information'
			}
			
			return isValid
		},
		async upsertApplication() {
			const isValid = this.validateApplicationDetails()
			if (!isValid) return

			this.loading = true
			
			try {
				delete this.application.addl

				if (Object.keys(this.newIcon).length > 0) {
					this.application.fields.icon.de = this.newIcon
				}

				this.application.fields.userRoles.de = []
				for (const userType of this.userTypes) {
					if (userType.selected === true) {
						this.application.fields.userRoles.de.push(userType.name)
					}
				}

				this.application.fields.clients = {de: []}
				for (const client of this.clients) {
					if (client.selected === true) {
						this.application.fields.clients.de.push(client.id)
					}
				}
				
				const data = {
					application: this.application,
					autoAssignApp: this.autoAssignApp
				}

				this.application = await this.$httpPost(`/application`, data)
				
				if (this.action === Action.CREATE) {
					this.successTitle = 'CREATE APPLICATION'
					this.successDetail = 'Application created successfully'
				} else {
					this.successTitle = 'UPDATE APPLICATION'
					this.successDetail = 'Application updated successfully'
				}

				this.action = Action.VIEW

			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		async deleteApplication() {
			this.loading = true
			
			try {
				await this.$httpDelete(`/application/${this.application.sys.id}`)
				
				this.successTitle = 'DELETE APPLICATION'
				this.successDetail = 'Application deleted successfully'
				this.loading = false

				this.sleep(2000).then(() => { this.goback() })
			} catch (error) {
				this.loading = false
				this.showError(error)
			}
		},
		async changeStatus(status) {
			const isValid = this.validateApplicationDetails()
			if (!isValid) return

			this.loading = true
			
			try {
				delete this.application.addl
				this.application.fields.status.de = status
			
				const data = {
					application: this.application,
					autoAssignApp: this.autoAssignApp
				}

				this.application = await this.$httpPost(`/application-status`, data)
				
				this.successTitle = 'UPDATE APPLICATION'
				this.successDetail = 'Application updated successfully'

			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		async removeAppFromUsers() {
			this.loading = true
			
			try {
				await this.$httpPost(`/remove-application/${this.application.sys.id}`)

				this.loading = false
				this.successTitle = 'REMOVE APPLICATION'
				this.successDetail = 'Application has been removed from all users successfully'
			} catch (error) {
				this.showError(error)
			}
		},
		switchLocale(locale) {
			this.selectedLocale = JSON.parse(JSON.stringify(locale)).code
			this.$forceUpdate()
		},
		goback() {
			this.$router.push('/applications')
		},
		showUpdateIcon() {
			this.isUpdateIcon = true
		},
		onThumbnailCreated(file) {
			this.newIcon = {
				sys: {
					id: file.upload.uuid,
				},
				fields:{
					title: {},
					altText: {},
					description: {},
					file: file,
				}
			}

			for (const locale of this.locales) {
				this.newIcon.fields.title[locale.code] = `App Icon - ${ this.application.fields.title[locale.code] }`
				this.newIcon.fields.altText[locale.code] = `App Icon - ${ this.application.fields.title[locale.code] }`
				this.newIcon.fields.description[locale.code] = `App Icon - ${ this.application.fields.title[locale.code] }`
			}

			//Preview New Icon
			if (this.application.fields.icon.de.fields) {
				this.application.fields.icon.de.fields.file.de.url = this.$refs.fileUpload.$refs.fileUploadElement.dropzone.files[0].dataURL
			}
		},
		cancelUpload() {
			this.newIcon = {},
			this.isUpdateIcon = false
			this.application.fields.icon.de = JSON.parse(JSON.stringify(this.originalIcon))
		},
		onActionCancelled() {
			this.$refs.deleteDialog.show = false
		},
		formatSelectedUserTypes() {
			let userTypeNames = []
			const selectedUserTypes = this.userTypes.filter(x => x.selected === true)

			for (const selectedUserType of selectedUserTypes) {
				userTypeNames.push(selectedUserType.name)
			}

			return userTypeNames.join(', ')
		},
		toggleClientSelect() {
			for (let client of this.clients) {
				client.selected = this.selectAll
			}
		}
	}
}
</script>

<style scoped>
.flag { position: absolute; z-index: 10; top: 54px; margin-left: -26px; zoom: 0.8; }
.preview-container {
	width: 75%;
	padding-top: 75%;
	position: relative;
	margin: auto;
}
.preview-component {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	border: 1px solid grey;
	border-radius: 10px;
}
li {
	font-size: 16px;
}
</style>