import { render, staticRenderFns } from "./TranslationsView.vue?vue&type=template&id=37efa8fd&scoped=true"
import script from "./TranslationsView.vue?vue&type=script&lang=js"
export * from "./TranslationsView.vue?vue&type=script&lang=js"
import style0 from "./TranslationsView.vue?vue&type=style&index=0&id=37efa8fd&prod&scoped=true&lang=css"
import style1 from "./TranslationsView.vue?vue&type=style&index=1&id=37efa8fd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37efa8fd",
  null
  
)

export default component.exports