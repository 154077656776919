<template class="justify-center">
    <v-card class="card" :color="serviceType.fields.color.de">
      <v-img style="opacity:0.25;width:50%;height:50%;margin:auto;padding-top:30px" contain :src="getAssetUrl(serviceType.fields.icon.de)"></v-img>

      <div style="height:45%; width:100%; position:absolute; bottom:0">
        <v-card-text class="cardText">
          <p style="font-size:12pt;font-weight:bold;color:#000">{{serviceType.fields.title[locale]}}</p>
          <p style="color:#000">{{serviceType.fields.shortDescription[locale]}}</p>
        </v-card-text>
      </div>
    </v-card>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	name: "ServiceTypeCard",
	mixins: [ Common ],
	props: {
    locale: {
      default: 'en',
      type: String
    },
		serviceType: Object
	},

  computed: {
    imgSize () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 50
        case 'sm': return 60
        case 'md': return 70
        case 'lg': return 80
        case 'xl': return 80
        default: return 100
      }
    },
    fontSize () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '8pt'
        case 'sm': return '10pt'
        case 'md': return '12pt'
        case 'lg': return '14pt'
        case 'xl': return '16pt'
        default: return '14pt'
      }
    }
  }
}
</script>

<style scoped>
.card {
  height: 100%;
  width: 100%;
}
.cardText {
  background-color:#ffffff;
  padding: 10px;
  height: 100%;
}
.label {
  width: 100% !important;
  background-color: white !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.img-container {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.pointer {
  cursor: pointer;
}
</style>

