<template>
	<v-container class="main" fluid fill-height>
		<loading :active.sync="isLoading" :is-full-page="true" color="#4caf50"></loading>

		<!-- Content -->
		<v-row align="center" justify="center">
			<v-col>
				<Grid 
					:items="applications"
					:cell-size="cellSize">
					<template slot="cell" slot-scope="props">
						<ApplicationCard
							:application="props.item"
							@load-application="$emit('load-application',props.item)"
						/>
					</template>
				</Grid>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Grid from './grid/Grid.vue'
import ApplicationCard from '../../components/common/ApplicationCard.vue'

export default {
	name: 'Dashboard',
	components: { Loading, ApplicationCard, Grid },

	data() {
		return {
			isLoading: false,
			loggedInUser: {},
			applications: []
		}
	},

	computed: {
		cellSize () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 150
        case 'sm': return 180
        case 'md': return 215
        case 'lg': return 225
        case 'xl': return 250
        default: return 200
      }
    }
	},

	async mounted() {
			this.$store.state.filter = null
			this.$store.state.searchStrinng = null
			this.$store.state.offset = null
			
			this.loggedInUser = this.$store.state.loggedInUser
			if (this.loggedInUser) {
				if (Object.entries(this.loggedInUser).length === 0 || this.tokenIsExpired(this.loggedInUser.kc_token)) {
					this.$root.app.showLogin()
					return
				}

				this.applications = this.loggedInUser.fields.applications.de
			}
	},

	methods: {
		tokenIsExpired(token) {
			try {
				const payload = JSON.parse(atob(token.split('.')[1]))
				const exp = payload.exp * 1000
				return exp < new Date().getTime()
			} catch (e) {
				return false
			}
		}
	}
}
</script>