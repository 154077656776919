<template>
	<v-container class="main" fluid fill-width>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="toolbar">
			<v-row align="center">
				<v-text-field outlined dense clearable hide-details height="48"
					prepend-inner-icon="mdi-magnify"
					placeholder='Search'
					style="padding-right:10px"
					v-model="searchString"
					@keyup.enter="search()"
					@click:clear="clearSearch()">
				</v-text-field>
				<v-btn class="btn" small elevation="0" @click="showNewApplication()">
					<v-icon>mdi-plus-circle</v-icon>
					<span class="d-none d-md-inline">Add Application</span>
				</v-btn>
			</v-row>
		</div>
		
		<v-card class="tableCard" style="margin-top:10px">
			<v-data-table fixed-header hide-default-footer
				v-model="selected"
				:headers="headers"
				:items="applications"
				:items-per-page="limit"
				:mobile-breakpoint="700"
				item-key="name"
				class="elevation-0"
				style="border-radius: 0"
				@click:row="showApplicationDetail">

				<template v-slot:item.icon="{ item }">
					<div class="d-flex align-center img-container pointer" style="height:32px;width:32px;border-radius:5px" :style="{'background':item.fields.colour.de + ' !important'}">
						<v-img style="opacity:0.5;" height="20px" width="20px" contain :src="getAssetUrl(item.fields.icon.de)"/>
					</div>
				</template>
				<template v-slot:item.title="{ item }">
					{{ item.fields.title.en }}
				</template>
				<template v-slot:item.created_at="{ item }">
					{{ item.sys.createdAt | formatShortDate }}
				</template>
				<template v-slot:item.updated_at="{ item }">
					{{ item.sys.updatedAt | formatShortDate }}
				</template>
				<template v-slot:item.status="{ item }">
					<Status :status="item.fields.status.de" />
				</template>
			</v-data-table>

			<v-card class="tableFooter">
				<v-card-actions>
					<TablePaginator v-model="offset" :limit="limit" :total="count" @input="getApplications()" :results="applications" />
				</v-card-actions>
			</v-card>
		</v-card>

	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Status from '@/components/common/Status.vue'
import Common from '@/mixins/Common.vue'
import { Action } from '@/plugins/enum.js'

export default {
	name: 'ApplicationsView',
	components: { Loading, Alert, TablePaginator, Status },
	mixins: [ Common ],

	data() {
		return {
			Action: Action,

			searchString: this.$store.state.searchString,

			offset: this.$store.state.offset,
			limit: 15,
			
			applications: [],
			count: 0,
			selected: []
		}
	},

	computed: {
		headers () {
			return [
				{ text: '', value: 'icon', sortable: false, width: '40px' },
				{ text: 'Application Name', value: "title", sortable: false },
				{ text: 'Created', value: "created_at", sortable: false,  width: '175' },
				{ text: 'Updated', value: "updated_at", sortable: false,  width: '175' },
				{ text: 'Status', value: "status", sortable: false, align: 'center', width: '15%' },
			]
		},
	},

	mounted() {
		this.getApplications()
	},

	methods: {
		async getApplications() {
			this.loading = true

			try {
				await this.$store.commit('setOffset', this.offset)
				let url = `/applications?skip=${this.offset}&limit=${this.limit}&include=1&searchString=${encodeURIComponent(this.searchString)}`
				
				const res = await this.$httpGet(url)
				this.applications = res.applications
				this.count = res.total
			}
			catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async showApplicationDetail(item) {
			try {
				this.loading = true
				const application = await this.$httpGet(`/application/${item.sys.id}`)
				await this.$store.commit('setSelectedApplication', application)
				this.loading = false

				this.$router.push(`/application-detail?action=${Action.VIEW}`)
				
			} catch (error) {
				this.showError(error)
			}
		},
		async showNewApplication() {
			await this.$store.commit('setSelectedApplication', null)
			this.$router.push(`/application-detail?action=${Action.CREATE}`)
		},
		async search() {
			await this.$store.commit('setSearchString', this.searchString)
			
			if (this.searchString?.length > 0) {
				this.offset = 0
				this.getApplications()
			} else {
				this.clearSearch()
			}
		},
		async clearSearch() {
			this.offset = 0
			this.searchString = ''
			await this.$store.commit('setSearchString', null)

			await this.getApplications()
		}
	}
}
</script>

