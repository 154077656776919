<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>
		
		<!-- toolbar -->
		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>
					&nbsp;<h2>Training Video Detail View</h2>
				</v-row>
			</div>
		</div>

		<SideBar>
			<div class="sidebar-block">
				<p class="sidebar-title">Actions</p>
				<div class="buttonBlock">
					<v-btn block class="btn blue mt-3" elevation="0" dark @click="upsertTrainingMaterial()">Save Changes</v-btn>
					<v-btn v-if="action!==Action.CREATE" block class="btn red mt-3" elevation="0" dark @click="$refs.deleteDialog.show=true">Delete Training Material</v-btn>
				</div>
			</div>

			<div class="sidebar-block">
				<p class="sidebar-title">Languages</p>
			
				<v-select outlined dense required hide-details class="mt-3"
					v-model="selectedLocale"
					:items="locales"
					:item-text="locale => locale.name"
					:item-value="locale => locale.code"
				/>
			</div>

      <div class="sidebar-block">
				<p class="sidebar-title">Preview</p>
				<div v-if="trainingMaterial.fields.media.de.fields" class="preview-container">
					<div class="preview-component">
						<div class="v-grid-item-wrapper">
						<TrainingMaterial 
								:trainingMaterial="trainingMaterial" 
								:playEnabled="false"
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="sidebar-block" v-if="action!==Action.CREATE">
				<p class="sidebar-title">Info</p>
				<v-label>{{trainingMaterial.sys.id}}</v-label>
			</div>
		</SideBar>

		<!-- General Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="General Information" :expanded="true" :error="sectionMessage.generalInfo.error" :message="sectionMessage.generalInfo.message">
				<div class="field left-border">
					<v-label>Title <span class="mandatory">(required)</span></v-label>
					<LanguageFlag v-model="selectedLocale" class="flag" style="margin-top:10px !important"/>
					<v-text-field outlined required
						:hide-details="!titleError.length"
						v-model="trainingMaterial.fields.title[selectedLocale]"
						:error-messages="titleError"
					/>
				</div>

        <div class="field left-border">
          <v-row style="padding:10px">
            <v-label>Video <span class="mandatory">(required)</span></v-label>
          </v-row>
					<v-row class="pa-3">
						<div v-for="locale of locales" :key="locale.code">
							<TrainingVideoUpload :ref="'tvu_'+locale.code"
								:locale="locale"
								:setView="setView"
								:trainingMaterial="trainingMaterial"
								:media="trainingMaterial.fields.media.de" 
								@upload-training-video="uploadVideo" 
								@view-video="viewVideo"
							/>
						</div>
					</v-row>
        </div>
        
				<!-- Colour -->
				<div class="field left-border">
					<v-label>Thumbnail Colour <span class="mandatory">(required)</span></v-label><br/>
					<v-row class="pa-3">
						<span v-for="colour of colours" :key="colour.label">
						<v-btn :class="selectedColour===colour.value ? `ma-3 dot ${colour.label} ${colour.label}-active` : `ma-3 dot ${colour.label}`" elevation="0" @click="selectColour(colour.value)"></v-btn>
						</span>
					</v-row>
				</div>

				<!-- Patterns -->
				<div class="field left-border">
					<v-label>Thumbnail Pattern <span class="mandatory">(required)</span></v-label><br/>
					<!-- red -->
					<v-row class="pa-3" v-if="colours.find(x=>x.value === selectedColour && x.label==='red')">
						<div :class="selectedPattern === 'red/Pattern01.png' ? 'pattern-red-active ma-3' : 'pattern ma-3'" @click="selectPattern('red/Pattern01.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/red/Pattern01.png"/>
						</div>
						<div :class="selectedPattern === 'red/Pattern02.png' ? 'pattern-red-active ma-3' : 'pattern ma-3'" @click="selectPattern('red/Pattern02.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/red/Pattern02.png"/>
						</div>
						<div :class="selectedPattern === 'red/Pattern03.png' ? 'pattern-red-active ma-3' : 'pattern ma-3'" @click="selectPattern('red/Pattern03.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/red/Pattern03.png"/>
						</div>
						<div :class="selectedPattern === 'red/Pattern04.png' ? 'pattern-red-active ma-3' : 'pattern ma-3'" @click="selectPattern('red/Pattern04.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/red/Pattern04.png"/>
						</div>
						<div :class="selectedPattern === 'red/Pattern05.png' ? 'pattern-red-active ma-3' : 'pattern ma-3'" @click="selectPattern('red/Pattern05.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/red/Pattern05.png"/>
						</div>
						<div :class="selectedPattern === 'red/Pattern06.png' ? 'pattern-red-active ma-3' : 'pattern ma-3'" @click="selectPattern('red/Pattern06.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/red/Pattern06.png"/>
						</div>
					</v-row>

					<!-- orange -->
					<v-row class="pa-3" v-if="colours.find(x=>x.value === selectedColour && x.label==='orange')">
						<div :class="selectedPattern === 'orange/Pattern01.png' ? 'pattern-orange-active ma-3' : 'pattern ma-3'" @click="selectPattern('orange/Pattern01.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/orange/Pattern01.png"/>
						</div>
						<div :class="selectedPattern === 'orange/Pattern02.png' ? 'pattern-orange-active ma-3' : 'pattern ma-3'" @click="selectPattern('orange/Pattern02.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/orange/Pattern02.png"/>
						</div>
						<div :class="selectedPattern === 'orange/Pattern03.png' ? 'pattern-orange-active ma-3' : 'pattern ma-3'" @click="selectPattern('orange/Pattern03.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/orange/Pattern03.png"/>
						</div>
						<div :class="selectedPattern === 'orange/Pattern04.png' ? 'pattern-orange-active ma-3' : 'pattern ma-3'" @click="selectPattern('orange/Pattern04.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/orange/Pattern04.png"/>
						</div>
						<div :class="selectedPattern === 'orange/Pattern05.png' ? 'pattern-orange-active ma-3' : 'pattern ma-3'" @click="selectPattern('orange/Pattern05.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/orange/Pattern05.png"/>
						</div>
						<div :class="selectedPattern === 'orange/Pattern06.png' ? 'pattern-orange-active ma-3' : 'pattern ma-3'" @click="selectPattern('orange/Pattern06.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/orange/Pattern06.png"/>
						</div>
					</v-row>

					<!-- yellow -->
					<v-row class="pa-3" v-if="colours.find(x=>x.value === selectedColour && x.label==='yellow')">
						<div :class="selectedPattern === 'yellow/Pattern01.png' ? 'pattern-yellow-active ma-3' : 'pattern ma-3'" @click="selectPattern('yellow/Pattern01.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/yellow/Pattern01.png"/>
						</div>
						<div :class="selectedPattern === 'yellow/Pattern02.png' ? 'pattern-yellow-active ma-3' : 'pattern ma-3'" @click="selectPattern('yellow/Pattern02.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/yellow/Pattern02.png"/>
						</div>
						<div :class="selectedPattern === 'yellow/Pattern03.png' ? 'pattern-yellow-active ma-3' : 'pattern ma-3'" @click="selectPattern('yellow/Pattern03.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/yellow/Pattern03.png"/>
						</div>
						<div :class="selectedPattern === 'yellow/Pattern04.png' ? 'pattern-yellow-active ma-3' : 'pattern ma-3'" @click="selectPattern('yellow/Pattern04.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/yellow/Pattern04.png"/>
						</div>
						<div :class="selectedPattern === 'yellow/Pattern05.png' ? 'pattern-yellow-active ma-3' : 'pattern ma-3'" @click="selectPattern('yellow/Pattern05.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/yellow/Pattern05.png"/>
						</div>
						<div :class="selectedPattern === 'yellow/Pattern06.png' ? 'pattern-yellow-active ma-3' : 'pattern ma-3'" @click="selectPattern('yellow/Pattern06.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/yellow/Pattern06.png"/>
						</div>
					</v-row>

					<!-- green -->
					<v-row class="pa-3" v-if="colours.find(x=>x.value === selectedColour && x.label==='green')">
						<div :class="selectedPattern === 'green/Pattern01.png' ? 'pattern-green-active ma-3' : 'pattern ma-3'" @click="selectPattern('green/Pattern01.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/green/Pattern01.png"/>
						</div>
						<div :class="selectedPattern === 'green/Pattern02.png' ? 'pattern-green-active ma-3' : 'pattern ma-3'" @click="selectPattern('green/Pattern02.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/green/Pattern02.png"/>
						</div>
						<div :class="selectedPattern === 'green/Pattern03.png' ? 'pattern-green-active ma-3' : 'pattern ma-3'" @click="selectPattern('green/Pattern03.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/green/Pattern03.png"/>
						</div>
						<div :class="selectedPattern === 'green/Pattern04.png' ? 'pattern-green-active ma-3' : 'pattern ma-3'" @click="selectPattern('green/Pattern04.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/green/Pattern04.png"/>
						</div>
						<div :class="selectedPattern === 'green/Pattern05.png' ? 'pattern-green-active ma-3' : 'pattern ma-3'" @click="selectPattern('green/Pattern05.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/green/Pattern05.png"/>
						</div>
						<div :class="selectedPattern === 'green/Pattern06.png' ? 'pattern-green-active ma-3' : 'pattern ma-3'" @click="selectPattern('green/Pattern06.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/green/Pattern06.png"/>
						</div>
					</v-row>

					<!-- blue -->
					<v-row class="pa-3" v-if="colours.find(x=>x.value === selectedColour && x.label==='blue')">
						<div :class="selectedPattern === 'blue/Pattern01.png' ? 'pattern-blue-active ma-3' : 'pattern ma-3'" @click="selectPattern('blue/Pattern01.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/blue/Pattern01.png"/>
						</div>
						<div :class="selectedPattern === 'blue/Pattern02.png' ? 'pattern-blue-active ma-3' : 'pattern ma-3'" @click="selectPattern('blue/Pattern02.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/blue/Pattern02.png"/>
						</div>
						<div :class="selectedPattern === 'blue/Pattern03.png' ? 'pattern-blue-active ma-3' : 'pattern ma-3'" @click="selectPattern('blue/Pattern03.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/blue/Pattern03.png"/>
						</div>
						<div :class="selectedPattern === 'blue/Pattern04.png' ? 'pattern-blue-active ma-3' : 'pattern ma-3'" @click="selectPattern('blue/Pattern04.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/blue/Pattern04.png"/>
						</div>
						<div :class="selectedPattern === 'blue/Pattern05.png' ? 'pattern-blue-active ma-3' : 'pattern ma-3'" @click="selectPattern('blue/Pattern05.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/blue/Pattern05.png"/>
						</div>
						<div :class="selectedPattern === 'blue/Pattern06.png' ? 'pattern-blue-active ma-3' : 'pattern ma-3'" @click="selectPattern('blue/Pattern06.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/blue/Pattern06.png"/>
						</div>
					</v-row>

					<!-- purple -->
					<v-row class="pa-3" v-if="colours.find(x=>x.value === selectedColour && x.label==='purple')">
						<div :class="selectedPattern === 'purple/Pattern01.png' ? 'pattern-purple-active ma-3' : 'pattern ma-3'" @click="selectPattern('purple/Pattern01.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/purple/Pattern01.png"/>
						</div>
						<div :class="selectedPattern === 'purple/Pattern02.png' ? 'pattern-purple-active ma-3' : 'pattern ma-3'" @click="selectPattern('purple/Pattern02.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/purple/Pattern02.png"/>
						</div>
						<div :class="selectedPattern === 'purple/Pattern03.png' ? 'pattern-purple-active ma-3' : 'pattern ma-3'" @click="selectPattern('purple/Pattern03.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/purple/Pattern03.png"/>
						</div>
						<div :class="selectedPattern === 'purple/Pattern04.png' ? 'pattern-purple-active ma-3' : 'pattern ma-3'" @click="selectPattern('purple/Pattern04.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/purple/Pattern04.png"/>
						</div>
						<div :class="selectedPattern === 'purple/Pattern05.png' ? 'pattern-purple-active ma-3' : 'pattern ma-3'" @click="selectPattern('purple/Pattern05.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/purple/Pattern05.png"/>
						</div>
						<div :class="selectedPattern === 'purple/Pattern06.png' ? 'pattern-purple-active ma-3' : 'pattern ma-3'" @click="selectPattern('purple/Pattern06.png')">
							<v-img width="100%" height="100%" style="border-radius:5px;" src="@/assets/patterns/purple/Pattern06.png"/>
						</div>
					</v-row>
				</div>
			</Disclosure>
		</div>

		<!-- Change Log -->
		<div v-if="action!==Action.CREATE" class="pa-3" style="width: 100%">
			<AuditLog :entryId="trainingMaterial.sys.id" :contentType="'trainingMaterial'"/>
		</div>	

		<!-- Confirm Delete -->
		<Dialog ref="deleteDialog"
			confirmLabel="Delete"
			cancelLabel="Cancel"
			:confirm-handler="deleteTrainingMaterial"
			:cancel-handler="onActionCancelled"
			:showClose="false"
      title="Delete Training Material"
			:height="'200px'"
			:width="'600px'">
			<template #content>
				<div class="pa-3" style="width:100%">
					<v-container fluid>
						<div>Are you sure you want to delete this training material?</div>
					</v-container>
				</div>
			</template>
		</Dialog>	

		<!-- Video Player -->
		<div v-if="showVideoPlayer" class="dialog">
			<v-card height="100%">
				<v-container>
					<v-row align="center" justify="space-between" class="pa-3">
						<h1>{{trainingMaterial.fields.title[selectedVideoLocale.code]}}</h1>
						<v-btn class="btn" elevation="0" @click="showVideoPlayer=false">
							<v-icon>mdi-close-circle</v-icon> Close
						</v-btn>
					</v-row>
					<br />
					<video controls
						:src="selectedVideo"
						id="videoPlayer"
						width="100%"
            height="auto"
						:key="selectedVideo"></video>
				</v-container>
			</v-card>
		</div>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import SideBar from "@/components/common/SideBar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import Dialog from '@/components/common/Dialog.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import AuditLog from '@/components/auditLog/AuditLog.vue'
import TrainingMaterial from './TrainingMaterial.vue'
import Common from '@/mixins/Common.vue'
import { Action } from '@/plugins/enum.js'
import TrainingVideoUpload from './TrainingVideoUpload.vue'

export default {
	name: "TraingingMaterialDetailView",
	mixins: [ Common ],
	components: { Loading, SideBar, Alert, Disclosure, Dialog, LanguageFlag, TrainingVideoUpload, AuditLog, TrainingMaterial },
	
	props: {
		initialAction: { type: String }
	},

	data() {
		return {
			Action: Action,
			action: this.initialAction,
			
			categories: [],

			showVideoPlayer: false,
			selectedVideo: {},
			selectedVideoLocale: {},
			
			selectedColour: '#f24545',
			selectedPattern: '',

			colours: [
				{ label:'red',value:'#f24545' },
				{ label:'orange',value:'#ff7f29' },
				{ label:'yellow',value:'#ffb400' },
				{ label:'green',value:'#64c823' },
				{ label:'blue',value:'#08adef' },
				{ label:'purple',value:'#cd46b5' },
			],

			patterns: [],
			originalMedia: {},
			setView: false,
			
			sectionMessage: {
				generalInfo: {error:false,warning:false,message:''},
				displaySettings: {error:false,warning:false,message:''}
			},
			trainingMaterial: {
				sys: {id:''},
				fields: {
					title: {},
          type: {de: 'video'},
          userRoles: {de: ['serviceprovider','operator']},
					media: {de: {}},
					color: {de: ''},
          pattern: {de: ''},
					category: {de: {}}
				}
			},
			videoData: { de:'', en:'', fr:'', it:'' }
		}
	},
	
	computed: {
		titleError() {
			if (this.runValidation && !this.checkRequiredForAllLocales(this.locales, this.trainingMaterial.fields.title)) {
				return 'Title is required for all languages'
			}
			return ''
		},
		mediaError() {
			if (this.runValidation && 
				(!this.trainingMaterial.fields.media.de.fields) && 
				(this.videoData.de === '' || this.videoData.en === '' || this.videoData.fr === '' || this.videoData.it === '')) {
				return 'Media is required'
			}
			return ''
		}
	},

	async mounted() {
		this.getCategories()
		
		if (this.$store.state.selectedTrainingMaterial) {
			this.trainingMaterial = this.$store.state.selectedTrainingMaterial
			this.originalMedia = JSON.parse(JSON.stringify(this.trainingMaterial.fields.media.de))

			this.selectedColour = this.trainingMaterial?.fields?.color?.de
			this.selectedPattern = this.trainingMaterial?.fields?.pattern.de
		}
	},

	methods: {
		async upsertTrainingMaterial() {
			const isValid = this.validateTrainingMaterialDetails()
			if (!isValid) return

			this.loading = true
			this.setView = false
			
			this.trainingMaterial.fields.category.de = this.categories[0]
			
			try {
				const data = {
					trainingMaterial: this.trainingMaterial,
					videoData: this.videoData
				}

				this.trainingMaterial = await this.$httpPost(`/training-material`, data)
				
				if (this.action === Action.CREATE) {
					this.successTitle = 'CREATE TRAINING VIDEO'
					this.successDetail = 'Training video created successfully'
				} else {
					this.successTitle = 'UPDATE TRAINING VIDEO'
					this.successDetail = 'Training video updated successfully'
				}

				await this.$store.commit('setSelectedTrainingMaterial', this.trainingMaterial)

				if (this.$store.state.selectedTrainingMaterial) {
					this.trainingMaterial = this.$store.state.selectedTrainingMaterial
					this.originalMedia = JSON.parse(JSON.stringify(this.trainingMaterial.fields.media.de))

					this.selectedColour = this.trainingMaterial?.fields?.color?.de
					this.selectedPattern = this.trainingMaterial?.fields?.pattern.de
				}

				this.action = Action.VIEW
				this.loading = false
				this.setView = true

			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		async deleteTrainingMaterial() {
			this.loading = true
			
			try {
				await this.$httpDelete(`/training-material/${this.trainingMaterial.sys.id}`)
				
				this.successTitle = 'DELETE TRAINING VIDEO'
				this.successDetail = 'Training video deleted successfully'
				this.loading = false

				this.sleep(2000).then(() => { this.goback() })
			} catch (error) {
				this.showError(error)
			}
		},
		viewVideo(event) {
			this.showVideoPlayer = true
			this.selectedVideoLocale = event.locale
			this.selectedVideo = this.getAssetUrl(event.videoMedia, event.locale.code)
		},
		uploadVideo(event) {
			const key = Object.keys(event)
			this.videoData[key] = event[key]
		},
		selectColour(colour) {
			this.trainingMaterial.fields.color.de = colour
			this.selectedColour = colour
		},
		selectPattern(pattern) {
			this.trainingMaterial.fields.pattern.de = pattern
			this.selectedPattern = pattern
		},
		onActionCancelled() {
			this.$refs.deleteDialog.show = false
		},
		async getCategories() {
			try {
				this.categories = await this.$httpGet(`/training-categories`)
			}
			catch (error) {
				this.showError(error)
			}
		},
		validateTrainingMaterialDetails() {
			this.runValidation = true
			let isValid = true

			if (this.titleError.length) {
				this.sectionMessage.generalInfo.error = true
				this.sectionMessage.generalInfo.message = 'Please enter the required information'
				isValid = false
			} else {
				this.sectionMessage.generalInfo.error = false
			}

			if (this.mediaError.length) {
				this.sectionMessage.displaySettings.error = true
				this.sectionMessage.displaySettings.message = 'Please upload a video'
				isValid = false
			} else {
				this.sectionMessage.displaySettings.error = false
			}

			if (isValid === false) {
				this.errorTitle = 'ERROR'
				this.errorDetail = 'Please enter the required information'
			}
			
			return isValid
		},
		goback() {
			this.$router.push('/trainingmaterials')
		}
	}
}
</script>

<style scoped>
.flag { position: absolute; z-index: 10; top: 54px; margin-left: -26px; zoom: 0.8; }
.preview-container { width: 100%; padding-top: 100%; position: relative; margin: auto; }
.preview-component {  top: 0; left: 0; bottom: 0; right: 0; }
.dialog { position: fixed; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 1200px; background-color: #ffffff; border-radius: 10px; box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2); z-index: 999; }
.v-grid-item-wrapper {display: block;position: absolute;box-sizing: border-box;user-select: none;transform: translate3d(0px, 0px, 0px);z-index: 1;padding: 0;margin-left:5px;top: 0;width:260px;height: 250px;}
li { font-size: 16px; }

.dot { min-height: 45px !important; max-height: 45px !important; min-width: 45px !important; max-width: 45px !important; background-color: #000; border-radius: 50%; display: inline-block; outline-offset: 3px; }
.red { background-color: #f24545; }
.red-active { outline: 4px solid #f24545; }
.orange { background-color: #ff7f29; }
.orange-active { outline: 4px solid #ff7f29; }
.yellow { background-color: #ffb400; }
.yellow-active { outline: 4px solid #ffb400; }
.green { background-color: #64c823; }
.green-active { outline: 4px solid #64c823; }
.blue { background-color: #08adef; }
.blue-active { outline: 4px solid #08adef; }
.purple { background-color: #cd46b5; }
.purple-active { outline: 4px solid #cd46b5; } 

.pattern { width:100px;height:100px;border-radius:5px;cursor:pointer }
.pattern-red-active { width:100px;height:100px;border-radius:5px;outline-offset: 3px;outline: 4px solid #f24545;}
.pattern-orange-active { width:100px;height:100px;border-radius:5px;outline-offset: 3px;outline: 4px solid #ff7f29;}
.pattern-yellow-active { width:100px;height:100px;border-radius:5px;outline-offset: 3px;outline: 4px solid #ffb400;}
.pattern-green-active { width:100px;height:100px;border-radius:5px;outline-offset: 3px;outline: 4px solid #64c823;}
.pattern-blue-active { width:100px;height:100px;border-radius:5px;outline-offset: 3px;outline: 4px solid #08adef;}
.pattern-purple-active { width:100px;height:100px;border-radius:5px;outline-offset: 3px;outline: 4px solid #cd46b5;}
</style>