<template>
	<v-row class="tableFooter" align="center">
		<v-spacer />
		<v-btn text @click="previousPage()" :disabled="value == 0"><v-icon>mdi-chevron-left</v-icon></v-btn>
		<div class="count" v-if="count">{{ value + 1 }} - {{ pageCount }}</div>
		<v-btn text @click="nextPage()" :disabled="!hasMore"><v-icon>mdi-chevron-right</v-icon></v-btn>
		<span style="margin-right:10px"> of {{total}}</span>
	</v-row>
</template>

<script>
export default {
	name: 'TablePaginator',
	props: {
		value: { type: Number, default: 0 },
		limit: { type: Number, default: 15 },
		total: Number,
		results: Array,
	},

	data() {
		return {
			hasMore: null,
			count: null
		}
	},

	watch: {
		results(v) {
			this.handleResults(v)
		}
	},

	computed: {
		pageCount() {
			const value = Math.min(this.value + this.count, this.value + this.limit)
			return value > this.total ? this.total : value
		}
	},

	mounted() {
		if (this.results) this.handleResults(this.results)
	},

	methods: {
		nextPage() {
			this.$emit('input', this.value + this.limit)
		},
		previousPage() {
			this.$emit('input', Math.max(0, this.value - this.limit))
		},
		handleResults(results) {
			this.count = Math.min(this.limit, results?.length)
			this.hasMore = Math.min(this.value + this.count, this.value + this.limit) < this.total
			return results
		}
	}
}
</script>

<style scoped>
.count { display: inline-block; line-height: initial; }
</style>