<template>
	<div class="field left-border">
		<div style="display: flex; align-items: center; width: 100%; gap: 10px;"
			:style="{
				'padding-right': translateButtonMargin,
			}"
		>
			<v-label>{{ label }}</v-label>
			<div style="flex-grow: 1"></div>
			<v-progress-circular indeterminate :size="16" v-if="loading" />
			<v-btn small style="margin-bottom: -7px; margin-top: -7px;"
				v-if="translateable && host.selectedLocale != 'en' && !value[host.selectedLocale] && value.en"
				@click="translate('en', host.selectedLocale)"
			>
				<img src="@/assets/icons/openai.svg" style="width:16px; height:16px;" />&nbsp;
				en&rarr;{{ host.selectedLocale }}
			</v-btn>
			<v-btn small style="margin-bottom: -7px; margin-top: -7px;"
				v-if="translateable && host.selectedLocale != 'de' && !value[host.selectedLocale] && value.de"
				@click="translate('de', host.selectedLocale)"
			>
				<img src="@/assets/icons/openai.svg" style="width:16px; height:16px;" />&nbsp;
				de&rarr;{{ host.selectedLocale }}
			</v-btn>
		</div>
		<LanguageFlag v-if="translateable" v-model="host.selectedLocale" class="flag" style="margin-top: 10px !important"/>
		<slot></slot>
	</div>
</template>

<script>
import LanguageFlag from './LanguageFlag.vue'
import Common from '@/mixins/Common.vue'

export default {
	components: { LanguageFlag },
	mixins: [ Common ],
	props: {
		label: String,
		value: Object, // { de: '', en: '', fr: '', it: '' }
		translateable: Boolean,
		translateButtonMargin: { type: String, default: '0px' },
	},
	inject: [ 'host' ],
	data: () => ({
		loading: false,
	}),
	methods: {
	    async translate(sourceLocale, targetLocale) {
			this.loading = true
			try {
				const text = await this.translateSingleWithChatGpt(this.value[sourceLocale], sourceLocale, targetLocale, this.host.locales)
				if (text) this.value[targetLocale] = text
				this.$emit('translated', { sourceLocale, targetLocale, text })
			}
			catch (error) {
				console.error(error)
			}
			this.loading = false
		},
	},
}
</script>

<style scoped>
.flag { position: absolute; z-index: 10; top: 54px; margin-left: -26px; zoom: 0.8; }
.field { margin-top: 0; }
</style>