<template>
	<div class='Field BooleanField'>
		<label class="fieldTitle">{{ title ? title : def.name }}</label>
		<v-radio-group v-model="model" row :disabled="disabled">
			<v-radio label="Yes" :value="true"></v-radio>
			<v-radio label="No" :value="false"></v-radio>
		</v-radio-group>
	</div>
</template>

<script>
export default {
	name: 'BooleanField',
	props: {
		value: Boolean,
		def: Object,
		title: String,
		disabled: Boolean
	},
	data() {
		return {
		model: null
	}},
	watch: {
		value(n) {
			this.model = n
		},
		model(n) {
			this.$emit('input', n)
		}
	},
	mounted() {
		this.model = this.value
	},
}
</script>

<style scoped>
.Field { padding-left: 10px; border-left: 3px solid silver; }
.fieldTitle { color: gray; font-size: smaller; }
</style>