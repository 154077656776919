<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading v-if="loading" :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>&nbsp;
				</v-row>
			</div>
		</div>
		
		<SideBar>
			<div class="sidebar-block">
				<p class="sidebar-title">Actions</p>
				<div class="buttonBlock">
					<v-btn block class="btn green" elevation="0" dark 
						@click="updateTranslations()">
						<v-icon>mdi-check</v-icon>Save Changes
					</v-btn>
				</div>
			</div>

			<div class="sidebar-block">
				<p class="sidebar-title">Applications</p>
				<ul id="navlist">
            <a v-for="application of applications" :key="application.sys.id">
              <li @click="selectApplication(application.sys.id)" :class="{ active: application.sys.id == activeLink }">
                {{application.fields.title.en}}
                <span v-if="application.hasDifferences===true" style="float:right"><v-icon :color="application.sys.id === activeLink ? '#fff' : '#FDB537'">mdi-alert</v-icon></span>
								<span v-if="application.hasDifferences===false" style="float:right"><v-icon :color="application.sys.id === activeLink ? '#fff' : '#62c522'">mdi-check-circle</v-icon></span>
              </li>
            </a>
          </ul>
			</div>
		</SideBar>

		<!-- Translations -->
		<div class="pa-3" style="width: 100%">
			<div class="detail-card">
				<v-tabs show-arrows slider-color="#16B0EF" grow v-model="tab">
					<v-tab 
						v-for="locale in locales"
						:key="locale.code" 
						@click="setSelectedLocale(locale.code)">
						{{ locale.name }}
						<v-icon v-if="locale.hasDifferences===true" color="#FDB537">mdi-alert</v-icon>
						<v-icon v-if="locale.hasDifferences===false" color="#62c522">mdi-check-circle</v-icon>
					</v-tab>
				</v-tabs>
				
				<v-row style="width:100%" class="mt-5 ml-2" v-if="stagingText && stagingText.translation_text">
					<v-row style="width:100%">
						<v-col><h2>Staging</h2></v-col>
						<v-col cols="1" class="pr-0 mr-0"/>
						<v-col class="pl-0 ml-0"><h2>Live</h2></v-col>
					</v-row>
					<div style="width:100%" class="pa-0" v-for="objKey in Object.keys(stagingText.translation_text)" :key="objKey">
						<v-row class="pa-0  mt-1" :style="stagingText.translation_text[objKey] !== liveText.translation_text[objKey] ? 'background-color:#efefef;width:100%' : 'width:100%'">
							<v-col class="pa-0">
								<div class="field pl-0">
									<v-row style="padding-left:12px">
										<v-label>{{objKey}}</v-label>
									</v-row>
									<v-textarea outlined required hide-details rows="2"
										v-model="stagingText.translation_text[objKey]"
									/>	
								</div>
							</v-col>
							
							<v-col cols="1" align="center" justify="center" style="margin-top:50px" class="pa-0">
									<v-icon @click="copyLiveToStage(objKey)">mdi-arrow-left-circle</v-icon>
									<br/>
									<v-icon @click="copyStageToLive(objKey)">mdi-arrow-right-circle</v-icon>
							</v-col>

							<v-col class="pa-0">
								<div class="field">
									<v-row style="padding-left:12px">
										<v-label>{{objKey}}</v-label>
									</v-row>
									<v-textarea outlined required hide-details rows="2"
										v-model="liveText.translation_text[objKey]"
									/>	
								</div>
							</v-col>
						</v-row>
					</div>
				</v-row>

				<br/>
			</div>
		</div>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import SideBar from "@/components/common/SideBar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Dialog from '@/components/common/Dialog.vue'
import { Action } from '@/plugins/enum.js'
import _ from 'lodash';

export default {
	name: "TranslationsCompareView",
	components: { Loading, SideBar, Alert, Disclosure, LanguageFlag, Dialog },
	mixins: [ Common ],

	data() {
		return {
			Action: Action,

			activeLink: '',
			tab: 0,

			applications: [],
			stagingTranslations: [],
			liveTranslations: [],
			
			stagingText: {},
			liveText: {},
			
			stAppTranslations: [],
			liAppTranslations: []
		}
	},

	async mounted() {
		this.loading = true
		await this.getTranslationsForCompare()
		await this.selectApplication('CORE')
		this.loading = false
	},

	methods: {
		selectApplication(key) {
			this.activeLink = key
			this.selectedApplication = key
			this.compareTranslations()
		},
		setSelectedLocale(locale) {
			this.selectedLocale = locale
			this.compareTranslations()
		},
		validJsonKey(evt) {
			evt = (evt) ? evt : window.event
			const validChars = new RegExp("^[a-zA-Z0-9]*$") 
			if (!validChars.test(evt.key)) {
				evt.preventDefault()
				return false
			}
			return true
		},
		goback() {
			this.$router.push('/translations')
		},
		async getApplications() {
			try {
				const res = await this.$httpGet(`/applications?include=0&limit=1000`)
				let applications = res.applications
				if (applications?.length) {
					applications = applications.filter(x => x.fields.userRoles.de.includes('engineer') === false)
				}
				applications.sort((a, b) => (a.fields.title.en > b.fields.title.en) ? 1 : -1)
				applications.unshift({sys:{id:'CORE'},fields:{title:{en:'CORE'}}})
				return applications
			} catch (error) {
				this.showError(error)
			}
		},
		async getTranslationsForCompare() {
			try {
				let applications = await this.getApplications()
		
				this.translations = await this.$httpGet(`/translations-compare`)

				if (!this.translations?.stagingTranslations?.length  && !this.translations?.liveTranslations?.length) return

				this.stagingTranslations = this.translations.stagingTranslations
				this.liveTranslations = this.translations.liveTranslations

				let appComponentIds = [...new Set([...this.stagingTranslations.map(st => st.appcomponent_id), ...this.liveTranslations.map(lt => lt.appcomponent_id)])]
				this.applications = applications.filter(app => appComponentIds.find(appId => appId === app.sys.id))

				for (const application of this.applications) {
					let isSame = true
					for (let locale of this.locales) {
						const stAppTranslations = this.stagingTranslations?.find(st => st.appcomponent_id === application.sys.id && st.locale === locale.code);
						const liAppTranslations = this.liveTranslations?.find(li => li.appcomponent_id === application.sys.id && li.locale === locale.code);

						isSame = _.isEqual(stAppTranslations?.translation_text, liAppTranslations?.translation_text)
						if (isSame === false) break
					}

					application.hasDifferences = !isSame
				}
	
			} catch (error) {
				this.showError(error)
			}
		},
		async compareTranslations() {
			this.stAppTranslations = this.stagingTranslations?.filter(st => st.appcomponent_id === this.selectedApplication);
			this.liAppTranslations = this.liveTranslations?.filter(li => li.appcomponent_id === this.selectedApplication);
			
			for (let locale of this.locales) {
				locale.hasDifferences = false

				const stagingLocaleText = this.stAppTranslations.find(stapptrans => stapptrans.locale === locale.code);
				const liveLocaleText = this.liAppTranslations.find(liapptrans => liapptrans.locale === locale.code);

				locale.hasDifferences = !_.isEqual(stagingLocaleText.translation_text, liveLocaleText.translation_text);

				if (locale.code === this.selectedLocale) {
					this.stagingText = stagingLocaleText
					this.liveText = liveLocaleText
				}
			}
		},
		async updateTranslations() {
			this.loading = true
			let data = {
				stagingTranslations: this.stAppTranslations,
				liveTranslations: this.liAppTranslations
			}

			try {
				await this.$httpPut(`/merge-translations`, data)
				
				this.successTitle = 'UPDATE TRANSLATIONS'
				this.successDetail = 'Translations updated successfully'

				await this.getTranslationsForCompare()
				await this.selectApplication('CORE')
			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		copyLiveToStage(objKey) {
			this.stagingText.translation_text[objKey] = this.liveText.translation_text[objKey]
		},
		copyStageToLive(objKey) {
			this.liveText.translation_text[objKey] = this.stagingText.translation_text[objKey]
		},
		isArrayEqual(x, y) {
			const isSameSize = _.size(x) === _.size(y);
			return isSameSize && _(x).xorWith(y, _.isEqual).isEmpty();
		}
	}
}
</script>

<style scoped>
.flag { position: absolute; z-index: 10; margin-top: 34px; margin-left: -26px; zoom: 0.8; }

.v-application ul {
	padding-left: 0 !important;
}
#navlist li {
	list-style-type: none;
	width: 100%;
	padding: 10px 0px 10px 0px !important;
	align-items: middle;
	font-family: 'Inter', sans-serif;
	font-size: 17px;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: -0.25px;
	font-weight: 500; 
}
#navlist a {
	color: #000000;
}
#navlist .active { 
	width: 107%;
	border-top: 1px solid #ededed;
	border-bottom: 1px solid #ededed; 
	background-color: #00aeef !important;
	color: #ffffff !important;
	margin-left: -10px;
	padding-left: 10px !important;
	padding-right: 9px !important;
}
</style>