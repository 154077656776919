<template>
	<div class="pa-3">
		<VideoCard 
			v-if="!isUpdate && media.fields"
			:media="media" 
			:canEdit="true" :canSelect="false" :showTitle="false"
			@play-video="viewVideo()"
			@show-media-update="showUpdateMedia()"
		/>
		<FileUpload 
			v-if="isUpdate || !media.fields"
			:options="dropOptions" ref="fileUpload" 
			@file-added="onFileAdded" 
			@file-removed="cancelUpload"
		/>
		<br v-if="isUpdate"/><v-row v-if="isUpdate" align="center" justify="center" class="pa-3"><v-btn class="btn" elevation="0" dense @click="cancelUpload()">Cancel Upload</v-btn></v-row>
		<br/><v-row align="center" justify="center" class="pa-3"><img :src="flagIcon(locale.code)" width="24px"/>&nbsp;<b>{{locale.name}}</b></v-row>
	</div>
</template>

<script>
import VideoCard from '@/components/common/VideoCard.vue'
import FileUpload from '@/components/common/FileUpload.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'TrainingVideoUpload',
	mixins: [ Common ],
	components: { VideoCard, FileUpload },
	props: {
		trainingMaterial: Object,
		media: Object,
		locale: Object,
		setView: Boolean,
	},
	data() {
		return {
			isUpdate: false,
			showVideoPlayer: false,
			dropOptions: {
				acceptedFiles: '.mp4,.mov',
				addRemoveLinks: true,
				maxFiles: 1,
			},
			videoData: '',
		}
	},
	watch: {
		setView() {
			if (this.isUpdate === true && this.setView === true) {
				this.isUpdate = false
			}
		}
	},
	methods: {
		flagIcon(locale) {
			let images = require.context('@/assets/flags/', false, /\.svg$/)

			if (locale == "de") {
				if (this.userSpecificGerman)
					return images('./' + this.userSpecificGerman + '.svg')
				return images('./de.svg')
			}
			if (locale) return images('./' + locale + '.svg')
			return images('./en.svg')
		},
		showUpdateMedia() {
			this.isUpdate = true
		},
		onFileAdded(file) {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => {
				this.videoData = {[this.locale.code]: reader.result}
				this.$emit('upload-training-video', this.videoData)
			}
		},
		cancelUpload() {
			this.videoData = ''
			this.isUpdate = false
		},
		viewVideo() {
			if (!this.media.fields.file[this.locale.code].url) {
				return
			}
			this.$emit("view-video", {videoMedia:this.media,locale:this.locale})
		},
	},
}
</script>