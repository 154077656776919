import Router from "vue-router"

import Login from '@/views/main/Login.vue'
import Dashboard from '@/views/dashboard/Dashboard.vue'

import ApplicationsView from '@/views/applications/appManager/ApplicationsView.vue'
import ApplicationDetailView from '@/views/applications/appManager/ApplicationDetailView.vue'

import ServiceTypesView from '@/views/applications/serviceTypes/ServiceTypesView.vue'
import ServiceTypeDetailView from '@/views/applications/serviceTypes/ServiceTypeDetailView.vue'

import ReleasesView from '@/views/applications/releaseManager/ReleasesView.vue'
import ReleaseDetailView from '@/views/applications/releaseManager/ReleaseDetailView.vue'

import TranslationsView from '@/views/applications/translationManager/TranslationsView.vue'
import TranslationsCompareView from '@/views/applications/translationManager/TranslationsCompareView.vue'

import PeakSyncView from '@/views/applications/peakSync/PeakSyncView.vue'
import PeakSyncDetailView from '@/views/applications/peakSync/PeakSyncDetailView.vue'

import ClientsView from '@/views/applications/clientManager/ClientsView.vue'
import ClientDetailView from '@/views/applications/clientManager/ClientDetailView.vue'

import ContentfulSyncView from '@/views/applications/contentfulSync/ContentfulSyncView.vue'
import ContentfulSyncDetailView from '@/views/applications/contentfulSync/ContentfulSyncDetailView.vue'

import LiveSyncView from '@/views/applications/liveSync/LiveSyncView.vue'
import LiveSyncDetailView from '@/views/applications/liveSync/LiveSyncDetailView.vue'

import UserManagerView from '@/views/applications/userManager/UserManagerView.vue'
import UserDetailView from '@/views/applications/userManager/UserDetailView.vue'

import RegionMappingsView from '@/views/applications/regionMapping/RegionMappingsView.vue'
import RegionMappingDetailView from '@/views/applications/regionMapping/RegionMappingDetailView.vue'

import TrainingLibraryView from '@/views/applications/trainingLibrary/TrainingLibraryView.vue'
import TrainingMaterialDetailView from '@/views/applications/trainingLibrary/TrainingMaterialDetailView.vue'

import AuditLogsView from '@/views/applications/logViewer/AuditLogsView.vue'

import SystemSettings from '@/views/applications/systemSettings/SystemDetailView.vue'

let routes = [
    { 
      path: "/", 
      component: Login 
    },
    { 
      path: "/dashboard", 
      component: Dashboard 
    },
    { 
      path: "/applications", 
      component: ApplicationsView 
    },
    { 
      path: "/application-detail", 
      component: ApplicationDetailView,
      props: route => ({ initialAction: route.query.action })
    },
    { 
      path: "/servicetypes", 
      component: ServiceTypesView 
    },
    { 
      path: "/servicetype-detail", 
      component: ServiceTypeDetailView 
    },
    {
      path: "/releases",
      component: ReleasesView
    },
    {
      path: "/release-detail",
      component: ReleaseDetailView
    },
    {
      path: "/translations",
      component: TranslationsView
    },
    {
      path: "/translations-compare",
      component: TranslationsCompareView
    },
    {
      path: "/peaksync",
      component: PeakSyncView
    },
    {
      path: "/peaksync-detail",
      component: PeakSyncDetailView
    },
    {
      path: "/clients",
      component: ClientsView
    },
    {
      path: "/client-detail",
      component: ClientDetailView
    },
    {
      path: "/contentfulsync",
      component: ContentfulSyncView
    },
    {
      path: "/contentfulsync-detail",
      component: ContentfulSyncDetailView,
      props: route => ({ client: route.query.client, space: route.query.space, environment: route.query.environment })
    },
    {
      path: "/livesync",
      component: LiveSyncView
    },
    {
      path: "/livesync-detail",
      component: LiveSyncDetailView
    },
    {
      path: "/usermanager",
      component: UserManagerView
    },
    {
        path: "/user-detail",
        component: UserDetailView,
        props: route => ({ initialAction: route.query.action, userType: route.query.userType })
    },
    {
      path: "/regionmappings",
      component: RegionMappingsView
    },
    {
      path: "/regionmapping-detail",
      component: RegionMappingDetailView,
      props: route => ({ initialAction: route.query.action })
    },
    {
      path: "/trainingmaterials",
      component: TrainingLibraryView
    },
    {
      path: "/trainingmaterial-detail",
      component: TrainingMaterialDetailView,
      props: route => ({ initialAction: route.query.action })
    },
    {
      path: "/logViewer",
      component: AuditLogsView
    },
    {
      path: "/system",
      component: SystemSettings
    }
]

export default new Router({
    mode: 'history',
    routes: routes
})
