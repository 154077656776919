<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>
					&nbsp;<h2>Region Mapping Detail View</h2>
				</v-row>
			</div>
		</div>
		
		<SideBar>
			<div class="sidebar-block">
				<p class="sidebar-title">Actions</p>
				<div class="buttonBlock">
					<v-btn block class="btn blue mt-3" elevation="0" dark @click="upsertRegionMapping()">Save Changes</v-btn>
					<v-btn v-if="action!=='create'" block class="btn grey mt-3" elevation="0" @click="$refs.duplicateDialog.show=true"><v-icon style="margin-right:0px">mdi-content-copy</v-icon>Duplicate</v-btn>
					<v-btn v-if="action!=='create'" block class="btn red mt-3" elevation="0" dark @click="$refs.deleteDialog.show=true">Delete Region Mapping</v-btn>				
				</div>
			</div>

			<div class="sidebar-block" v-if="action!==Action.CREATE">
				<p class="sidebar-title">Info</p>
				<v-label>{{regionMapping.sys.id}}</v-label>
			</div>
		</SideBar>

    <!-- Region Mapping Details -->
   	<div class="pa-3" style="width: 100%">
			<Disclosure title="Region Mapping Details" data-cy="regionMappingInfo" ref="SECTION_regionMappingInfo" :expanded="true">
				<div class="field left-border">
					<label>Title <span class="mandatory">(required)</span></label>
					<v-text-field outlined required counter="256"
						:hide-details="!titleError"
						:error-messages="titleError"
						v-model.trim="regionMapping.fields.title.de"
						data-cy="title"/>
				</div>

				<div class="field left-border">
					<label>Region <span class="mandatory">(required)</span></label>
					<v-select outlined return-object
						:hide-details="!regionError"
						:error-messages="regionError"
						v-model="selectedRegion"
						:items="regions"
						:item-text="item => item && item.fields && item.fields.title ? item.fields.title.de : ''"/>
				</div>

				<div class="field left-border">
					<label>Client <span class="mandatory">(required)</span></label>
					<v-select outlined return-object 
						:hide-details="!clientError"
						:error-messages="clientError"
						v-model="selectedClient"
						:items="clients"
						:item-text="item => item && item.fields && item.fields.title ? item.fields.title.de : ''"
						@input="getClientData()"
						data-cy="client"/>
				</div>

				<div class="field left-border">
					<div data-cy="tags" @click="closeDropDown('vTags')">
						<label>Tags</label>
						<v-select outlined  
							ref="vTags"
							v-model="regionMapping.fields.tags.de"
							:items="tags"
							:menu-props="{ offsetY: true }"
							multiple small-chips hide-details>
							<template #selection="{ item }">
								<v-chip :color="getColor(item)" dark>{{item}}</v-chip>
							</template>
						</v-select>
					</div>
				</div>

				<div class="field left-border">
					<div data-cy="salesChannels" @click="closeDropDown('vSalesChannels')">
						<label>Sales Channels</label>
						<v-select chips multiple outlined hide-details return-object 
							ref="vSalesChannels"
							v-model="regionMapping.fields.salesChannels.de"
							:items="salesChannels"
							:item-text="item => item && item.fields && item.fields.title ? item.fields.title.de : ''"
							:menu-props="{ offsetY: true }">
							<template v-slot:selection="{ attrs, item, select, selected }">
							<v-chip
								class="chip"
								v-bind="attrs"
								:input-value="selected"
								close
								@click="select"
								@click:close="remove(item)">
								{{ item.fields.title.de }}
							</v-chip>
							</template>
						</v-select>
					</div>
				</div>

				<div class="field left-border">
					<label>ZIP Country Code <span class="mandatory">(required)</span></label>
					<v-select outlined  
						:hide-details="!zipCountryCodeError"
						:error-messages="zipCountryCodeError"
						v-model="regionMapping.fields.zipCountryCode.de"
						:items="countryCodeLookup"
						data-cy="zipCountryCode"
					/>
				</div>

				<div class="field left-border">
					<label>ZIP Codes</label><br/><br/>
					<v-row style="padding-left:10px;" v-for="(zipCode, zipIndex) in regionMapping.fields.zipCodes.de" :key="zipIndex">
						<v-text-field outlined hide-details style="padding-right:10px"
							v-model="regionMapping.fields.zipCodes.de[zipIndex].zipMin"
							placeholder="Zip Min"/>
						<v-text-field outlined hide-details style="padding-right:10px"
							v-model="regionMapping.fields.zipCodes.de[zipIndex].zipMax"
							placeholder="Zip Max"/>

						<v-btn v-if="regionMapping.fields.zipCodes.de.length === 1" class="btn-row grey mt-3" elevation="0" @click="regionMapping.fields.zipCodes.de = ['']"><v-icon size="24px">mdi-close</v-icon></v-btn>
						<v-btn v-if="regionMapping.fields.zipCodes.de.length > 1" class="btn-row grey mt-3"  elevation="0" @click="regionMapping.fields.zipCodes.de.splice(zipIndex, 1)"><v-icon size="24px" color="red">mdi-delete</v-icon></v-btn>
					</v-row><br/>
					<v-btn class="btn grey" elevation="0" @click="addZipRange()"><v-icon size="24px">mdi-plus</v-icon> Add Another ZIP Range</v-btn>
				</div>

				<div class="field left-border">
					<label>Geo Fence</label><br/>
					<GeofenceField v-model="regionMapping.fields.geofence.de" :def="{name:''}"></GeofenceField>
				</div>

			</Disclosure>
    </div>

		<!-- Change Log -->
		<div v-if="action!==Action.CREATE" class="pa-3" style="width: 100%">
			<AuditLog :entryId="regionMapping.sys.id" :contentType="'regionMapping'"/>
		</div>	

		<!-- Confirm Delete -->
		<Dialog ref="deleteDialog"
			confirmLabel="Delete"
			cancelLabel="Cancel"
			:confirm-handler="deleteRegionMapping"
			:cancel-handler="onActionCancelled"
			:showClose="false"
      title="Delete Region Mapping"
			:height="'200px'"
			:width="'600px'">
			<template #content>
				<div class="pa-3" style="width:100%">
					<v-container fluid>
						<div>Are you sure you want to delete this region mapping?</div>
					</v-container>
				</div>
			</template>
		</Dialog>

		<!-- Select new client for which the region mapping should be copied dialog -->
		<Dialog ref="duplicateDialog"
			confirmLabel="Duplicate"
			cancelLabel="Cancel"
			:confirm-handler="copyRegionMapping"
			:cancel-handler="onActionCancelled"
			:showClose="false"
			title="Duplicate Region Mapping"
			:height="'250px'"
			:width="'600px'">
			<template #content>
				<div class="pa-3" style="width:100%">
					<v-container fluid>
						<div>Select a client for which the region mapping should be duplicated</div>
						<v-select outlined return-object
						v-model="selectedDuplicateClient"
						:items="filteredClients"
						:item-text="item => item && item.fields && item.fields.title ? item.fields.title.de : ''"
						data-cy="client"/>
					</v-container>
				</div>
			</template>
		</Dialog>

		<!-- Confirm Duplicate Region Mapping -->
		<Dialog ref="switchDialog"
			confirmLabel="Switch to Duplicate"
			cancelLabel="Cancel"
			:confirm-handler="onConfirmViewDuplicate"
			:cancel-handler="onActionCancelled"
			:showClose="false"
			title="Duplicate Region Mapping" 
			:height="'300px'"
			:width="'600px'">
			<template #content>
				<div class="pa-3" style="width:100%">
					<v-container fluid>
						<div>A copy of this region mapping has been created.<br/>Please select whether you want to switch to the copy of the region mapping or whether you want to stay on the original. </div>
					</v-container>
				</div>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Disclosure from '@/components/common/Disclosure.vue'
import Alert from '@/components/common/Alert.vue'
import Dialog from '@/components/common/Dialog.vue'
import GeofenceField from '@/components/entry/GeofenceField.vue'
import SideBar from "@/components/common/SideBar"
import AuditLog from '@/components/auditLog/AuditLog.vue'
import Common from '@/mixins/Common.vue'
import { Action } from '@/plugins/enum.js'

export default {
	name: 'RegionMappingDetail',
	components: { SideBar, Loading, Disclosure, Alert, Dialog, GeofenceField, AuditLog },
	mixins: [ Common ],

	props: {
		initialAction: { type: String }
	},

	data() {
		return {
			Action: Action,
			action: this.initialAction,

			clients: [],
			filteredClients: [],
			regions: [],
			tags: [],
			selectedTags: [],
			salesChannels: [],
			selectedSalesChannels: [],
			tagColors: ['#ac3', '#c3f', '#3cf', '#3ca', '#fc3', '#f3c', '#c96', '#c69', '#6c9', '#69c', '#96c', '#9c6', '#cc3', '#c3c', '#cc3', '#c33', '#33c', '#3c3', '#63f', '#36f' ],
			countryCodeLookup: ["AT", "CH", "DE", "IT", "FR", "LI"],
			selectedClient: {},
			selectedRegion: {},

			duplicateRegionMapping: {},
			selectedDuplicateClient: {},

			sectionMessage: {
				regionMappingInfo: {error:false,warning:false,message:''}
			},
			
			regionMapping: {
				sys: {
					id: '',
					contentType: {sys:{id:'regionMapping'}}
				},
				fields:{
					title: {de: ''},
					region: {de: {}},
					client: {de: {}},
					zipCountryCode: {de: ''},
					geofence: {de: {}},
					zipCodes: {de: [{zipMin:'',zipMax:''}]},
					tags: {de: []},
					salesChannels: {de: []}
				}
			},
		}
	},

	computed: {
		titleError() {
			return this.runValidation && this.regionMapping?.fields?.title?.de === '' ? 'Title is required' : ''
		},
		regionError() {
			return this.runValidation && !this.regionMapping?.fields?.region?.de?.sys?.id && !this.selectedRegion?.sys?.id ? 'Region is required' : ''
		},
		clientError() {
			return this.runValidation && !this.regionMapping?.fields?.client?.de?.sys?.id && !this.selectedClient?.sys?.id ? 'Client is required' : ''
		},
		zipCountryCodeError() {
			return this.runValidation && this.regionMapping?.fields?.zipCountryCode?.de === '' ? 'ZIP Country Code is required' : ''
		}
	},

	async mounted() {
		this.loading = true
		
		await this.getClients()
		await this.getRegions()

		if (this.action === Action.VIEW) {
			await this.getRegionMapping()
			await this.getClientData()
		}
		
		this.loading = false
	},

	methods: {
		async getRegionMapping() {
			this.loading = true
			try {
				this.regionMapping = await this.$httpGet(`/region-mapping/${this.$store.state.selectedRegionMapping.sys.id}`)
				await this.$store.commit('setSelectedRegionMapping', this.regionMapping)

				this.selectedClient = this.regionMapping.fields.client?.de
				this.selectedRegion = this.regionMapping.fields.region?.de

				//Remove region mapping client for duplicate function
				this.filteredClients = JSON.parse(JSON.stringify(this.clients))
				const index = this.clients.findIndex(x => x.sys.id === this.regionMapping.fields.client?.de?.sys?.id)

				if (index > -1) {
					this.filteredClients.splice(index, 1)
				}
			} catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async getRegions() {
			this.loading = true
			try {
				this.regions = await this.$httpGet('/content/entries?contentType=region')
			} catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async getClients() {
			this.loading = true
			try {
				const res = await this.$httpGet('/clients')
				this.clients = res.clients
			} catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async getClientData() {
			this.selectedTags = []
			this.selectedSalesChannels = []

			try {
				const clientId = this.regionMapping.fields.client?.de?.sys?.id ? this.regionMapping.fields.client.de.sys.id : this.selectedClient.sys.id
				//Client Tags
				this.tags = await this.$httpGet(`/client/tags/${clientId}`)

				//Client Sales Channels
				const client = await this.$httpGet(`/client/${clientId}`)
				this.salesChannels = client.fields?.salesChannels?.de ? client.fields.salesChannels.de : []
				
			} catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async upsertRegionMapping() {
			try {
				this.regionMapping.fields.region.de = this.selectedRegion.sys.id
				this.regionMapping.fields.client.de = this.selectedClient.sys.id

				if (this.regionMapping.fields.salesChannels.de.length) {
					for (let i=0; i < this.regionMapping.fields.salesChannels.de.length; i++) {
						this.regionMapping.fields.salesChannels.de[i] = this.regionMapping.fields.salesChannels.de[i].sys.id
					}
				}

				const isValid = this.validate()
				if (!isValid) return this.showError('Please enter the required information')

				this.loading = true

				this.regionMapping = await this.$httpPost('/region-mapping', this.regionMapping)
				await this.$store.commit('setSelectedRegionMapping', this.regionMapping)

				if (this.action === Action.CREATE) {
					this.successTitle = 'CREATE REGION MAPPING'
					this.successDetail = 'Region Mapping created successfully'
				} else {
					this.successTitle = 'UPDATE REGION MAPPING'
					this.successDetail = 'Region Mapping updated successfully'
				}

				this.action = Action.VIEW
				this.loading = false

			} catch (error) {
				this.showError(error)
			}
		},
		onDeleteConfirmed() {
			this.$refs.deleteDialog.show = false
			this.deleteRegionMapping()
			return true
		},
		onActionCancelled() {
			this.$refs.deleteDialog.show = false
			this.$refs.duplicateDialog.show = false
		},
		async deleteRegionMapping() {			
			this.loading = true
				await this.$httpDelete(`/region-mapping/${this.regionMapping.sys.id}`)

				await this.$store.commit('setSelectedRegionMapping', null)
			
				this.successTitle = 'REGION MAPPING DELETED'
				this.successDetail = 'Region Mapping deleted sucessfully'

				setTimeout(() => { this.goback() }, 2000)

				this.loading = false
		},
		async copyRegionMapping() {
			this.$refs.duplicateDialog.show = false

			try {
				this.loading = true
				this.duplicateRegionMapping = JSON.parse(JSON.stringify(this.regionMapping))
				this.duplicateRegionMapping.sys.id = ''
				this.duplicateRegionMapping.fields.salesChannels.de = []
				this.duplicateRegionMapping.fields.tags.de = []
				this.duplicateRegionMapping.fields.client.de = this.selectedDuplicateClient.sys.id
				this.duplicateRegionMapping.fields.region.de = this.selectedRegion.sys.id

				this.duplicateRegionMapping = await this.$httpPost('/region-mapping', this.duplicateRegionMapping)

				this.successTitle = 'DUPLICATE REGION MAPPING'
				this.successDetail = 'Region Mapping duplicated successfully'

				this.loading = false
				this.$refs.switchDialog.show = true
			} catch (error) {
				this.showError(error)
			}
		},
		async onConfirmViewDuplicate() {
			this.$refs.switchDialog.show = false

			this.regionMapping = JSON.parse(JSON.stringify(this.duplicateRegionMapping))
			await this.$store.commit('setSelectedRegionMapping', this.regionMapping)

			this.duplicateRegionMapping = {}
			this.selectedClient = this.regionMapping.fields.client?.de
			this.selectedRegion = this.regionMapping.fields.region?.de

			//Remove region mapping client for duplicate function
			this.filteredClients = JSON.parse(JSON.stringify(this.clients))
			const index = this.clients.findIndex(x => x.sys.id === this.regionMapping.fields.client?.de?.sys?.id)

			if (index > -1) {
				this.filteredClients.splice(index, 1)
			}
		},
 		validate() {
			this.runValidation = true
			
			if (this.titleError.length || 
				this.regionError.length ||
				this.clientError.length || 
				this.zipCountryCodeError.length) {
				return false
			} else {
				return true
			}
		},
		addZipRange() {
			this.regionMapping.fields.zipCodes.de.push({
				zipMin:'',zipMax:''
			})
		},
		getColor(tag) {
			return this.tagColors[this.hash(tag) % this.tagColors.length]
		},
		hash(str, seed = 0) {
			let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
			for (let i = 0, ch; i < str.length; i++) {
				ch = str.charCodeAt(i);
				h1 = Math.imul(h1 ^ ch, 2654435761);
				h2 = Math.imul(h2 ^ ch, 1597334677);
			}
			h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
			h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
			return 4294967296 * (2097151 & h2) + (h1>>>0);
		},
		closeDropDown(ref) {
			if (!this.dropdownOpen) {
				this.$refs[ref].focus()
				this.dropdownOpen = true
			} else {
				this.$refs[ref].blur()
				this.dropdownOpen = false
			}
		},
		remove(item) {
			this.regionMapping.fields.salesChannels.de.splice(this.regionMapping.fields.salesChannels.de.indexOf(item), 1)
			this.regionMapping.fields.salesChannels.de = [...this.regionMapping.fields.salesChannels.de]
		},
		async goback() {
			await this.$store.commit('setSelectedRegionMapping', null)
			this.$router.push("/regionmappings")
		}
	}
}
</script>

<style scoped>
.chip {
  min-height: 32px !important;
  max-height: 32px !important;
  height: 32px !important;
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border: thin #dddddd solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: "Inter", sans-serif;
  font-size: 12pt !important;
  font-weight: normal;
  text-transform: none !important;
  letter-spacing: 0px !important;
  cursor: pointer !important;
}
</style>