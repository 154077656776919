<template>
	<div class='Field TagField'>
		<label class="fieldTitle">{{ title ? title : def.name }}</label>
		<v-text-field outlined :disabled="disabled"
			hide-details
      class="mb-1"
			v-model="current"
			@keypress="keypress"
		>
		</v-text-field>
		<div v-if="validationError" class="fieldError">
			{{ validationError }}
		</div>
		<div v-if="current" class="fieldWarning">
			You have entered content that will not be saved. Press enter to add the tag!
		</div>
		<div>
			<v-chip v-for="(val, v) of value" :key="val"
				style="margin-right: 5px"
				close @click:close="deleteAt(v, val)"
			>{{ val }}</v-chip>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TagsField',
	props: {
		value: Array,
		def: Object,
		title: String,
		disabled: Boolean
	},
	data() {
		return {
		current: '',
	}},
	computed: {
		validationError() {
			let allowedValues = this.def.items.validations?.[0]?.in
			if (this.current !== ''
				&& allowedValues
				&& allowedValues.indexOf(this.current) < 0)
				return 'Value must be one of: ' + allowedValues.join(', ')

			return null
		},
	},
	mounted() {
		// ensure that we have an array on the model when starting
		if (!this.value)
			this.$emit('input', [])
	},
	methods: {
		keypress(event) {
			if (event.code == 'Enter') {
				if (this.validationError) return
				this.value.splice(1000, 0, this.current)
				this.current = ''
			}
		},
		deleteAt(v, val) {
			this.current = val
			this.value.splice(v, 1)
		},
	},

}
</script>

<style scoped>
.Field { padding-left: 10px; border-left: 3px solid silver; }
.fieldTitle { color: gray; font-size: smaller; }
.fieldWarning { color: darkorange; font-size: smaller; position: relative; top: -15px; }
.fieldError { color: red; font-size: smaller; position: relative; top: -15px; }
</style>