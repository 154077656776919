<template>
	<v-container class="main" fluid fill-width>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="toolbar">
			<v-row align="center">
				<!-- Search -->
				<v-text-field outlined dense clearable hide-details height="48"
					prepend-inner-icon="mdi-magnify"
					placeholder='Search'
					style="padding-right:10px"
					v-model="searchString"
					@keyup.enter="search()"
					@click:clear="clearSearch()">
				</v-text-field>

				<!-- Filters -->
				<div style="padding-left:10px;padding-right:20px">
					<v-menu offset-y bottom left origin="top right" class="filter" :close-on-content-click="false" v-model="isOpened">
						<template v-slot:activator="{ on }" class="filter">
							<v-row justify="end" v-on="on">
								<v-badge color="error" style="z-index:999" overlap v-if="$store.state.filter"></v-badge>
								<v-btn class="btn" elevation="0" x-small>
									<v-icon>mdi-filter</v-icon>
									<span class="d-none d-md-inline">Filter</span>
									<v-icon style="cursor:pointer;">mdi-menu-down</v-icon>
								</v-btn>
							</v-row>
						</template>

						<v-list class="filter">
							<v-list-item class="filter pa-5">
								<v-list-item-title>
									<div @click="closeFilter()">
										<span style="width:100%">Category</span>
										<v-select filled solo class="btn"
											ref="filterCategories"
											v-model="selectedCategories"
											:items="categories"
											:item-text="item => item.fields.title.en"
											:item-value="item => item.sys.id"
											multiple small-chips hide-details
											:menu-props="{ offsetY: true  }">
											<template #selection="{ item }">
												<v-chip>{{item.fields.title.en}}</v-chip>
											</template>
										</v-select>
									</div>
								</v-list-item-title>
							</v-list-item>

							<v-divider/>

							<v-row class="pl-6 pr-6 pb-4 pt-6">
								<v-btn class="btn" elevation="0" style="width:48%;margin-right:4%" @click="clearFilter()">Clear Filter</v-btn>
								<v-btn class="btn green" elevation="0" dark style="width:48%;" @click="applyFilter()">Apply Filter</v-btn>
							</v-row>
						</v-list>
					</v-menu>
				</div>

				<v-btn-toggle style="padding-left:10px;padding-right:20px" v-model="view">
					<v-btn id="btn-list" class='btn-list active-btn' elevation="0"><v-icon>mdi-format-list-bulleted</v-icon></v-btn>
					<v-btn id="btn-grid" class="btn-grid" elevation="0"><v-icon>mdi-view-grid</v-icon></v-btn>
				</v-btn-toggle>
				
				<!-- Add New -->
				<v-btn class="btn" small elevation="0" @click="showNewVideo()">
					<v-icon>mdi-plus-circle</v-icon>
					<span class="d-none d-md-inline">Add Training Video</span>
				</v-btn>
			</v-row>
		</div>

		<!-- List View -->
		<v-card v-if="view===0" class="tableCard" style="margin-top:10px">
			<v-data-table fixed-header hide-default-footer disable-sort disable-pagination
				v-model="selected"
				:headers="headers"
				:items="trainingMaterials"
				item-key="name"
				class="elevation-0"
				style="border-radius: 0"
        @click:row="showTrainingMaterialDetail"
			>
        <template v-slot:item.img="{ item }">
          <v-img width="32px" height="32px" style="border-radius:5px" :src="getPattern(item.fields.pattern.de)"/>
        </template>
        <template v-slot:item.title="{ item }">
          {{ item.fields.title.en }}
        </template>
        <template v-slot:item.category="{ item }">
          {{ item.fields.category.de.fields.title.en }}
        </template>
         <template v-slot:item.created_at="{ item }">
          {{ item.sys.createdAt | formatShortDate }}
        </template>
				<template v-slot:item.updated_at="{ item }">
          {{ item.sys.updatedAt | formatShortDate }}
        </template>
			</v-data-table>
			<v-card class="tableFooter">
				<v-card-actions>
					<TablePaginator v-model="offset" :limit="limit" :total="itemCount" @input="getTrainingMaterials()" :results="trainingMaterials" />
				</v-card-actions>
			</v-card>
		</v-card>

		<!-- Grid View-->
		<div v-if="view===1" style="margin-top:30px" class="pa-3">
			<v-row align="center" justify="center">
				<v-col>
					<Grid 
						:items="trainingMaterials"
						:cell-size="300">
						<template slot="cell" slot-scope="props">
							<div class="preview-component">
								<div class="v-grid-item-wrapper">
									<TrainingMaterial 
										:trainingMaterial="props.item" 
										:playEnabled="true"
										@view-video="viewVideo"
									/>
								</div>
							</div>
						</template>
					</Grid>
				</v-col>
			</v-row>
    </div>

		<!-- Video Player -->
		<div v-if="showVideoPlayer" class="dialog">
			<v-card height="100%">
				<v-container>
					<v-row align="center" justify="space-between" class="pa-3">
						<h1>{{trainingMaterial.fields.title.en}}</h1>
						<v-btn class="btn" elevation="0" @click="showVideoPlayer=false">
							<v-icon>mdi-close-circle</v-icon> Close
						</v-btn>
					</v-row>
					<br />
					<video controls
						:src="selectedVideo"
						id="videoPlayer"
						width="100%"
            height="auto"
						:key="selectedVideo"></video>
				</v-container>
			</v-card>
		</div>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import TrainingMaterial from './TrainingMaterial.vue'
import Grid from '@/views/dashboard/grid/Grid.vue'
import Common from '@/mixins/Common.vue'
import { Action } from '@/plugins/enum.js'

export default {
	name: "TrainingLibraryView",
	components: { Loading, Alert, TablePaginator, Grid, TrainingMaterial },
	mixins: [ Common ],

	data() {
		return {
			Action: Action, 
			
			searchString: '',

			isOpened: false,
			offset: this.$store.state.offset,
			limit: 15,
			itemCount: 0,
			selected: [],
			view: 0,

			categories: [],
			selectedCategories: [],

			trainingMaterials: [],
			trainingMaterial: {},
			
			thumbnailColors: [],
			thumbnailPatterns: [],

			selectedVideo: null,
			selectedPdf: null,
			mediaType: null,
			
			showVideoPlayer: false,
			mediaTitle: '',
			trainingMaterial: {},
		}
	},

	computed: {
		headers() {
			return [
				{ sortable: false, value: "img", width: '40px' },
				{ text: 'Title', value: "title", sortable: true },
				{ text: 'Category', value: "category", sortable: true },
				{ text: 'Created', value: "created_at", width: '175' },
				{ text: 'Updated', value: "updated_at", width: '175' }
			]
		},
	},

	watch: {
		showVideoPlayer() {
			if (!this.showVideoPlayer && this.mediaType == 'video') {
				var videoPlayer = document.getElementById('videoPlayer')
				videoPlayer.pause()
			}
		},
	},

	mounted() {
		this.getCategories()
		this.getTrainingMaterials()	
	},

	methods: {
		async getCategories() {
			try {
				this.selectedCategory = this.categoryAll

				this.categories = await this.$httpGet(`/training-categories`)
		
				if (this.categories) {
					this.categories.unshift(this.categoryAll)
				}
			}
			catch (error) {
				this.showError(error)
			}
		},
		async getTrainingMaterials() {
			this.loading = true
			try {
				await this.$store.commit('setOffset', this.offset)
				const url = `/training-materials?skip=${this.offset}&limit=${this.limit}&searchString=${encodeURIComponent(this.searchString)}&filter=${JSON.stringify(this.$store.state.filter)}`
				
				const res = await this.$httpGet(url)

				this.trainingMaterials = res.trainingMaterials
				this.itemCount = res.total

			} catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async showTrainingMaterialDetail(item) {
			try {
				await this.$store.commit('setSelectedTrainingMaterial', item)
				this.$router.push(`/trainingmaterial-detail?action=${Action.VIEW}`)
			} catch (error) {
				this.showError(error)
			}
		},
		async showNewVideo() {
			try {
				await this.$store.commit('setSelectedTrainingMaterial', null)
				this.$router.push(`/trainingmaterial-detail?action=${Action.CREATE}`)
			} catch (error) {
				this.showError(error)
			}
		},
		viewVideo(trainingMaterial) {
			this.trainingMaterial = trainingMaterial
			
			if (!this.trainingMaterial.fields.media.de?.fields?.file?.en.url) return

			if (this.trainingMaterial.fields.media.de) {
				this.showVideoPlayer = true
				this.selectedVideo = this.getAssetUrl(this.trainingMaterial.fields.media.de, 'en')
			}
		},
		async search() {
			await this.$store.commit('setSearchString', this.searchString)
				
			if (this.searchString?.length > 0) {
				this.offset = 0
				this.getTrainingMaterials()
			} else {
				this.clearSearch()
			}
		},
		async clearSearch() {
			this.offset = 0
			this.searchString = ''
			await this.$store.commit('setSearchString', '')
			
			this.getTrainingMaterials()
		},
		async applyFilter() {
			this.isOpened = false
			this.offset = 0
			const filter = {
				categories: this.selectedCategories
			}
			await this.$store.commit('setFilter', filter)
			await this.getTrainingMaterials()
		},
		async clearFilter() {
			this.isOpened = false
			this.selectedCategories = []
			this.offset = 0
			await this.$store.commit('setFilter', null)
			await this.getTrainingMaterials()
		},
		closeFilter() {
			if (!this.dropdownOpen) {
				this.$refs.filterCategories.focus()
				this.dropdownOpen = true
			} else {
				this.$refs.filterCategories.blur()
				this.dropdownOpen = false
			}
		}
	},

}
</script>

<style scoped>
a { text-decoration: none; }
li { white-space: normal;float: left;width: 100%;height: auto;word-wrap: break-word;padding-left: 20px;cursor: pointer; }
.btn-list { border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important; }
.btn-grid { border-top-left-radius: 0px !important; border-bottom-left-radius: 0px !important; }
.preview-component {  top: 0; left: 0; bottom: 0; right: 0; }
.v-grid-item-wrapper { display: block;position: absolute;box-sizing: border-box;user-select: none;transform: translate3d(0px, 0px, 0px);z-index: 1;padding: 0;margin-left:5px;top: 0;width:260px;height: 250px; }
.dialog { position: fixed; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 1200px; background-color: #ffffff; border-radius: 10px; box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2); z-index: 999; }
</style>