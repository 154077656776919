<template>
	<div class='Field GeofenceField'>
		<label class="fieldTitle">{{ title ? title : def.name }}</label>
		<div class="mapWrap">
			<div ref="map" class="map" style="height: 500px;" />
		</div>
		<div style="background: white; border-radius: 5px; padding: 5px; display: flex; position: absolute; margin-top: -55px; margin-left: 110px;">
			<input type="text" v-model="searchModel" @keypress.enter="geocode(searchModel, false)" placeholder="search map" style="border: 1px solid silver; margin-right: 5px; padding: 5px;" />
			<v-btn outlined @click="geocode(searchModel, true)"><v-icon color="red">mdi-dots-square</v-icon> rough fence</v-btn>
			<v-btn outlined @click="$emit('input', getEmptyModel())" v-if="value && value.geometry && value.geometry.coordinates && value.geometry.coordinates.length" style="margin-left: 5px">clear</v-btn>
		</div>
		<i class="help">Enter a search term (place, region, etc.) and hit enter to display it. click "rough fence" to draw a rectangle around the search result.</i>
	</div>
</template>

<script>
// TODO: i copied this from the LocationField, we should make this a central facility!
// TODO: key from env
const API_KEY = 'AIzaSyAxeE-DWlZZrrvf8lZRjEimC2iJfDKjviA'
let initialized = !!window.google
let resolveInitPromise
let rejectInitPromise

const initPromise = new Promise((resolve, reject) => {
	resolveInitPromise = resolve
	rejectInitPromise = reject
})

function gmapsInit() {
	if (initialized) return initPromise
	initialized = true
	window.initMap = () => resolveInitPromise(window.google)

	const script = document.createElement('script')
	script.async = true
	script.defer = true
	script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=initMap`
	script.onerror = rejectInitPromise
	document.querySelector('head').appendChild(script)
	return initPromise
}

// https://developers.google.com/maps/documentation/javascript/examples/delete-vertex-menu
function getDeleteMenu(google) {
	class DeleteMenu extends google.maps.OverlayView {
		constructor() {
			super();
			this.div_ = document.createElement('div')
			this.div_.className = 'delete-menu'
			this.div_.innerHTML = 'Delete'
			const menu = this
			google.maps.event.addDomListener(this.div_, 'click', () => {
				menu.removeVertex()
			})
		}
		onAdd() {
			const deleteMenu = this
			const map = this.getMap()
			this.getPanes().floatPane.appendChild(this.div_)
			// mousedown anywhere on the map except on the menu div will close the menu.
			this.divListener_ = google.maps.event.addDomListener(map.getDiv(), "mousedown", (e) => {
					if (e.target == deleteMenu.div_) return
					deleteMenu.close()
				}, true)
		}
		onRemove() {
			if (this.divListener_) {
				google.maps.event.removeListener(this.divListener_)
			}
			this.div_.parentNode.removeChild(this.div_)
			this.set('position', null)
			this.set('path', null)
			this.set('vertex', null)
		}
		close() {
			this.setMap(null)
		}
		draw() {
			const position = this.get('position')
			const projection = this.getProjection()

			if (!position || !projection) return
			const point = projection.fromLatLngToDivPixel(position)
			this.div_.style.top = point.y + 'px'
			this.div_.style.left = point.x + 'px'
		}
		open(map, path, vertex) {
			this.set('position', path.getAt(vertex))
			this.set('path', path)
			this.set('vertex', vertex)
			this.setMap(map)
			this.draw()
		}
		removeVertex() {
			const path = this.get('path')
			const vertex = this.get('vertex')
			if (!path || vertex == undefined) {
				this.close()
				return
			}
			path.removeAt(vertex)
			this.close()
		}
	}
	return new DeleteMenu()
}

export default {
	name: 'GeofenceField',
	props: {
		value: Object,
		def: Object,
		title: String,
		search: String,
	},
	data() {
		return {
		map: null,
		geocoder: null,
		searchModel: '',
		polygon: null,
		deleteMenu: null,
		internalUpdate: false,
	}},
	watch: {
		value(n) {
			this.updateModel(this.value)
		},
		searchValue(n) {
			this.searchModel = n
			this.doSearch()
		},
	},
	async mounted() {
		try {
			this.google = await gmapsInit()
			const google = this.google
			this.geocoder = new google.maps.Geocoder()
			this.map = new google.maps.Map(this.$refs.map, {
				scrollwheel: false,
				scaleControl: false,
			})
			this.map.setZoom(3)
			this.map.setCenter(new google.maps.LatLng(47, 11))
			this.deleteMenu = getDeleteMenu(this.google)

			this.updateModel(this.value)
		}
		catch (e) {
			console.error(e)
		}
	},
	methods: {
		async geocode(address, updateGeofence) {
			this.geocoder.geocode({ address: address }, (results, status) => {
				const google = this.google
				if (status !== 'OK' || !results[0]) {
					throw new Error(status)
				}
				this.map.fitBounds(results[0].geometry.viewport)
				
				if (!updateGeofence) return
				const ne = results[0].geometry.viewport.getNorthEast()
				const sw = results[0].geometry.viewport.getSouthWest()
				this.updatePolygon([
					ne, new google.maps.LatLng(ne.lat(), sw.lng()),
					sw, new google.maps.LatLng(sw.lat(), ne.lng()),
				])
				this.updateValue()
			})
		},
		updateModel(geofence) {
			if (!geofence) return
			const google = this.google
			const path = []
			for (const coord of geofence.geometry?.coordinates ?? []) {
				path.push(new google.maps.LatLng(coord[0], coord[1]))
			}
			// we dont swap the polygon on internal updates
			if (!this.internalUpdate) {
				this.updatePolygon(path)
			}
			this.internalUpdate = false
		},
		updatePolygon(path) {
			const google = this.google
			if (this.polygon) {
				this.polygon.setMap(null)
			}
			// TODO: instead this.polygon.clear + add? otherwise listeners could leak
			this.polygon = new google.maps.Polygon({
				path: path,
				editable: true,
				fillColor: '#FF0000',
				strokeColor: '#FF0000',
				strokeOpacity: 1.0,
				strokeWeight: 2,
				map: this.map,
			})
			google.maps.event.addListener(this.polygon, 'contextmenu', (e) => {
				if (!e.vertex) return
				if (!this.polygon) return
				this.deleteMenu.open(this.map, this.polygon.getPath(), e.vertex)
			})
			google.maps.event.addListener(this.polygon.getPath(), 'set_at', this.updateValue)
			google.maps.event.addListener(this.polygon.getPath(), 'insert_at', this.updateValue)
			google.maps.event.addListener(this.polygon.getPath(), 'remove_at', this.updateValue)

			// zoom to polygon
			if (path.length) {
				const bounds = this.getPathBounds(this.polygon.getPath())
				this.map.fitBounds(bounds)
			}
		},
		updateValue() {
			const data = this.getEmptyModel()
			const postgresPolygonArray = []
			this.polygon.getPath().forEach(p => {
				data.geometry.coordinates.push([ p.lat(), p.lng() ])
				postgresPolygonArray.push(`(${p.lat()},${p.lng()})`)
			})
			const postgresPolygon = '(' + postgresPolygonArray.join(',') + ')'
			const bounds = this.getPathBounds(this.polygon.getPath())
			const ne = bounds.getNorthEast()
			const sw = bounds.getSouthWest()
			data.bbox = [ne.lat(), ne.lng(), sw.lat(), sw.lng()]
			data.postgres.box = `((${ne.lat()},${ne.lng()}),(${sw.lat()},${sw.lng()}))`
			data.postgres.polygon = postgresPolygon

			this.internalUpdate = true
			this.$emit('input', data)
		},
		getPathBounds(path) {
			const google = this.google
			const bounds = new google.maps.LatLngBounds()
			for (var ii = 0; ii < path.getLength(); ii++) {
				bounds.extend(path.getAt(ii))
			}
			return bounds
		},
		getEmptyModel() {
			// this is a geojson feature https://geojson.org/geojson-spec.html
			return {
				type: "Feature",
				properties: {},
				geometry: {
					type: 'Polygon',
					coordinates: [],
				},
				bbox: null,
				postgres: {
					box: null,
					polygon: null,
				},
			}
		},
	},
}
</script>

<style scoped>
.Field { padding-left: 10px; border-left: 3px solid silver; }
.fieldTitle { color: gray; font-size: smaller; }
</style>

<style>
.delete-menu {
	position: absolute;
	background: white;
	padding: 5px;
	color: #666;
	font-weight: bold;
	border: 1px solid #999;
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.3);
	margin-top: -10px;
	margin-left: 10px;
	cursor: pointer;
	border-radius: 3px;
}
.delete-menu:hover { background: #eee; }
</style>