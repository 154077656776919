<template>
    <v-card flat :class="{ cardActive: media.checked, card: !media.checked }">
      <video id="video" width="100%" height="100%" controls @play="playVideo()"></video>
      <p :class="media.checked ? 'labelActive' : 'label'" v-if="showTitle">{{formattedTitle()}}</p>
     
      <!-- Overlay if component is disabled -->
      <div class="leftCorner" v-if="canSelect">
        <v-checkbox dark
        v-model="media.checked"
        color="info" 
        off-icon="mdi-checkbox-blank"
        on-icon="mdi-checkbox-marked"
        style="margin-top:-7px;margin-left:-3px;" 
        @change="checkboxChange()"
        />
      </div>

      <div class="rightCorner">
        <v-btn v-if="canEdit" @click="showUpdate()" class="icon" elevation="0"><v-icon size="24px">mdi-circle-edit-outline</v-icon></v-btn>
      </div>
    </v-card>
</template>

<script>
export default {
  name: "VideoCard",
  emits: ['show-media-update', 'checkbox-change',],
  props: {
		media: { type: Object },
    localeCode: String,
    canEdit: Boolean,
    canSelect: Boolean,
    showTitle: Boolean
	},

  async mounted() {
    this.selectedLocale = this.$store.state.selectedLocale;
  },

  methods: {
    showUpdate() {
      this.$emit("show-media-update")
    },
    checkboxChange() {
      this.$emit("checkbox-change", this.media)
    },
    mediaSource() {
      let video = document.getElementById('video')
      let source = document.createElement('source')

      if (this.media.fields?.media?.de?.fields?.file?.[localeCode]?.url) {
         source.setAttribute('src', this.media.fields.media.de.fields.file[localeCode].url)
      } else if (this.media.fields.file?.[localeCode]?.url) {
        source.setAttribute('src', this.media.fields.file[localeCode].url)
        return this.media.fields.file[localeCode].url
      }
      video.appendChild(source)
    },
    formattedTitle() {
      if (this.media.fields?.title?.de?.length) {
        if (this.media.fields?.title?.de?.length >= 40) {
          return this.media.fields.title.de.substring(0,40)
        } else {
          return this.media.fields.title.de
        }
      }
      return ''
    },
    playVideo() {
      this.$emit('play-video')
    }
  }
}
</script>

<style scoped>
.icon {
  width: 24px !important;
  height: 24px !important;
  max-width: 24px !important;
  max-height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  border-radius: 12px !important;
  padding:  0px !important;
}
.card {
  height: 200px;
  width: 200px;
  background-color: #ffffff;
  border: solid 1px #dbdbdb !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.cardActive {
  height: 200px;
  width: 200px;
  background-color: #00aeef !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.label {
  font-size:10pt;
  color:#000000;
  padding:10px;
}
.labelActive {
  font-size:10pt;
  color:#ffffff;
  padding:10px;
}
.leftCorner {
  display: inline-block;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  width: 35px;
  height: 35px;
  padding: 8px;
  border-radius: 0 !important;
  border-bottom-right-radius: 10px !important;
  background-color: rgba(128,128,128,0.5);
}
.rightCorner {
  display: inline-block;
  position: absolute;
  z-index: 3;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;

}
.editButton {
  margin-top:-7px;
  margin-right:0px;
  cursor: pointer !important;
  max-width: 35px !important;
  max-height: 35px !important;
  background-color: transparent !important;
}
</style>
