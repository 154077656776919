<template>
	<div class='Field TextField'>
		<label class="fieldTitle">{{ title ? title : def.name }}</label>
		<v-select outlined 
			:disabled="disabled"
			v-if="def.validations && def.validations[0] && def.validations[0].in"
			v-model="model"
			:items="def.validations[0].in"
		/>
		<v-text-field outlined 
			:disabled="disabled"
			v-if="!(def.validations && def.validations[0] && def.validations[0].in)"
			v-model="model">
		</v-text-field>
		<div class="fieldInfo">
			{{ value.length }} characters
		</div>
		<div v-if="validationError" class="fieldError">
			{{ validationError }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'TextField',
	
	props: {
		value: [Number, String],
		def: Object,
		title: String,
		disabled: Boolean
	},

	data() {
		return {
		model: null
	}},
	computed: {
		validationError() {
			if (this.def.required && this.value === '')
				return 'This field is required!'
	
			let allowedValues = this.def.validations?.[0]?.in
			if (this.value !== ''
				&& allowedValues
				&& allowedValues.indexOf(this.value) < 0)
				return 'Value must be one of: ' + allowedValues.join(', ')

			return null
		},
	},
	watch: {
		value(n) {
			this.model = n
		},
		model(n) {
			this.$emit('input', n)
		}
	},
	mounted() {
		this.model = this.value
	},

}
</script>

<style scoped>
.v-text-field__details { margin-bottom: 0 !important; }
.Field { padding-left: 10px; border-left: 3px solid silver; }
.fieldTitle { color: gray; font-size: smaller; }
.fieldInfo { color: gray; font-size: smaller; position: absolute; margin-top: -25px; }
.fieldError { color: red; font-size: smaller; position: relative; top: -5px; }
</style>