
<template>
  <v-container fluid class="pa-0">
   
    <Disclosure title="Change Log" :expanded="true">
      <v-data-table fixed-header hide-default-footer
        :headers="headers"
        :items="auditEntries"
        :mobile-breakpoint="700"
        item-key="name"
        class="elevation-0"
        style="border-radius: 0"
        >

        <template v-slot:item="{item}">
          <tr @click="showDetail(item)">
            <td>{{ item.summary }}</td>
            <td>{{ item.user.fields.name.de }}</td>
            <td>{{ item.createdAt | formatDateTime }}</td>
          </tr>
        </template>
        
      </v-data-table>
    </Disclosure>

    <!-- Audit Entry Details -->
		<Dialog ref="auditDetailDialog"
			:showClose="true"
      title="Change Detail"
			:height="'90%'"
			:width="'60%'">
			<template #content>
        <v-container class="pa-5">
          <div class="pa-5">
            <v-row class="detail"><span class="label">Summary</span>{{selectedItem.summary}}</v-row>
            <v-row class="detail"><span class="label">Updated by</span>{{selectedItem.user.fields.name.de}}</v-row>
            <v-row class="detail"><span class="label">Updated at</span>{{selectedItem.createdAt | formatDateTime}}</v-row>
          </div>

          <div class="audit-entry-container">
            <v-row style="padding:12px">
              <div class="audit-entry-header-box">
                <div class="audit-entry-header"><h3>Original</h3></div>
              </div>
              <div class="audit-entry-header-box">
                <div class="audit-entry-header"><h3>Changes</h3></div>
              </div>
            </v-row>

            <div v-for="key of Object.keys(selectedItem.detail.original)" :key="key" class="audit-entry-row">
                <!-- Original Data Column -->
                <div class="audit-entry">
                  <DiffData :contentType="contentType" :propKey="key" :entry="selectedItem.detail.original[key] ? selectedItem.detail.original[key] : null"/>
                </div>

                <!-- New Data Column -->
                <div class="audit-entry">
                  <DiffData :contentType="contentType" :propKey="key" :entry="selectedItem.detail.new[key] ? selectedItem.detail.new[key] : null"/>
                </div>
            </div> 
          </div> 

        </v-container>
			</template>
		</Dialog>
  </v-container>
</template>

<script>
import Disclosure from '@/components/common/Disclosure.vue'
import Dialog from '@/components/common/Dialog.vue'
import DiffData from '@/components/auditLog/DiffData.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: "AuditLog",
	mixins: [ Common ],
  components: { Disclosure, Dialog, DiffData },
	
	props: {
		entryId: { type: String },
    contentType: { type: String }
	},

	data() {
		return {
			auditEntries: [],
      selectedItem: {}
		}
	},
	
  watch: {
    entryId() {
      this.getAuditLog()
    }
  },

	computed: {
		headers () {
			return [
				{ text: 'Summary', value: "", sortable: false },
				{ text: 'Updated By', value: "", sortable: false, width: '25%' },
				{ text: 'Updated At', value: "", sortable: false,  width: '15%' }
			]
		}
	},

  mounted() {
    this.getAuditLog()
  },
  
	methods: {
		async getAuditLog() {
      if (this.entryId?.length > 0) {
        try {
          this.auditEntries = await this.$httpGet(`/audit-log/${this.entryId}`)
        }
        catch (error) {
          this.showError(error)
        }
      }
		},
    async showDetail(item) {
      this.selectedItem = item
      this.$refs.auditDetailDialog.show = true
    }
	}
}
</script>

<style scoped>
h3 { font-weight: normal; color: rgb(17, 27, 43); }
.label { width: 15%; font-size: 13pt; font-weight: bold; color: rgb(17, 27, 43); }
.detail { font-size: 13pt; color: rgb(17, 27, 43); }
.audit-entry-container { -moz-box-flex: 1; flex-grow: 1; max-width: 100%; margin-top:20px; }
.audit-entry-header-box { max-width: 50%; height: 48px; display: flex; flex: 1 0 0%; position: relative; background-color: rgb(247, 249, 250); border: 1px solid rgb(219, 219, 219); }
.audit-entry-header { max-width: 50%; position: absolute; inset: 0px; cursor: pointer; padding-left: 20px; padding-top: 13px; }
.audit-entry-row { display: flex; padding: 0px; }
.audit-entry { max-width: 50%; width: 50%; padding: 20px; flex: 1; border: 1px solid rgb(219, 219, 219); }
.audit-entry-name { line-height: 1.5; color: rgb(90, 101, 124); }
</style>
