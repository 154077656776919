<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>
		
		<!-- toolbar -->
		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>
					&nbsp;<h2>System Settings</h2>
				</v-row>
			</div>
		</div>

		<SideBar>
			<div class="sidebar-block">
				<p class="sidebar-title">Actions</p>
				<div class="buttonBlock">
					<v-btn block class="btn blue mt-3" elevation="0" dark @click="updateSettings()">Save Changes</v-btn>
				</div>
			</div>
		</SideBar>

		<!-- System Settings -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="" :expanded="true" :error="sectionMessage.generalInfo.error" :message="sectionMessage.generalInfo.message">
				<div class="field left-border" v-if="maintenanceMode">
					<v-label>Maintenance Mode</v-label>
					<v-switch
						v-model="maintenanceMode.value.enabled"
						:label="maintenanceMode.value.enabled === false ? 'Maintenance mode off' : 'Maintenance mode on'" 
					/>
				</div>

				<div class="field left-border">
					<v-label>System Notification</v-label>
					<p>This notification is displayed in the message bar, on the dashboard after a user logs in.</p>
					
					<div v-for="locale of locales" :key="locale.code" style="display: flex">
						<LanguageFlag v-model="locale.code" class="flag"/>
						<v-text-field outlined required hide-details class="pl-3"
							v-model="systemNotification.value[locale.code]"
						/>
					</div>
	
				</div>
			</Disclosure>
		</div>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import SideBar from "@/components/common/SideBar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import Dialog from '@/components/common/Dialog.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Status from '@/components/common/Status.vue'
import AuditLog from '@/components/auditLog/AuditLog.vue'
import Common from '@/mixins/Common.vue'
import { Action, EntryStatus, SystemSettings } from '@/plugins/enum.js'

export default {
	name: "SystemDetailView",
	mixins: [ Common ],
	components: { Loading, SideBar, Alert, Disclosure, Dialog, LanguageFlag, Status, AuditLog },
	
	props: {
		initialAction: { type: String }
	},

	data() {
		return {
			Action: Action,
			EntryStatus: EntryStatus,
			action: this.initialAction,

			maintenanceMode: null,
			systemNotification: this.resetSystemNotification(),

			auditEntries: [],

			sectionMessage: {
				generalInfo: {error:false,warning:false,message:''},
			},
			
		}
	},
	
	async mounted() {
		this.getSettings()
	},

	methods: {
		async getSettings() {
			try {
				const settings = await this.$httpGet('/settings')
				
				/* Supported settings:
					maintenance_mode
					system_notification
				*/

				this.maintenanceMode = settings.find(setting => setting.key === SystemSettings.MAINTENANCE_MODE)
				this.systemNotification = settings.find(setting => setting.key === SystemSettings.SYSTEM_NOTIFICATION)

				if (!this.systemNotification?.value) {
					this.systemNotification = this.resetSystemNotification()
				}
			}
			catch (error) {
				if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error)
			}
		},
		toggleClientSelect() {
			for (let client of this.clients) {
				client.selected = this.selectAll
			}
		},
		async updateSettings() {
			this.loading = true
			
			try {
				//Check if system notification message is empty
				let systemMessage = JSON.parse(JSON.stringify(this.systemNotification))

				if (!this.systemNotification.value.de.length && 
						!this.systemNotification.value.en.length && 
						!this.systemNotification.value.fr.length && 
						!this.systemNotification.value.it.length) {

						systemMessage.value = null
				}

				const data = [
					this.maintenanceMode,
					systemMessage
				]
				

				this.application = await this.$httpPut(`/settings`, data)
				
				this.successTitle = 'UPDATE SETTINGS'
				this.successDetail = 'Settings updated successfully'
			
			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		resetSystemNotification() {
			return {
				key: SystemSettings.SYSTEM_NOTIFICATION,
				value: {de:'',en:'',fr:'',it:''}
			}
		},
		goback() {
			this.$router.push('/dashboard')
		}
	}
}
</script>

<style scoped>
.flag { z-index: 1; margin-top: 28px; margin-left: -26px; zoom: 0.8; }
.preview-container {
	width: 75%;
	padding-top: 75%;
	position: relative;
	margin: auto;
}
.preview-component {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	border: 1px solid grey;
	border-radius: 10px;
}
li {
	font-size: 16px;
}
</style>