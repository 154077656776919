<template>
	<div class='Field TextField ass'>
		<label class="fieldTitle">{{ title ? title : def.name }}</label>
		<div v-if="(model && model.sys)">
			<div>
				<img :src="model.fields.file.de.url" style="max-width: 150px; max-height: 150px;" />
			</div>
			<v-btn @click="model = null" v-if ="!disabled">remove image</v-btn>
		</div>
		<template v-if="!(model && model.sys) && !disabled">
			<FileUpload :options="dropOptions" ref="dropzone" data-cy="dropzone" @thumbnail-created="onThumbnailCreated" @file-removed="onFileRemoved" />
		</template>
	</div>
</template>

<script>
import FileUpload from '@/components/common/FileUpload'

export default {
	name: 'AssetField',
	components: { FileUpload },
	props: {
		value: Object,
		title: String,
		def: Object,
		disabled: Boolean
	},
	data() {
		return {
		model: null,
		files: [],
		dropOptions: {
			acceptedFiles: '.jpg, .jpeg',
			maxFiles: 1,
			maxFilesize: 10,
			resizeWidth: 2000,
			resizeMimeType: 'image/jpeg',
			resizeQuality: 0.8,
		}
	}},
	watch: {
		value(n) {
			this.model = n
		},
		model(n) {
			this.$emit('input', n)
		},
	},
	mounted() {
		this.model = this.value
	},
	methods: {
		async onThumbnailCreated(file) {
			this.files.push(file)
			let formData = new FormData()
			formData.append('file', file)
			// FIXME: there is no uploadAndProcess endpoint (see upload-controller.ts)
			let res = await this.axios.post('/uploadAndProcess', formData,
				{ headers: { Authorization: `Bearer ${ this.$store.state.loggedInUser.kc_token }`} }
			)
			let asset = res
			this.model = {
				sys: {
					id: asset.sys.id,
					type: 'Link',
					linkType: 'Asset',
				},
				fields: {
					file: {
						de: {
							url: asset.fields.file.de.url
						}
					},
				},
			}
			this.$refs.dropzone.removeFile(file)
		},
		onFileRemoved() {
			this.files = []
		},
	},
}
</script>

<style scoped>
.Field { padding-left: 10px; border-left: 3px solid silver; }
.fieldTitle { color: gray; font-size: smaller; }
</style>
