<template class="justify-center">
    <div fluid fill-width>
      <div class="field left-border pt-0 mt-0">
        <v-row class="pl-3 pt-3">
          <span style="width:200px">Client</span><v-label>{{ peakSyncData.client }}</v-label>
        </v-row>
        <v-row class="pl-3">
          <span style="width:200px">PEAK ID</span>
          <v-label v-if="parseInt(peakSyncData.entityid)">{{ peakSyncData.entityid }}</v-label>
          <v-label v-if="!parseInt(peakSyncData.entityid)">-</v-label>
        </v-row>
        <v-row class="pl-3" align="center">
          <span style="width:200px">Status</span><div :style="{'color': color()}">{{peakSyncData.status.toUpperCase()}}</div>
        </v-row>
         <v-row class="pl-3 pr-3" align="center" v-if="peakSyncData.status === 'error'">
          <div :style="{'color': color()}">{{peakSyncData.error}}</div>
        </v-row>
      </div>

      <div class="field left-border">
        PEAK Product Data
        <JsonEditor v-model="peakSyncData.data" :plus="true" :options="editorOptions" />
			</div>
    </div>
</template>

<script>
import JsonEditor from '@/components/common/JsonEditor.vue'
import Status from '@/components/common/Status.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: "PeakSyncData",
	mixins: [ Common ],
  components: { Status, JsonEditor },
	props: {
		peakSyncData: Object
	},

  data() {
		return {
      editorOptions: {
				mode: "code",
				modes: ["tree", "code"],
				onEditable: this.onEditable,
			},
    }
  },
  
  methods: {
    color() {
			if (this.peakSyncData.status === 'pending') return '#ff9e21'
      if (this.peakSyncData.status === "reapprove") return '#ffb400'
      if (this.peakSyncData.status === 'saved') return '#589ae6'
			if (this.peakSyncData.status === 'active_saved') return '#589ae6'
			if (this.peakSyncData.status === 'active') return '#66BB6A'
			if (this.peakSyncData.status === 'deactivated') return '#f34545'
			if (this.peakSyncData.status === 'declined') return '#f34545'
			if (this.peakSyncData.status === "approved") return '#64c823'
			if (this.peakSyncData.status === "expired") return '#737373'
			if (this.peakSyncData.status === 'done') return '#66BB6A'
			if (this.peakSyncData.status === 'error') return '#f34545'

			//Live Sync Statuses
			if (this.peakSyncData.status === 'scheduled') return '#589ae6'
			if (this.peakSyncData.status === "in_progress") return '#ffb400'

			return '#cccccc'
		}
  }
}
</script>
