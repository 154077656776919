<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success" class="alertSmall">{{ successDetail }}</Alert>
		<loading v-if="loading" :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>
		<div v-if="loadingOpenAIRequests" class="progress-bar" ref="progressBar">
			<v-progress-linear :value="progress" color="white" height="8"></v-progress-linear>
			<div class="progress-text">
				Translated {{ translatedSoFar }}/{{ filteredKeys.length }} ( {{ progress.toFixed(0) }}% ) of the variables so far...
				<br>
				Estimated time: {{ estimatedTimeRemaining === 0 ? 'Calculating...' : formatTime(estimatedTimeRemaining) + ' remaining' }}
			</div>
			<v-btn class="cancel-button" color="red" @click="cancelRequests = true" small outlined rounded :loading="cancelRequests">
				<v-icon small>mdi-cancel</v-icon>
				Cancel OpenAI requests
				<template v-slot:loader>
					<v-progress-linear indeterminate color="red"></v-progress-linear>
				</template>
			</v-btn>
		</div>

		<!-- toolbar -->
		<div v-show="!loadingOpenAIRequests" class="pa-1 pt-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center" style="gap: 10px;">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>
					<div style="position: relative; flex-grow: 1;">
						<v-text-field v-model="search" outlined hide-details
							prepend-inner-icon="mdi-magnify"
						/>
						<div class="filterCheckboxes">
							<v-btn @click="showOnlyExistingLeft = !showOnlyExistingLeft" class="toggle" :class="{ active: showOnlyExistingLeft }" outlined title="only exists on the left"><v-icon>mdi-alpha-l-box</v-icon></v-btn>
							<v-btn @click="showOnlyMissing = !showOnlyMissing" class="toggle" :class="{ active: showOnlyMissing }" outlined title="missing on the right"><v-icon>mdi-alpha-r-box-outline</v-icon></v-btn>
							<v-btn @click="showOnlyEditing = !showOnlyEditing" class="toggle" :class="{ active: showOnlyEditing }" outlined title="editor open"><v-icon>mdi-square-edit-outline</v-icon></v-btn>
							<v-btn @click="showOnlyChanged = !showOnlyChanged" class="toggle" :class="{ active: showOnlyChanged }" outlined title="right changed since last save/load"><v-icon>mdi-delta</v-icon></v-btn>
							<v-btn @click="showOnlyDifferent = !showOnlyDifferent" class="toggle" :class="{ active: showOnlyDifferent }" outlined title="right is different from left"><v-icon>mdi-not-equal</v-icon></v-btn>
							<v-btn @click="showOnlySame = !showOnlySame" class="toggle" :class="{ active: showOnlySame }" outlined title="right is same as left"><v-icon>mdi-equal</v-icon></v-btn>
							<v-btn @click="showOnlyMissing = !showOnlyMissing" class="toggle small" :class="{ active: showOnlyMissing }" outlined><v-icon>mdi-circle-off-outline</v-icon></v-btn>
							<v-btn @click="showOnlyEditing = !showOnlyEditing" class="toggle small" :class="{ active: showOnlyEditing }" outlined><v-icon>mdi-pencil-circle</v-icon></v-btn>
							<v-btn @click="showOnlyDifferent = !showOnlyDifferent" class="toggle small" :class="{ active: showOnlyDifferent }" outlined><v-icon>mdi-yin-yang</v-icon></v-btn>
							<v-btn @click="showOnlySame = !showOnlySame" class="toggle small" :class="{ active: showOnlySame }" outlined>test<v-icon>mdi-circle</v-icon></v-btn>
						</div>
					</div>

					<!-- Add New Translation -->
					<v-btn class="btn" small elevation="0" @click="showAddTranslation()">
						<v-icon>mdi-plus-circle</v-icon>
					</v-btn>

					<!-- Copy translations -->
					<v-tooltip top open-delay="500" color="white" max-width="300px" content-class="tooltip-arrow-top">
						<template  v-slot:activator="{ on, attrs }">
							<v-btn v-bind="attrs" v-on="on" class="btn" small elevation="0" @click="copyTranslations()">
								<v-icon>mdi-arrow-right</v-icon>
								<v-icon>mdi-sigma</v-icon>
							</v-btn>
						</template>
						<p>Copies all translations of the current view from the left locale to the right locale</p>
					</v-tooltip>

					<v-menu bottom right close-on-click style="z-index: 9999;">
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-bind="attrs" v-on="on" class="btn" small elevation="0">
								<img src="@/assets/icons/openai.svg" style="width:22px;height:22px;" />
								<v-icon>mdi-sigma</v-icon>
							</v-btn>
						</template>
						<template>
							<v-list>
								<v-list-item-subtitle class="header">
									Translate all empty strings on the right.<br />
									Choose Translation Context
								</v-list-item-subtitle>
								<v-list-item @click="translateMultipleWithChatGptInChunks(leftLocale, rightLocale)">
									<v-list-item-title>Without context</v-list-item-title>
								</v-list-item>
								<v-list-item v-for="context of allContexts" :key="context.key"
									@click="translateMultipleWithChatGptInChunks(leftLocale, rightLocale, context)"
								>
									<v-list-item-title>{{ context.comp }}</v-list-item-title>
								</v-list-item>
							</v-list>
						</template>
					</v-menu>
				</v-row>
			</div>
		</div>
		
		<SideBar :class="{ overlay: loadingOpenAIRequests }">
			<div style="display: flex; flex-direction: column; gap: 10px;">
				<v-btn block class="btn green" elevation="0" dark 
					@click="updateTranslations()">
					<v-icon>mdi-check</v-icon> Save Changes
				</v-btn>
				<v-btn v-if="environment !== 'production'"
					block class="btn default" elevation="0" 
					@click="showCompareTranslations()">
					<v-icon>mdi-select-compare</v-icon> Compare with Live
				</v-btn>
				<v-select outlined hide-details dense
					:disabled="changeCount > 0"
					v-model="selectedApplication"
					:items="applications"
					:item-text="application => application.fields.title.en"
					:item-value="application => application.sys.id" 
					@change="getTranslationsForApp()"
				/>
				<div v-if="changeCount > 0" class="warning">
					You have {{ changeCount }} unsaved changes in {{ changedLocaleCount }} locales. Please save before switching applications!
				</div>
				<div style="display: flex; gap: 10px;">
					<div style="width: 50%; text-align: left;">
						<v-select outlined dense required hide-details
							v-model="leftLocale"
							:items="textLocales"
							:item-text="locale => locale.code"
							:item-value="locale => locale"
						/>
					</div>
					<div style="width: 50%; text-align: right">
						<v-select outlined dense required hide-details
							v-model="rightLocale"
							:items="textLocales"
							:item-text="locale => locale.code"
							:item-value="locale => locale"
						/>
					</div>
				</div>
				<v-text-field outlined hide-details dense clearable
					v-if="rootMeta.children?.length"
					v-model="compSearch"
					label="Search Component"
					clear-icon="mdi-close-circle-outline"
				/>
				<v-treeview selectable return-object
					v-model="compSelection"
					selectionType="independent"
					:items="rootMeta.children"
					:search="compSearch"
				/>
			</div>
		</SideBar>

		<!-- Translations -->
		<div class="pa-1" style="width: 100%;">
			<v-card :class="{ overlay: loadingOpenAIRequests }">
				<div style="padding: 10px; display: flex; gap: 10px;">
					<div style="width: 30%">
						<div v-if="selectedCompKeys.length" title="selecting a component may not show all relevant translations!" style="cursor: pointer;">{{ selectedCompKeys.length }} Components ⚠️ <a href="#" @click.stop="compSelection = []">clear</a></div>
						<div>{{ filteredKeys.length }} Translations</div>
					</div>
					<div style="width: 35%;">
						<!-- TODO: actually we want to also show staging / live -->
						<v-select outlined dense required hide-details
							v-model="leftLocale"
							:items="textLocales"
							:item-text="locale => locale.code"
							:item-value="locale => locale"
						/>
						<div v-if="!text[leftLocale.code]"> 
							There are no translations for the selected application or language
						</div>
					</div>
					<div style="width: 35%; z-index: 9999;">
						<v-select outlined dense required hide-details
							v-model="rightLocale"
							:items="textLocales"
							:item-text="locale => locale.code"
							:item-value="locale => locale"
						/>
						<div v-if="!text[rightLocale?.code]"> 
							There are no translations for the selected application or language
						</div>
					</div>
				</div>
				<div v-for="key in filteredKeys" :key="key" class="line">
					<div class="key">{{ key }}</div>
					<div class="left">
						<span>{{ text[leftLocale?.code]?.[key] }}</span>
					</div>
					<div @click="edit($event, key)" class="right">
						<span v-if="!editing[key]">{{ text[rightLocale?.code]?.[key] }}</span>
						<div class="editing" v-if="editing[key]">
							<textarea v-model="text[rightLocale.code][key]" :ref="'e-' + key" />
							<div class="actions">
								<v-btn v-if="leftLocale.code && text[leftLocale.code][key] && rightLocale.code && text[rightLocale.code]"
									@click.stop="text[rightLocale.code][key] = text[leftLocale?.code]?.[key]"
								><v-icon>mdi-arrow-right</v-icon></v-btn>
								<div>
									<v-menu bottom right close-on-click>
										<template v-slot:activator="{ on, attrs }">
											<v-btn class="chatgptButton" color="white" v-bind="attrs" v-on="on">
												<img src="@/assets/icons/openai.svg" style="width: 16px; height: 16px;" />
											</v-btn>
										</template>
										<template>
											<v-list>
												<v-list-item-subtitle class="header">Choose Translation Context</v-list-item-subtitle>
												<v-list-item @click="translateSingleWithChatGpt(leftLocale, rightLocale, key)">
													<v-list-item-title>Without context</v-list-item-title>
												</v-list-item>
												<v-list-item v-for="context of findContextsForTranslation(key)" :key="context.key"
													@click="translateSingleWithChatGpt(leftLocale, rightLocale, key, context)"
												>
													<v-list-item-title>{{ context.comp }}</v-list-item-title>
												</v-list-item>
											</v-list>
										</template>
									</v-menu>
								</div>
								<v-btn @click.stop="editing[key] = false; $forceUpdate();" class="ok"><v-icon color="white">mdi-check</v-icon></v-btn>
							</div>
						</div>
					</div>
				</div>
			</v-card>
			<div class="help">
				Enter &lt;DELETE&gt; to delete a value (note that setting the value empty does not delete it, but only set it to an empty string).
			</div>
		</div>

		<!-- Add Translation -->
		<Dialog ref="addTranslationDialog"
			confirmLabel="Add Translation"
			cancelLabel="Cancel"
			:confirm-handler="validateTranslation"
			:cancel-handler="onActionCancelled"
			:showClose="false"
			title="Add Translation"
			height="700px"
			width="800px"
			style="z-index: 9999;"
		>
			<template #content>
				<div class="pl-5 pr-5" style="width: 100%; height: 1000px;">
					<div class="field left-border">
						<v-label>Key <span class="mandatory">(required)</span></v-label>
						<v-text-field outlined dense
							:hide-details="textLabelError.length == 0"
							:error-messages="textLabelError"
							v-model="newText.label" 
							placeholder="my_key"
							@keypress="validJsonKey($event)"
						/>
						<div v-if="newText.label.startsWith('context')">
							Looks like you want to define a context.
							Context keys have to look like "context_MyView_vue".
							Take care to use the correct casing and replace dots with underscores.
							The context translations should be human readable text that helps the AI translating text for the component.
						</div>
						<div v-else>
							Start context defs with 'context_'.
						</div>
						<div v-if="newText.label.startsWith('text')" style="color: orange;">
							Please do not start your keys with 'text.', the system will automatically add that later.
						</div>
					</div>

					<v-row v-for="locale of locales" :key="locale.code" style="padding-left:12px">
						<div class="field left-border" style="margin-top: -10px;">
							<v-label>{{locale.name}}</v-label>
							<LanguageFlag v-model="locale.code" class="flag"/>
							<v-textarea outlined required hide-details style="width:744px" rows="2"
								v-model="newText.values[locale.code]"
							/>
						</div>
					</v-row>
				</div>
			</template>
		</Dialog>

		<!-- Confirm Delete -->
		<Dialog ref="deleteDialog"
			confirmLabel="Delete"
			cancelLabel="Cancel"
			:confirm-handler="deleteTranslation"
			:cancel-handler="onActionCancelled"
			:showClose="false"
			title="Delete Translation"
			height="300px"
			width="600px"
		>
			<template #content>
				<v-row justify="center" align="center" class="pa-10" width="100%">
					<img src="@/assets/icons/icon-warning.svg" style="width:32px;height:32px"/>&nbsp;&nbsp;&nbsp;
					<span class="title">Are you sure you want to delete this translation?<br/>This action is irreversible.</span>
				</v-row>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import SideBar from "@/components/common/SideBar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Dialog from '@/components/common/Dialog.vue'
import { Action } from '@/plugins/enum.js'

export default {
	name: 'TranslationsView',
	components: { Loading, SideBar, Alert, Disclosure, LanguageFlag, Dialog },
	mixins: [ Common ],
	data() {
		return {
			Action: Action,

			applications: [],
			selectedApplication: 'CORE',
			environment: process.env.VUE_APP_ENV,

			selectedObjKey: '',
			originalText: {},
			text: {},
			newText: {
				label: '',
				values: {}
			},
			search: '',
			leftLocale: this.$store.state.defaultLocale,
			rightLocale: this.$store.state.defaultLocale,
			showOnlyMissing: false,
			showOnlyExistingLeft: false,
			showOnlyDifferent: false,
			showOnlyEditing: false,
			showOnlyChanged: false,
			showOnlySame: false,
			editing: {},
			meta: null,
			component: null,
			compSearch: '',
			compSelection: [],
			// TODO: this has a weird structure that does not fit with the rest
			//       of this app.. should we rather extend the main endpoints
			//       or should the ui support this data?
			compare: null, // { liveTranslations: .., stageTranslations: .. }
			// Note that "locales" are actually loaded into the store (from CM?), this only has 4 locales though
			// (de, en, fr, it) - .locales comes from the Common mixin
			additionalLocales: [
				{ code: 'es', name: 'Spanish' },
				{ code: 'ru', name: 'Russian' },
				{ code: 'sv', name: 'Swedish' },
				{ code: 'no', name: 'Norwegian' },
				{ code: 'nl', name: 'Dutch' },
				{ code: 'ca', name: 'Catalan' },
				{ code: 'cs', name: 'Czech' },
				{ code: 'ja', name: 'Japanese' },
				{ code: 'sk', name: 'Slovak' },
				{ code: 'uk', name: 'Ukrainian' },
			],

			progress: 0,
			cancelRequests: false,
			loadingOpenAIRequests: false,
			translatedSoFar: 0,			
			estimatedTimeRemaining: 0,
			averageTimeTaken: 0,
			changeUpdate: 0,
		}
	},
	computed: {
		textLabelError() {
			if (this.runValidation && !this.newText.label.length) {
				return 'Text Label is required'
			} else if (this.existingKeys.find(x => x === this.newText.label)) {
				return 'Text Label is already used'
			}
			return ''
		},
		keys() {
			return Object.keys(this.text[this.leftLocale?.code] ?? {}).sort()
		},
		filteredKeys() {
			let search = this.search?.toLowerCase()
			const exact = search?.startsWith?.('"') && search?.endsWith?.('"')
			if (exact) search = search.substring(1, search.length - 1)
			return this.keys.filter(key => {
				let rightValue = this.text[this.rightLocale?.code][key]
				let leftValue = this.text[this.leftLocale?.code][key]
				const originalValue = this.originalText[this.rightLocale?.code][key]
				if (this.showOnlyMissing && rightValue && !this.editing[key]) return false
				if (this.showOnlyExistingLeft && !leftValue) return false
				if (this.showOnlyDifferent && rightValue == leftValue) return false
				if (this.showOnlyChanged && rightValue == originalValue) return false
				if (this.showOnlySame && rightValue !== leftValue) return false
				rightValue = rightValue?.toLowerCase?.() ?? ''
				leftValue = leftValue?.toLowerCase?.() ?? ''
				// substring search
				if (
					this.search &&
					!key.toLowerCase().includes(search) &&
					!rightValue.includes(search) &&
					!leftValue.includes(search) &&
					!this.editing[key]
				)
					return false
				// exact search
				if (
					exact &&
					this.search &&
					key != search &&
					rightValue != search &&
					leftValue != search &&
					!this.editing[key]
				)
					return false
				if (this.showOnlyEditing && !this.editing[key]) return false
				if (
					this.selectedCompTranslationKeys.length &&
					!this.selectedCompTranslationKeys.includes(key) &&
					!this.editing[key]
				)
					return false
				return true
			})
		},
		rootMeta() {
			if (!this.meta) return []
			if (!this.meta.data) return []

			const meta = this.meta.data

			// build lookups
			const childKeyToParent = {}
			for (const [key, value] of Object.entries(meta)) {
				for (const childKey of value.childKeys ?? []) {
					if (!childKeyToParent[childKey]) childKeyToParent[childKey] = []
					childKeyToParent[childKey].push(key)
				}
			}

			// find root nodes (that dont have a parent)
			const r = []
			for (const [key, value] of Object.entries(meta)) {
				if (!childKeyToParent[key]) {
					r.push(key)
				}
			}

			// TODO: shouldnt the generator already do that? maybe not, because then there will be duplicates in the tree..
			const getNodeForKey = (key, level = 0) => {
				if (level > 5) return 'ERROR:TOODEEP'
				const children = []

				if (key == undefined) {
					// find root objects and add them to the result
					for (const [key, value] of Object.entries(meta)) {
						if (childKeyToParent[key]) continue
						children.push(getNodeForKey(key))
					}
				}
				else if (meta[key]) {
					// find child nodes
					for (const childKey of meta[key].childKeys ?? []) {
						children.push(getNodeForKey(childKey))
					}
				}
				const translationKeys = meta[key]?.translationKeys ?? []
				return { name: `${ key } (${ translationKeys.length })`, id: key, children, translationKeys }
			}

			return getNodeForKey()
		},
		selectedCompKeys() {
			const r = []
			const walk = (nodes) => {
				for (const node of nodes) {
					r.push(node.id)
					if (node.children?.length)
						walk(node.children)
				}
			}
			walk(this.compSelection)
			return r
		},
		selectedCompTranslationKeys() {
			if (!this.meta) return []
			const r = []
			for (const key of this.selectedCompKeys) {
				r.push(...this.meta.data[key]?.translationKeys)
			}
			return r
		},
		textLocales() {
			let r = Object.keys(this.text ?? {}).map(code => {
				const lang = code.substring(0, 2)
				let locale = this.$store.state.locales.find(x => x.code == lang)
				if (!locale) locale = this.additionalLocales.find(x => x.code == lang)
				return { ...locale, code }
			})
			r = this.sortOn(r, x => (x.code.substring(3) ? 'x' : '') + x.code)
			// TODO: do we always need to merge these in?
			if (r.length == 0) return this.locales
			return r
		},
		existingKeys() {
			const r = {}
			for (const locale of this.textLocales) {
				for (const key of Object.keys(this.text?.[locale.code]))
					r[key] = true
			}
			return Object.keys(r)
		},
		changes() {
			this.changeUpdate
			let data = []
			for (const locale of this.textLocales) {
				const translationText = {}
				for (const key of this.keys) {
					if (this.text[locale.code][key] !== this.originalText[locale.code][key]) {
						translationText[key] = this.text[locale.code][key]
					}
				}
				// TODO: the above does not catch deletes - for this we would also have to
				//       iterate over originalText - and use <DELETE> to represent deletes in the data
				data.push({
					locale: locale.code,
					env: locale.env,
					appComponentId: this.selectedApplication,
					translationText,
				})
			}
			return data
		},
		changeCount() {
			let count = 0
			for (const localeChanges of this.changes) {
				count += Object.keys(localeChanges.translationText).length
			}
			return count
		},
		changedLocaleCount() {
			let count = 0
			for (const localeChanges of this.changes) {
				if (Object.keys(localeChanges.translationText).length > 0) count++
			}
			return count
		},
		allContexts() {
			if (!this.text?.[this.rightLocale.code]) return []
			const r = []
			const defaultContextKey = 'context_*'
			const defaultContextValue = this.text[this.rightLocale.code][defaultContextKey]
			for (const key of this.keys) {
				if (key.startsWith('context_')) {
					let value = this.text[this.rightLocale.code][key]
					if (defaultContextValue && key != defaultContextKey)
						value = defaultContextValue + '\n' + value
					let comp = key.substring(8)
					if (comp == '*') comp = 'With default context'
					r.push({ key, comp, value })
				}
			}
			return r
		},
	},
	methods: {
		sortOn(arr, fun) {
			arr.sort((a, b) => fun(a) > fun(b) ? 1 : -1)
			return arr
		},
		validJsonKey(evt) {
			evt = (evt) ? evt : window.event
			// ATT: we currently allow basically any key.
			//      if we would still want to restrict, we could use this:
			/*const validChars = new RegExp("^[a-zA-Z0-9_ \.]*$") 
			if (!validChars.test(evt.key)) {
				evt.preventDefault()
				return false
			}*/
			return true
		},
		goback() {
			this.$router.push('/dashboard')
		},
		showCompareTranslations() {
			this.$router.push('/translations-compare')
		},
		showAddTranslation() {
			this.$refs.addTranslationDialog.show = true
			this.newText = {label:'',values:{}}
		},
		onActionCancelled() {
			this.$refs.addTranslationDialog.show = false
		},
		confirmDelete(objKey) {
			this.selectedObjKey = objKey
			this.$refs.deleteDialog.show = true
		},
		async getApplications() {
			try {
				const res = await this.$httpGet(`/applications?include=0&limit=1000`)
				this.applications = res.applications

				if (this.applications?.length) {
					this.applications = this.applications.filter(x => x.fields.userRoles.de.includes('engineer') === false)
				}
				this.applications.sort((a, b) => (a.fields.title.en > b.fields.title.en) ? 1 : -1)
				this.applications.unshift({sys:{id:'CORE'},fields:{title:{en:'CORE'}}})
			} catch (error) {
				this.showError(error)
			}
		},
		async getTranslationsForApp() {
			this.loading = true
			const translations = await this.$httpGet(`/translations/${ this.selectedApplication }`)

			const text = {}
			if (translations?.length) {
				for (const translation of translations) {
					text[translation.locale] = translation.translationText
				}
			}
			this.originalText = JSON.parse(JSON.stringify(text))

			// TODO: do we really want this?
			for (const locale of this.locales) {
				if (!text[locale.code]) text[locale.code] = {}
			}
			this.text = text
			//this.rightLocale = this.textLocales[0]

			await this.getTranslationMetadataForApp()
			this.loading = false
		},
		async getTranslationMetadataForApp() {
			this.meta = await this.$httpGet(`/translationMetadata/${ this.selectedApplication }`)
		},
		async getTranslationsCompare() {
			this.compare = await this.$httpGet(`/translations-compare`)
		},
		async updateTranslations() {
			this.loading = true
			let data = this.changes

			try {
				await this.$httpPatch(`/translations`, data)
				
				this.successTitle = 'UPDATE TRANSLATIONS'
				this.successDetail = 'Translations updated successfully'

				await this.getTranslationsForApp()
			}
			catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		async addTranslation() {
			this.loading = true
			let data = []

			for (const locale of this.locales) {
				if (!this.text[locale.code]) {
					this.text[locale.code] = {}
				}
				
				this.text[locale.code][this.newText.label] = this.newText.values[locale.code] ? this.newText.values[locale.code] : ''
				
				data.push({
					locale: locale.code,
					env: locale.env,
					appComponentId: this.selectedApplication,
					translationText: this.text[locale.code]
				})
			}

			try {
				// TODO: patch
				await this.$httpPut(`/translations`, data)
				
				this.successTitle = 'CREATE TRANSLATION'
				this.successDetail = 'Translation created successfully'

				await this.getTranslationsForApp()
				
				this.runValidation = false
			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		async deleteTranslation() {
			for (const locale of this.locales) {
				delete this.text[locale.code][this.selectedObjKey]
			}

			await this.updateTranslations()
			
			this.selectedObjKey = ""
		},
		validateTranslation() {
			this.runValidation = true

			if (this.textLabelError.length) {
				return false		
			}

			this.addTranslation()

			return true
		},
		edit($event, key) {
			this.editing[key] = true
			this.$forceUpdate()
			this.$nextTick(() => {
				console.log(this.$refs['e-' + key])
				this.$refs['e-' + key]?.[0]?.focus?.()
			})
		},
		async translateSingleWithChatGpt(sourceLocale, targetLocale, variableName, context) {
			this.loading = true
			try {
				const translationKey = variableName
				const translationString = this.text[sourceLocale.code][translationKey]
				const data = {
					action: 'Translate',
					strings: [{
						[sourceLocale.code]: {
							[translationKey]: translationString,
						},
					}],
					targetLang: [targetLocale.name],
					context: context?.value,
				}
				const res = await this.$httpPost(`/mys-api-proxy/openai/translate`, data)
				this.$set(this.text[targetLocale.code], translationKey, res[translationKey][this.cleanedLocaleCode(targetLocale.code)])
			}
			catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async translateMultipleWithChatGptInChunks(sourceLocale, targetLocale, context = null) {
			try {
				this.loadingOpenAIRequests = true

				const chunkSize = 20
				let total = this.filteredKeys.length

				for (let offset = 0; offset < total; offset += chunkSize) {
					const start = offset
					const end = Math.min(start + chunkSize, total)
					const chunkKeys = this.filteredKeys.slice(start, end)
					let translationCount = 0

					const data = {
						action: 'Translate',
						strings: [],
						targetLang: [targetLocale.name],
						context: context?.value,
					}

					for (const filteredKey of chunkKeys) {
						if (
							!this.text[targetLocale.code][filteredKey] &&
							this.text[sourceLocale.code][filteredKey]
						) {
							data.strings.push({
								[sourceLocale.code]: {
									[filteredKey]: this.text[sourceLocale.code][filteredKey],
								}
							})
						}
					}

					if (data.strings.length === 0) continue

					// Step 1 for time estimation: Keep track of the start time for each request.
					const startTime = new Date().getTime()

					const res = await this.$httpPost(
						`/mys-api-proxy/openai/translate`,
						data
					)

					this.estimateTime(startTime, offset, chunkSize)
					for (const key of Object.keys(res)) {						
						this.editing[key] = true
						this.$set(
							this.text[targetLocale.code],
							key,
							res[key][this.cleanedLocaleCode(targetLocale.code)]
						)
						translationCount++
					}

					this.translatedSoFar += translationCount
					const progress = (this.translatedSoFar / total) * 100
					this.progress = progress

					if (this.cancelRequests) break
				}

				this.successTitle = "UPDATE TRANSLATIONS"
				this.successDetail = `Successfully updated ${this.translatedSoFar} out of ${this.filteredKeys.length} translations from ${sourceLocale.name} to ${targetLocale.name}`

				// removed this as it creates a weirdness in usability
				// this.showOnlyEditing = true
			}
			catch (error) {
				this.showError(error)
			}
			finally {
				this.loadingOpenAIRequests = false
				this.cancelRequests = false
				this.translatedSoFar = 0
			}
		},
		async copyTranslations() {
			let count = 0
			for (const key of this.filteredKeys) {
				try {
					this.text[this.rightLocale.code][key] = this.text[this.leftLocale.code][key]
					this.editing[key] = true
					count++
				}
				catch (error) {
					this.showError(error)
				}
			}
			this.changeUpdate++
			alert(''
				+ 'Copied ' + count + ' translations.\n\n'
				+ (this.showOnlyDifferent ? 'NOTE You have DIFF selected, your action made all of the visible keys same, so your view will be empty.\n\n' : '')
				+ 'Please save changes to persist.'
			)
		},
		// Helper function to format time in milliseconds to a human-readable format.
		formatTime(time) {
			const seconds = Math.floor(time / 1000)
			const minutes = Math.floor(seconds / 60)
			const hours = Math.floor(minutes / 60)
			const days = Math.floor(hours / 24)

			const formattedDays = days > 0 ? `${days}d ` : ''
			const formattedHours = hours > 0 ? `${hours % 24}h ` : ''
			const formattedMinutes = minutes > 0 ? `${minutes % 60}m ` : ''
			const formattedSeconds = `${seconds % 60}s`

			return formattedDays + formattedHours + formattedMinutes + formattedSeconds
		},

		// Context Detection /////
		// TODO: this should be used with chat translations:
		//       show a UI with a picker for [ 'without context', ...contexts ]
		findContextsForTranslation(key) {
			const cfk = this.contextForKey(key)
			const comps = this.findAllParentsForComponents(cfk)
			const uniqueComps = [...new Set(comps)]
			const contexts = []
			const defaultContextKey = 'context_*'
			const defaultContextValue = this.text[this.rightLocale.code][defaultContextKey]
			if (defaultContextValue) {
				contexts.push({ comp: 'With default context', key: defaultContextKey, value: defaultContextValue })
			}
			for (const comp of uniqueComps) {
				const contextKey = 'context_' + comp.replace(/[^a-zA-Z0-9]/g, '_')
				let value = this.text[this.rightLocale.code][contextKey]
				if (!value) continue
				if (defaultContextValue)
					value = defaultContextValue + '\n' + value
				contexts.push({ comp, key: contextKey, value })
			}
			return contexts
		},
		contextForKey(key) {
			return this.findComponentsForKey(key)
		},
		findDirectParentsForComponent(componentKey) {
			const r = []
			for (const [compKey, compValue] of Object.entries(this.meta.data)) {
				if (compValue.childKeys.includes(componentKey)) {
					r.push(compKey)
				}
			}
			return r
		},
		findAllParentsForComponent(componentKey) {
			const r = []
			const parents = this.findDirectParentsForComponent(componentKey)
			r.push(...parents)
			for (const parent of parents) {
				r.push(...this.findAllParentsForComponent(parent))
			}
			return r
		},
		findAllParentsForComponents(components) {
			const r = [...components]
			for (const compKey of components) {
				const parents = this.findAllParentsForComponent(compKey)
				r.push(...parents)
			}
			return r
		},
		findComponentsForKey(key) {
			if (!this.meta || Object.keys(this.meta).length == 0) return []
			const comps = []
			for (const [compKey, compValue] of Object.entries(this.meta.data)) {
				if (!compValue.translationKeys.includes(key)) continue
				comps.push(compKey)
			}
			return comps
		},
		estimateTime(startTime, offset, chunkSize) {
			// Step 1: Keep track of the end time for each request.
			const endTime = new Date().getTime()
			// Step 2: Calculate the time taken for each request.
			const timeTaken = endTime - startTime
			// Step 3: Calculate the total number of requests.
			const totalRequests = this.filteredKeys.length / chunkSize
			// Step 4: Calculate the number of requests completed so far.
			const completedRequests = offset / chunkSize + 1
			// Step 5: Calculate the average time taken for each request.
			const averageTimeTaken = (timeTaken + (completedRequests - 1) * this.averageTimeTaken) / completedRequests
			// Step 6: Update the average time taken variable.
			this.averageTimeTaken = averageTimeTaken
			// Step 7: Calculate the number of requests remaining.
			const remainingRequests = totalRequests - completedRequests
			// Step 8: Calculate the estimated time remaining.
			const estimatedTimeRemaining = remainingRequests * averageTimeTaken
			// Step 9: Update the estimated time remaining variable.
			this.estimatedTimeRemaining = estimatedTimeRemaining
			// const estimatedTimeRemaining = ( remainingRequests * averageTimeTaken) / completedRequests // Step 5: Calculate the estimated time remaining.

			console.log("completed requests: ", completedRequests)
			console.log(`Time taken for ${completedRequests} requests: ${timeTaken}ms`)
			console.log(`Average time taken for each request: ${averageTimeTaken}ms`)
			console.log(`Estimated time remaining for ${remainingRequests} requests: ${estimatedTimeRemaining}ms`)
		},

		cleanedLocaleCode(localeCode) {
			return localeCode.split('_')[0]
		},
	},
	async mounted() {
		this.loading = true
		this.getApplications()
		await this.getTranslationsForApp()
		this.getTranslationsCompare()
		this.loading = false
	},
}
</script>

<style scoped>
.flag { position: absolute; z-index: 10; margin-top: 34px; margin-left: -26px; zoom: 0.8; }

.line { padding: 10px; display: flex; gap: 10px; border-bottom: 1px solid #eee; }
.line .key { width: 30%; word-wrap: break-word; color: gray; }
.line .left { width: 35%; white-space: pre-wrap; color: gray; }
.line .right { width: 35%; white-space: pre-wrap; cursor: pointer; margin-bottom: -20px; }
.line .editing { position: relative; margin: -5px 0 -15px -10px; padding: 0; height: calc(100% - 10px); min-height: 20px; }
.line textarea { width: 100%; height: 100%; min-height: 35px; padding: 5px 10px; outline: 1px solid #bbb; border-radius: 5px; resize: vertical; background: #daf2db; }
.editing .actions { position: absolute; right: 20px; bottom: -15px; z-index: 99; display: flex; gap: 5px; }
.editing .actions .v-btn { padding: 0 !important; height: 25px !important; min-width: 30px !important; }
.editing .actions .ok { background: #4caf50 !important; }
.editing .actions .neutral { background: #7e8691 !important; }
.editing .actions .chatgptButton { background: #fff !important; }
.filterCheckboxes { position: absolute; right: 10px; top: 10px; display: flex; gap: 5px; }
.filterCheckboxes .toggle { padding: 5px !important; min-width: 24px !important; color: silver; }
.filterCheckboxes .toggle.active { color: black; } 
.filterCheckboxes .toggle.small { display: none; border: none; margin-left: -5px; } 
.alertSmall { width: 75%; }

@media screen and (max-width: 960px) {
	.filterCheckboxes { gap: 0; }
	.filterCheckboxes .toggle { display: none; }
	.filterCheckboxes .toggle.small { display: block; } 
}
</style>

<style scoped>
.v-treeview-node__root { min-height: 35px !important; font-size: smaller; }

.progress-bar {
	position: fixed;
	top: 0rem;
	left: 0;
	width: 100%;
	height: 15px;
	/* background: #eee; */
	z-index: 1000;
}
.progress-bar .cancel-button {
	position: absolute;
	right: 0.5rem;
	top: 1rem;
	height: 100%;
	transform: translateX(-50%);
	/* left: 50%; */
	margin-top: 2px;
}
.progress-bar .progress-text {
	position: absolute;
	left: 1rem;
	top: 0.5rem;
	height: 100%;
	left: 50%;
	transform: translateX(-50%);
	color: white;
	/* background: #4caf50;
	border-radius: 5px; */
	padding: 0 0.5rem 0.5rem 0.5rem;
	text-align: center;
}
.overlay {
	position: fixed;
	top: 0;
	left: 17%;
	width: 100%;
	height: 100%;
	z-index: 99;
	background: rgba(0,0,0,0.5);
	pointer-events: none;
	opacity: 0.5;
}
div.warning { padding: 10px; background: #fbf29f !important; color: #333; border-radius: 5px; border: 1px solid orange; font-size: smaller; }
.help { margin-top: 15px; color: gray; }
.header { padding: 4px 16px; color: white; background: gray; }
</style>