<template>
	<v-container class="main pb-12" fluid fill-height>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<v-row align="center" justify="center">
			<v-card id="card" outlined elevation="0">
					<h1>Login to MyServices Toolbox</h1>
					
          <div class="field left-border">
            <div class="pa-1">
              <v-label>E-mail Address</v-label>
              <v-text-field outlined dense 
                v-model.trim="email"
                :error-messages="emailErrors"
                @keyup.enter="login()"
              />
            </div>
           
						<div class="pa-1">
              <v-label>Password</v-label>
              <v-text-field :type="type" outlined dense 
                v-model.trim="password"
                :error-messages="passwordErrors"
                @keyup.enter="login()"
              />
            </div>

            <v-row class="pa-4">
              <v-btn x-large depressed dark class="btn green" @click.prevent="login()">Login</v-btn>
              <v-spacer/>
              <!--<router-link style="z-index: 99;" to="/forgotPassword">Forgot Password</router-link>-->
            </v-row>
					</div>

			</v-card>
		</v-row>

	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'Login',
	components: { Loading, Alert },
	mixins: [ Common ],

	data() {
		return {
			email: '',
			password: '',
			plainTextPassword: false,
			type: 'password',
		}
	},

	computed: {
		emailErrors() {
			if (this.runValidation && !this.email.length) return 'E-mail Address is required'
		},
		passwordErrors() {
			if (this.runValidation && !this.password.length) return 'Password is required'
		},
	},

	beforeDestroy () {
		this.$root.$off('show-dashboard')
	},
	
	methods: {
		async login() {
			this.runValidation = true
			this.loading = true

			try {
				let user = await this.$httpPost('/login', {
					username: this.email,
					password: this.password,
				})

				if (!user?.kc_token) this.showError('Invalid Login Credentials')

				await this.$store.commit('setLoggedInUser', user)
				this.$emit('show-dashboard')

			}
			catch (error) {
				this.showError('Login Failed: ' + (error?.error ?? error?.message ?? error))
			}

			this.loading = false
		},
	}
}
</script>