<template>
	<v-container class="main" fluid fill-width>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="toolbar">
			<v-row align="center">
				<!-- Search -->
				<v-text-field outlined dense clearable hide-details height="48"
					prepend-inner-icon="mdi-magnify"
					placeholder='Search'
					class="ma-rgt"
					v-model="searchString"
					@keyup.enter="search()"
					@click:clear="clearSearch()">
				</v-text-field>

				<!-- Projects -->
				<v-select outlined dense hide-details class="select ma-rgt" 
					style="max-width:15% !important"
					v-model="selectedProject"
					:items="projects" 
					@change="setSelectedProject"
				/>

				<!-- Add New -->
				<v-btn class="btn" small elevation="0" @click="showNewRelease()">
					<v-icon>mdi-plus-circle</v-icon>
					<span class="d-none d-md-inline">Add Release</span>
				</v-btn>
			</v-row>
		</div>

		<v-card class="tableCard" style="margin-top:10px">
			<v-data-table fixed-header hide-default-footer
				v-model="selected"
				:headers="headers"
				:items="releases"
				:items-per-page="limit"
				:mobile-breakpoint="700"
				item-key="name"
				class="elevation-0"
				style="border-radius: 0"
				@click:row="showReleaseDetail"
				>

				<template v-slot:item.title="{ item }">
					{{ $store.state.selectedProject }} Release {{ item.version }}
				</template>
				<template v-slot:item.version="{ item }">
					{{ item.version }}
				</template>
				<template v-slot:item.label="{ item }">
					{{ item.label }}
				</template>
				<template v-slot:item.issueCount="{ item }">
					{{ item.issueCount }}
				</template>
			</v-data-table>

			<v-card class="tableFooter">
				<v-card-actions>
					<TablePaginator v-model="offset" :limit="limit" :total="count" @input="getReleases()" :results="releases" />
				</v-card-actions>
			</v-card>
		</v-card>

	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'ReleasesView',
	components: { Loading, Alert, TablePaginator },
	mixins: [ Common ],
	props: {
		locale: String,
	},
	data() {
		return {
			searchString: '',
			selectedProject: 'MYS',
			offset: 0,
			limit: 100,
			releases: [],
			count: 0,
			selected: [],

			projects: ['ARC','MYS']
		}
	},
	watch: {
		locale() {
			this.selectedLocale = this.locale
			this.$store.state.selectedLocale = this.selectedLocale
		},
	},
	computed: {
		headers () {
			return [
				{ text: 'Release Name', value: "title", sortable: false },
				{ text: 'Version', value: "version", sortable: false,  width: '15%' },
				{ text: 'Label', value: "label", sortable: false,  width: '15%' },
				{ text: '# Issues', value: "issueCount", align: "center", sortable: false, width: '10%' },
			]
		},
	},
	async mounted() {
		this.selectedLocale = this.$store.state.selectedLocale
		await this.setSelectedProject()
	},
	methods: {
		async setSelectedProject() {
			await this.$store.commit('setSelectedProject', this.selectedProject)
			this.getReleases()
		},
		async showReleaseDetail(item) {
			try {
				this.loading = true
				const release = await this.$httpGet(`/release/${item.id}`)
				await this.$store.commit('setSelectedRelease', release)
				this.loading = false
				this.$router.push('/release-detail')
				
			} catch (error) {
				this.showError(error)
			}
		},
		async showNewRelease() {
			await this.$store.commit('setSelectedRelease', null)
			this.$router.push('/release-detail')
		},
		async getReleases() {
			this.loading = true
			try {
				let url = `/releases/${this.$store.state.selectedProject}`
				this.releases = await this.$httpGet(url)
				this.count = 0

				if (this.releases?.length) {
					this.releases.sort((a, b) => (a.version < b.version) ? 1 : -1)
					this.count = this.releases.length
				}
			}
			catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async search() {
			this.loading = true
			try {
				let url = `/search-releases/${this.$store.state.selectedProject}?searchString=${encodeURIComponent(this.searchString)}`
				this.releases = await this.$httpGet(url)
				this.count = 0

				if (this.releases?.length) {
					this.releases.sort((a, b) => (a.version < b.version) ? 1 : -1)
					this.count = this.releases.length
				}
			}
			catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async clearSearch() {
			this.searchString = ''
			await this.getReleases()
		}
	}
}
</script>

