<template>
  <div v-if="show" :class="{backdrop}" @click="backdropClick">
    <div class="dialog-div" :style="`height:${height};width:${width}`">
      <v-toolbar elevation="0" color="white" width="100%" class="dialog-header">
        <v-row style="width:100%" align="center">
          <v-toolbar-title style="margin-left:20px"> {{ title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn v-if="showClose" class="btn" style="height:40px !important;margin-right:10px" elevation="0" @click="closeDialog()">
            <v-icon>mdi-close-circle</v-icon>
             Close
          </v-btn>
        </v-row>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider style="border-color:#dedede"/>

      <v-layout row wrap class="scrollable dialog-content">
        <v-col class="d-flex overflow-x-auto ma-0" style="padding:10px;">
          <slot name="content"/>
        </v-col>
      </v-layout>

      <v-divider/>
      <v-toolbar elevation="0" color="white" width="100%" height="60px" class="dialog-footer">
        <v-spacer></v-spacer>
         <v-btn class="btn ma-rgt" elevation="0" v-if="backLabel"
          @click="goback()"
          v-html="backLabel"
        />
        <v-btn class="btn ma-rgt" elevation="0" v-if="cancelLabel"
          @click="cancel()"
          v-html="cancelLabel"
        />
        <v-btn class="btn red ma-rgt" dark elevation="0" v-if="deleteLabel" 
          @click="remove()"
          v-html="deleteLabel"
        ><v-icon size="24px" color="#fb3640">mdi-delete</v-icon></v-btn>

        <v-btn class="btn green" dark elevation="0"  v-if="confirmLabel" 
          @click="confirm()"
          v-html="confirmLabel"
        />
      </v-toolbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dialog",

  props: {
    title: String,
    confirmLabel: String,
    cancelLabel: String,
    deleteLabel: String,
    backLabel: String,
    confirmHandler: Function,
    cancelHandler: Function,
    closeHandler: Function,
    deleteHandler: Function,
    backHandler: Function,
    showBackdrop: {
      type: Boolean,
      default: true
    },
    closeOnOuterClick: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: true
    },
    height: String,
    width: {
      type: String,
      default: '1100px'
    }
  },
  
  data() {
    return {
      show: false,
      backdrop: this.showBackdrop,
    }
  },

	watch: {
		show(n) {
      if (n === true) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
			this.fixZindexes(n)
		}
	},

  methods: {
    async confirm() {
      let close = true
      if (this.confirmHandler && typeof this.confirmHandler === 'function') {
        close = await this.confirmHandler();
      }
      if (close) {
        this.closeDialog();
      }
    },
    cancel() {
      if (this.cancelHandler && typeof this.cancelHandler === 'function') {
        this.cancelHandler();
      }
      this.closeDialog();
    },
    remove() {
      if (this.deleteHandler && typeof this.deleteHandler === 'function') {
        this.deleteHandler();
      }
      this.closeDialog();
    },
    closeDialog() {
      if (this.closeHandler && typeof this.closeHandler === 'function') {
        this.closeHandler();
      }
      this.show = false;
    },
    goback() {
      if (this.backHandler && typeof this.backHandler === 'function') {
        this.backHandler();
      }
    },
    backdropClick() {
      if (this.closeOnOuterClick) {
        this.closeDialog();
      }
    },
		fixZindexes(n) {
			// raise/restore the whole parent stack's z-index to avoid overlaying siblings
			for (let el = this.$parent; el; el = el.$parent) {
				if (!el?.$el) continue
				if (n) {
					el.$el.style['z-index-backedup'] = true
					el.$el.style['z-index-backup'] = el.$el.style['z-index']
					el.$el.style['z-index'] = 999
				}
				else {
					if (el.$el.style['z-index-backedup']) 
            el.$el.style['z-index'] = 1 //el.$el.style['z-index-backup']
				}
			}
		}
	},
	beforeDestroy () {
		this.fixZindexes(false)
	},
}
</script>

<style scoped lang="scss">
.backdrop {
  position: fixed;
  height: calc(100vh - 56px);
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  top: 56px;
  left: 0;
}

.dialog-div {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 1100px;
  height: min(700px, calc(100vh - 20px));
  max-width: calc(100vw - 20px);

  background-color: #ffffff;
  border-radius: 10px !important;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
  overflow: hidden;

  i {
    margin-right: 5px;
  }

  .dialog-header {
    max-height: 64px;
  }

  .dialog-footer {
    max-height: 60px;
  }
}

.dialogDivPrint {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  height: 100%;

  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.beta-top .corner {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 0px;
    height: 0px;
    border-top: 75px solid #ff7f29;
    border-left: 75px solid transparent;
    z-index: 9;
}

.beta-top .beta {
    position: absolute;
    right: 5px;
    top: 15px;
    text-transform: uppercase;
    color: white;
    font-size: 12pt;
    font-weight: bold;
    z-index: 10;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg); 
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -sand-transform: rotate(45deg);
    transform: rotate(45deg);
}
</style>
