<template>
	<v-container fluid>
    <div>
      <v-label>New Password</v-label>
      <v-text-field id="password" type="password" outlined dense
        v-model="password"
        autocomplete="off"
        :error-messages="passwordErrors" 
        @input="runValidation=true"/>
      <br/>
      <v-label>Confirm Password</v-label>
      <v-text-field id="repeatPassword" type="password" outlined dense
        v-model="repeatPassword"
        autocomplete="off"
        :error-messages="repeatPasswordErrors" 
        @input="runValidation=true"/>
    </div>
  </v-container>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	name: 'ResetPassword',
	mixins: [ Common ],
	data() {
		return {
			password: '',
			repeatPassword: ''
		}
	},
  computed: {
    passwordErrors() {
			if (!this.runValidation) return []
			let errors = []
			if (this.password.length === 0) {
				errors.push('Password is required')
			} else if (this.password.length < 8) {
				errors.push('Password must be at least 8 characters')
			}
			return errors
	},
	repeatPasswordErrors() {
		if (!this.runValidation) return []
		let errors = []
		if (this.repeatPassword.length === 0) {
			errors.push('Repeat Password is required')
		} else if (this.repeatPassword !== this.password) {
			errors.push('Passwords must be identical')
		}
		return errors
	},
  }
}
</script>
