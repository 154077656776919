<template>
	<div style="width:100%">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>

		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<div class="content pa-3" v-if="entry && contentType">
			<div v-for="field of contentType.fields" :key="field.id"
				class="fieldContainer"
				:ref="'section_' + field.id" 
			>
				<TextField v-if="entry.fields[field.id] && field.type === 'String' && field.localized == false" v-model="entry.fields[field.id].de" :def="field" :disabled="!userMayEditFields()"></TextField>
				<TextField v-if="entry.fields[field.id] && field.type === 'Number' && field.localized == false" v-model="entry.fields[field.id].de" :def="field" :disabled="!userMayEditFields()"></TextField>
				<TextI18nField v-if="entry.fields[field.id] && field.type === 'I18nString' && field.localized == true" v-model="entry.fields[field.id]" :def="field" :disabled="!userMayEditFields()"></TextI18nField>
				<BooleanField v-if="entry.fields[field.id] && field.type === 'Boolean'" v-model="entry.fields[field.id].de" :def="field" :disabled="!userMayEditFields()"></BooleanField>
				<AssetField v-if="entry.fields[field.id] && field.type === 'Link' && field.linkType === 'Asset'" v-model="entry.fields[field.id].de" :def="field" :disabled="!userMayEditFields()"></AssetField>
				<TagsField v-if="entry.fields[field.id] && field.type === 'Array' && field.items.type === 'string'" v-model="entry.fields[field.id].de" :def="field" :disabled="!userMayEditFields()"></TagsField>
				<LinksField v-if="entry.fields[field.id] && field.type === 'Array' && field.items.linkType === 'Entry'" v-model="entry.fields[field.id].de" :def="field" 
					:title="field.name" 
					:userMayEditFields="userMayEditFields()"
					:filter="getFilter(field)"
					@subedit="$emit('subedit', $event)"
					@subcreate="$emit('subcreate', $event)"
				></LinksField>
				<LinkField v-if="entry.fields[field.id] && field.type === 'Link' && field.linkType === 'Entry'" v-model="entry.fields[field.id].de" :def="field" 
					:title="field.name" 
					:userMayEditFields="userMayEditFields()"
					@subedit="$emit('subedit', $event)"
					@subcreate="$emit('subcreate', $event)"
				></LinkField>
				
				<!-- TODO: media field -->
				<GeofenceField v-if="entry.fields[field.id] && field.type === 'Object' && field.id === 'geofence'" v-model="entry.fields[field.id].de" :def="field" :disabled="!userMayEditFields()"></GeofenceField>
			</div>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay'

import TextField from './TextField.vue'
import TagsField from './TagsField.vue'
import TextI18nField from './TextI18nField.vue'
import LinksField from './LinksField.vue'
import BooleanField from './BooleanField.vue'
import LinkField from './LinkField.vue'
import AssetField from './AssetField.vue'
import GeofenceField from './GeofenceField.vue'
import Alert from '@/components/common/Alert.vue'
import SideBar from "@/components/common/SideBar"
import Common from "@/mixins/Common"

export default {
	name: 'EntryEditor',
	components: { SideBar, Loading, TextField, TagsField, TextI18nField, LinksField, BooleanField, LinkField, AssetField, GeofenceField, Alert },
	mixins: [ Common ],
	props: {
		value: Object,
		contentType: Object
	},
	data() {
		return {
		loading: false,
		Alert: null,
		entry: null,
		savedObject: null,
	}},
	mounted() {
		if (this.value.isNew) {
			this.entry = this.value
			return
		}
		this.loadEntry()
	},
	methods: {
		showError(error) {
			this.loading = false
			const detail = error?.response?.data?.error ?? error

			this.errorTitle = this.$t('text.ERROR')
			this.errorDetail = detail
		},
		validateForm() {
			if (!this.entry.fields.title.de.length > 0) {
				this.showError('A title for regionMapping is required!')
			}
		},
		getFilter(field) {
			const contentType = field.items?.linkContentType?.length ? field.items.linkContentType[0] : ''
			return { contentType: contentType, search: ''}
		},
		async loadEntry() {
			this.loading = true
			let entry = await this.$httpGet(`/content/entry/${this.value.sys.id}`)
		
			for (const field of this.contentType.fields) {
				if (entry.fields[field.id]) continue
				console.warn(field.id, 'not found in entry, setting empty')
				// TODO: dont we have a very similar code somewhere else? (ClientManager.vue) check and unify
				if (field.type === 'String' && field.localized == false) entry.fields[field.id] = { de: '' }
				if (field.type === 'String' && field.localized == true) entry.fields[field.id] = { de: '', en: '', fr: '', it: '' }
				if (field.type === 'Array') entry.fields[field.id] = { de: [] }
				if (field.type === 'Object') entry.fields[field.id] = { de: {} }
				if (field.type === 'Boolean') entry.fields[field.id] = { de: false }
				if (field.type === 'Number' && field.localized == false) entry.fields[field.id] = { de: 0 }
				// TODO: empty links?
				//if (field.type == 'Link' && field.linkType == 'Entry')
				if (entry.fields[field.id]) continue
				console.error(field.id, 'was not able to repair! type', field.type, 'localized', field.localized)
			}
			this.entry = entry
			this.loading = false
		},
		scrollToSection(field) {
			this.$refs['section_' + field.id][0].scrollIntoView(true)
			window.scrollBy(0, -80)
			this.$refs.sidebar?.closeSidebar?.()
		},
		markIndex(field) {
			this.$refs['index_' + field.id][0].classList.add('hover')
			this.$refs['section_' + field.id][0].classList.add('hover')
		},
		unmarkIndex(field) {
			this.$refs['index_' + field.id][0].classList.remove('hover')
			this.$refs['section_' + field.id][0].classList.remove('hover')
		},
		sendData() {
			// resolve the links
			const dto = {
				sys: this.entry.sys,
				fields: {},
				metadata: this.entry.metadata,
			}
			for (const fieldName in this.entry.fields) {
				const field = this.entry.fields[fieldName]
				dto.fields[fieldName] = {}
				for (const locale in field) {
					let value = field[locale]
					let flatValue = value
					// simple entry links
					if (value?.sys) {
						flatValue = this.link(value)
					}
					// arrays
					if (Array.isArray(value)) {
						flatValue = []
						for (let elem of value) {
							if (elem?.sys) elem = this.link(elem)
							flatValue.push(elem)
						}
					}
					dto.fields[fieldName][locale] = flatValue
				}
			}
			return dto
		},
		deleteEntry() {},
		link(e) {
			if (!e) return undefined
			if (e.sys.type === 'Link') return e
			return {
				sys: {
					id: e.sys.id,
					type: 'Link',
					linkType: e.sys.type ?? 'Entry',
				}
			}
		},
	},
}
</script>

<style scoped>
.fieldContainer > * { margin-bottom: 20px; }
.fieldContainer.hover >>> .Field { border-color: dodgerblue; }
.fieldContainer {
  background-color: white;
}
.header { background-color: #eee; border-bottom: #ddd; border-radius: 10px; }
.header .type { font-size: smaller; color: gray; text-transform: capitalize; display: inline-block; }
.header .sidebar-title { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.index > div { font-size: 10pt; margin: 0 5px; padding-bottom: 5px; display: block; cursor: pointer; }
.index > div.hover { color: rgb(24, 109, 194); }
.content {width: 100%; }
</style>
