<template>
	<v-container class="main" fluid fill-width>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="toolbar">
			<v-row align="center">
				<v-text-field outlined dense clearable hide-details height="48"
					prepend-inner-icon="mdi-magnify"
					placeholder='Search'
					style="padding-right:10px"
					v-model="searchString"
					@keyup.enter="search()"
					@click:clear="clearSearch()">
				</v-text-field>

				<!-- Filters -->
				<div style="padding-left:20px;padding-right:20px">
					<v-menu offset-y bottom left origin="top right" class="filter" :close-on-content-click="false" v-model="isOpened">
						<template v-slot:activator="{ on }" class="filter">
							<v-row justify="end" v-on="on">
								<v-badge color="error" style="z-index:999" overlap v-if="$store.state.filter"></v-badge>
								<v-btn class="btn" elevation="0" x-small>
									<v-icon>mdi-filter</v-icon>
									<span class="d-none d-md-inline">Filter</span>
									<v-icon style="cursor:pointer;">mdi-menu-down</v-icon>
								</v-btn>
							</v-row>
						</template>

						<v-list class="filter">
							<v-list-item class="filter pa-5">
								<v-list-item-title>
									<div @click="closeFilter()">
										<span style="width:100%">User Type</span>
										<v-select filled solo class="btn"
											ref="filterUserTypes"
											v-model="selectedUserTypes"
											:items="userTypes"
											multiple small-chips hide-details
											:menu-props="{ offsetY: true  }">
											<template #selection="{ item }">
												<v-chip>{{item}}</v-chip>
											</template>
										</v-select>
									</div>
								</v-list-item-title>
							</v-list-item>

							<v-divider/>

							<v-row class="pl-6 pr-6 pb-4 pt-6">
								<v-btn class="btn" elevation="0" style="width:48%;margin-right:4%" @click="clearFilter()">Clear Filter</v-btn>
								<v-btn class="btn green" elevation="0" dark style="width:48%;" @click="applyFilter()">Apply Filter</v-btn>
							</v-row>
						</v-list>
					</v-menu>
				</div>
				
				<!-- Add New User -->
				<div style="margin-left:35px;margin-right:10px;width:75px;">
          <v-menu offset-y offset-x style="z-index:1000">
            <template v-slot:activator="{ on }">
              <v-row style="width:75px" align="center" justify="center" v-on="on">
                  <v-btn class="btn" small elevation="0">
										<v-icon>mdi-plus-circle</v-icon>
										<span class="d-none d-md-inline">Add User</span>
									</v-btn>
              </v-row>
            </template>

            <v-list style="width:200px">
						  <v-list-item v-for="newUserType in newUserTypes" :key="newUserType" style="cursor: pointer;" @click="showAddUser(newUserType)">
                <v-list-item-title>
                  <v-icon>mdi-account-plus</v-icon>
                  {{newUserType}}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
			</v-row>
		</div>

		<!-- Content -->
		<v-card class="tableCard" style="margin-top:10px">
			<v-data-table fixed-header hide-default-footer disable-sort 
				v-model="selected"
				:headers="headers"
				:items="users"
				:items-per-page="limit"
				item-key="name"
				class="elevation-0"
        @click:row="showUserDetail"
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.sys.createdAt | formatShortDate }}
        </template>
				<template v-slot:item.updated_at="{ item }">
          {{ item.sys.updatedAt | formatShortDate }}
        </template>
			</v-data-table>
			<v-card class="tableFooter">
				<v-card-actions>
					<TablePaginator v-model="offset" :limit="limit" :total="userCount" @input="getUsers()" :results="users" />
				</v-card-actions>
			</v-card>
		</v-card>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Common from '@/mixins/Common.vue'
import { Action } from '@/plugins/enum.js'

export default {
	name: 'UserManagerView',
	components: { Loading, Alert, TablePaginator },
	mixins: [ Common ],

	data() {
		return {
			Action: Action,
			
			limit: 15,
			offset: this.$store.state.offset ? this.$store.state.offset : 0,
			searchString: this.$store.state.searchString ? this.$store.state.searchString : '',
			selected: [],
			singleSelect: '',
			users: [],
			userCount: 0,
			isOpened: false,

			userTypes: [],
			selectedUserTypes: [],
			newUserTypes: []
		}
	},
	
	computed: {
		headers () {
			return [
				{ text: 'Type', value: "fields.type.de", width: '200', cellClass: "truncate" },
				{ text: 'Login E-Mail', value: "fields.email.de", width: '50%', cellClass: "truncate" },
				{ text: 'Name', value: "fields.name.de", width: '50%', cellClass: "truncate" },
				{ text: 'Created', value: "created_at", width: '175' },
				{ text: 'Updated', value: "updated_at", width: '175' }
			]
		},
	},
	
	mounted() {
		this.selectedUserTypes = this.$store.state.filter?.userTypes?.length ? this.$store.state.filter.userTypes : []
		this.getUsers()
	},
	
	methods: {
		async getUsers() {
			this.loading = true
			try {
				await this.$store.commit('setOffset', this.offset)
				const url = `/users?skip=${this.offset}&limit=${this.limit}&searchString=${encodeURIComponent(this.searchString)}&filter=${JSON.stringify(this.$store.state.filter)}`
				
				let res = await this.$httpGet(url)
				this.users = res.users?.length ? res.users : []
				this.userCount = res.total
				this.userTypes = res.userTypes
				
				this.newUserTypes = JSON.parse(JSON.stringify(this.userTypes))
				this.newUserTypes.splice(this.newUserTypes.findIndex(x => x === 'serviceprovider'), 1)
			}
			catch (error) {
				if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error)
			}
			this.loading = false
		},
		async showUserDetail(item) {
			await this.$store.commit('setSelectedUser', item)
			this.$router.push('/user-detail?action=view')
		},
		showAddUser(userType) {
			this.$router.push(`/user-detail?action=create&userType=${userType}`)
		},
		async search() {
			await this.$store.commit('setSearchString', this.searchString)
			
			if (this.searchString?.length > 0) {
				this.offset = 0
				this.getUsers()
			} else {
				this.clearSearch()
			}
		},
		async clearSearch() {
			this.offset = 0
			this.searchString = ''
			await this.$store.commit('setSearchString', '')
			
			this.getUsers()
		},
		async applyFilter() {
			this.isOpened = false
			this.offset = 0
			const filter = {
				userTypes: this.selectedUserTypes
			}
			await this.$store.commit('setFilter', filter)
			await this.getUsers()
		},
		async clearFilter() {
			this.isOpened = false
			this.selectedUserTypes = []
			this.offset = 0
			await this.$store.commit('setFilter', null)
			await this.getUsers()
		},
		closeFilter() {
			if (!this.dropdownOpen) {
				this.$refs.filterUserTypes.focus()
				this.dropdownOpen = true
			} else {
				this.$refs.filterUserTypes.blur()
				this.dropdownOpen = false
			}
		}
	}
}
</script>

<style scoped>
.v-data-table__mobile-row {
  word-break: break-all;
}
</style>
