<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>
		
		<!-- toolbar -->
		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>
					&nbsp;<h2>{{ clientName() }} Live Sync Detail View</h2>
				</v-row>
			</div>
		</div>

		<SideBar>
			<div class="sidebar-block">
				<p class="sidebar-title">Actions</p>
				<div class="buttonBlock">
					<v-btn v-if="!syncRunning && !syncInProgress" block class="btn green mt-3" elevation="0" dark @click="startLiveSync()"><v-icon>mdi-play-circle</v-icon>Start Live Sync</v-btn>
				</div>
			</div>
		</SideBar>

		<!-- Live Sync List -->
		<div class="pa-3" style="width: 100%">
			<v-card class="tableCard">
				<v-data-table fixed-header hide-default-footer 
					:headers="headers"
					:items="liveSyncRuns"
					:items-per-page="limit"
					:mobile-breakpoint="700"
					class="elevation-0"
					style="border-radius: 0" 
					>

					<template v-slot:item="{item}">
						<tr @click="showLiveSyncDetail(item)">
							<td>
								<span v-if="item.started_at">{{ item.started_at | formatShortDateTime }}</span>
								<span v-if="!item.started_at">-</span>
							</td>
							<td>
								<span v-if="item.finished_at">{{ item.finished_at | formatShortDateTime }}</span>
								<span v-if="!item.finished_at">-</span>
							</td>
							<td align="center" nowrap><Status :status="item.status" /></td>
						</tr>
					</template>
				</v-data-table>

				<v-card class="tableFooter">
					<v-card-actions>
						<TablePaginator v-model="offset" :limit="limit" :total="count" :results="liveSyncRuns" />
					</v-card-actions>
				</v-card>
			</v-card>
		</div>

		<LiveSyncRunProgress ref="liveSyncProgressDialog"
			:height="'90%'"
			:width="'1450px'">
		</LiveSyncRunProgress>	
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import SideBar from "@/components/common/SideBar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Status from '@/components/common/Status.vue' 
import LiveSyncRunProgress from './LiveSyncRunProgress.vue'
import Common from '@/mixins/Common.vue'
import moment from 'moment'

export default {
	name: "LiveSyncDetailView",
	mixins: [ Common ],
	components: { Loading, SideBar, Alert, Disclosure, TablePaginator, Status,LiveSyncRunProgress },
	
	data() {
		return {
			offset: 0,
			limit: 1000,
			count: 0,

			sectionMessage: {
				scheduleInfo: {error:false,warning:false,message:''},
				liveSyncInfo: {error:false,warning:false,message:''}
			},

			client: {},
			liveSyncRuns: [],
			syncDate: '',
      today: moment().toISOString(),
			syncRunning: false,
			syncInProgress: false,
		}
	},

	computed: {
		headers () {
			return [
				{ text: 'Start Time', value: "started_at", sortable: false },
				{ text: 'Finish Time', value: "finished_at", sortable: false },
				{ text: 'Status', value: "status", sortable: false, width: "20px", align: "center" }
			]
		}
	},

	async mounted() {
		await this.checkLiveSyncInProgress()
		await this.getLiveSyncRunsForClient()
	},

	methods: {
		goback() {
			this.$router.push('/livesync')
		},
		clientName() {
			return this.client.title
		},
		async showLiveSyncDetail(item) {
			const res = await this.$httpGet(`/live-sync/${item.id}`)
			await this.$store.commit('setSelectedLiveSync', res.liveSync)
			this.$refs.liveSyncProgressDialog.show = true
		},
		onActionConfirm() {
			this.$refs.liveSyncProgressDialog.show = false
		},
		onActionCancelled() {
			this.$refs.liveSyncProgressDialog.show = false
		},
		async getLiveSyncRunsForClient() {
			this.loading = true
			try {
				const res = await this.$httpGet(`/live-sync/client/${this.$store.state.selectedClient}`)
				this.client = res.client
				this.liveSyncRuns = res.client.liveSyncRuns ?? []
				
				if (this.liveSyncRuns?.length) {
					this.count = this.liveSyncRuns.length

					if (this.liveSyncRuns.find(x => x.status == 'in_progress')) {
						this.syncRunning = true
					}
				}
				
			} catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async startLiveSync() {
			this.loading = true
			try {
				let url = `/live-sync/start/${this.$store.state.selectedClient}`
				const res = await this.$httpPost(url)
				await this.$store.commit('setSelectedLiveSync', res.liveSync)

				this.successTitle = 'LIVE SYNC'
				this.successDetail = 'Live sync started successfully'
				this.syncRunning = true

				//Get running sync and open Dialog
				this.$refs.liveSyncProgressDialog.show = true

				this.getLiveSyncRunsForClient()
			} catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async checkLiveSyncInProgress() {
			this.loading = true
			try {
				let url = `/live-sync-in-progress`
				this.syncInProgress = await this.$httpGet(url)
			
			} catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
	}
}
</script>