<template>
	<v-container class="main" fluid fill-width>
			<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
			<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

			<!-- toolbar -->
			<div :class="toolbarClass()">
				<v-row align="center">
					<v-spacer v-if="toolbarMessage.length === 0"/>
					<p v-html="toolbarMessage" class="mb-0"/>
				</v-row>
			</div>


			<v-card class="tableCard" style="margin-top:10px" v-if="environment !== 'production'">
				<v-data-table fixed-header hide-default-footer
					v-model="selected"
					:headers="headers"
					:items="clients"
					:items-per-page="limit"
					:mobile-breakpoint="700"
					item-key="name"
					class="elevation-0"
					style="border-radius: 0" 
					>

					<template v-slot:item="{item}">
						<tr @click="viewLiveSyncRunsForClient(item)">
							<td>{{item.title}}</td>
							<td align="left">
								<span v-if="item.lastSyncDate">{{item.lastSyncDate | formatShortDateTime }}</span>
								<span v-if="!item.lastSyncDate">-</span>
							</td>
							<td align="center">
								<Status :status="item.status" />
							</td>
						</tr>
					</template>
				</v-data-table>

				<v-card class="tableFooter">
					<v-card-actions>
						<TablePaginator v-model="offset" :limit="limit" :total="count" @input="getLiveClients()" :results="clients" />
					</v-card-actions>
				</v-card>
			</v-card>
		
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Status from '@/components/common/Status.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'LiveSyncView',
	components: { Loading, Alert, TablePaginator, Status },
	mixins: [ Common ],

	data() {
		return {
			offset: this.$store.state.offset,
			limit: 15,
			count: 0,
			selected: [],
			clients: [],
			selectedClientId: '',
			environment: process.env.VUE_APP_ENV,
			syncInProgress: false,
			toolbarMessage: ''
		}
	},

	computed: {
		headers () {
			return [
				{ text: 'Client', value: "client", sortable: false },
				{ text: 'Last Sync Date', value: "lastSyncDate", sortable: false, width: '150px' },
				{ text: 'Status', value: "status", sortable: false, align: 'center', width: '15%' },
			]
		}
	},

	mounted() {
		if (this.environment !== 'production') {
			this.getLiveClients()
			this.checkLiveSyncInProgress()
		}
	},

	methods: {
		toolbarClass() {
			if (this.environment === 'production') {
				this.toolbarMessage = `The live sync application is not available on the production environment.<br/>
				Please access the application on the <a href="https://myservices-toolbox.lakeside.peaksolution.com/" target="_blank">staging environment</a>.`
				return 'toolbar-error'
			}
			if (this.syncInProgress === true) {
				this.toolbarMessage = `There is a live sync in progress.<br/> Please note that you will not be able to run another sync until the currently running sync is complete.`
				return 'toolbar-warning'
			}
			
			return 'toolbar'
		},
		async getLiveClients() {
			this.loading = true
			try {
				await this.$store.commit('setOffset', this.offset)
				let url = `/live-sync/clients?skip=${this.offset}&limit=${this.limit}`
				
				const res = await this.$httpGet(url)

				this.clients = res.clients
				this.count = res.total
			
			} catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async checkLiveSyncInProgress() {
			this.loading = true
			try {
				let url = `/live-sync-in-progress`
				this.syncInProgress = await this.$httpGet(url)
			
			} catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async viewLiveSyncRunsForClient(item) {
			await this.$store.commit('setSelectedClient', item.id)
			await this.$store.commit('setSelectedLiveSync', null)
			this.$router.push('/livesync-detail')
		}
	}
}
</script>

