<template>
	<div>
		<div v-for="loc in locales" :key="loc">
			<TextField v-if="value" v-model="value[loc]" :def="def" :title="def.name + ' - ' + loc" :disabled="disabled"></TextField>
		</div>
	</div>
</template>

<script>
import TextField from './TextField'

export default {
	name: 'TextI18nField',
	components: { TextField },
	props: {
		value: Object,
		def: Object,
		disabled: Boolean
	},
	data() {
		return {
		locales: ['de', 'en', 'fr', 'it']
	}},
	created() {
		for (let loc of this.locales) {
			if (!this.value[loc]) this.value[loc] = ''
		}
	},
}
</script>

<style scoped>
</style>