<template class="justify-center">
  <div style="width:600px">
    <v-row style="height: 72px">
      <v-btn elevation="0" :style="{'width':'72px !important','border':'0 !important','background':application.fields.colour.de + ' !important'}" disabled>
        <v-img style="margin-top:50%;margin-bottom:50%;width:40px;opacity:0.5;" contain :src="application.fields.icon.de.fields.file.de.url"/>
      </v-btn>
    
      <v-btn elevation="0" class="labelButton" disabled>
        {{application.fields.title.en}}
      </v-btn>
  
      <v-btn elevation="0" class="checkboxButton">
        <v-checkbox v-model="selected" hide-details class="mt-0 pa-0" @click="setSelected()"/>
      </v-btn>
    </v-row>
    <br/>
  </div>
</template>


<script>
import Common from '@/mixins/Common'

export default {
  name: "ApplicationSelector",
  
  mixins: [Common],

  props: {
		application: { type: Object },
		selectedApplications: { type: Array }
	},

  data () {
    return {
      selected: false
    }
  },
  
  created() {
    if (this.selectedApplications?.length && this.selectedApplications.find(selectedApp => selectedApp.sys.id === this.application.sys.id)) {
      this.selected = true
    } else {
      this.selected = false
    }
  },

  methods: {
    setSelected() {
      if (this.selected) {
          this.$emit('add-application', this.application);
      } else {
        this.$emit('remove-application', this.application);
      }
    }
  }
}
</script>

<style scoped>
button {
  width: 100% !important;
  border-top-left-radius:10px !important;
  border-top-right-radius:0px !important;
  border-bottom-left-radius:10px !important;
  border-bottom-right-radius:0px !important;
  height: 100% !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  font-family: "Inter", sans-serif;
  font-size: 12pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
  align-items: left !important;
}
.labelButton {
  font-family: "Inter", sans-serif;
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border:1px solid #c2c2c2 !important;
  padding-left:15px !important;
  border-radius: 0px !important;
  border-right-width: 0px !important;
  font-size: 14pt !important;
  text-transform: none !important;
  
  letter-spacing: 0px !important;
  border-radius: 0px;
  width: 50%!important;
  height: 72px !important;
}
.theme--light.v-btn.v-btn--disabled, .theme--light.v-btn.v-btn--disabled .v-btn__loading, .theme--light.v-btn.v-btn--disabled .v-icon {
color: #000000 !important;
}
.checkboxButton {
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border:1px solid #c2c2c2 !important;
  padding-left:15px !important;
  padding-right:15px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius:10px !important;
  border-bottom-right-radius:10px !important;
  height: 48px !important;
  width: 50px !important;
  height: 72px !important;
}
</style>
