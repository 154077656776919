<template>
	<v-container class="main" fluid fill-width>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="toolbar">
			<v-row align="center">
				
			</v-row>
		</div>

		<v-card class="tableCard" style="margin-top:10px">
			<v-data-table fixed-header hide-default-footer disable-sort 
				:headers="headers"
				:items="environments"
				:items-per-page="limit"
				class="elevation-0">

				<template v-slot:item="{item}">
					<tr :class="rowStyle(item)" @click="viewSyncErrors(item)">
						<td>{{item.spaceName}}</td>
						<td>{{item.spaceId}}</td>
						<td>{{item.environmentId}}</td>
						<td style="text-align:right">{{item.syncErrorTotal}}</td>
						<td>
							<v-switch
								v-model="item.syncEnabled"
								:value="item.syncEnabled"
								inset label=""
								color="success"
								@change="isChangeSyncStatus=true;changeSyncStatus(item)"
								hide-details
								class="mt-0"
								style="padding-left:40px"
							/>
						</td>
					</tr>
				</template>
			</v-data-table>

			<v-card class="tableFooter">
				<v-card-actions>
					<TablePaginator v-model="offset" :limit="limit" :total="count" @input="getContentfulEnvironments()" :results="environments" />
				</v-card-actions>
			</v-card>
		</v-card>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'ContentfulSyncView',
	components: { Loading, Alert, TablePaginator },
	mixins: [ Common ],

	data() {
		return {
			offset: this.$store.state.offset,
			limit: 15,
			count: 0,
			environments: [],
			isChangeSyncEnabled: false,
		}
	},

	computed: {
		headers () {
			return [
				{ text: 'Space Name', value: 'spaceName', width:'90%', cellClass: 'truncate' },
				{ text: 'Space ID', value: 'spaceId', width:'200', cellClass: 'truncate' },
				{ text: 'Environment ID', value: 'environmentId', width:'200', cellClass: 'truncate' },
				{ text: 'Errors', value: 'errors', align: 'right', width:'150', cellClass: 'truncate' },
				{ text: 'Sync Enabled', value: 'syncEnabled', align:"center" }
			]
		},
	},

	async mounted() {
		await this.getContentfulEnvironments()
	},

	methods: {
		async getContentfulEnvironments() {
			this.loading = true
			try {
				await this.$store.commit('setOffset', this.offset)
				const res = await this.$httpGet(`/contentful-sync/environments?skip=${this.offset}&limit=${this.limit}`)
				this.environments = res.environments
				this.count = res.total
			} catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async viewSyncErrors(item) {
			if (!this.isChangeSyncStatus) {
				this.$router.push(`/contentfulsync-detail?client=${item.spaceName}&space=${item.spaceId}&environment=${item.environmentId}`)
			}	
		},
		async changeSyncStatus(item) {
			this.loading = true
			try {
				const data = {
					skip: this.offset,
					limit: this.limit,
					spaceName: item.spaceName,
      		spaceId: item.spaceId,
					environmentId: item.environmentId,
					syncEnabled: item.syncEnabled ? true : false
				}

				const res = await this.$httpPost('/contentful-sync/environment', data)
				this.environments = res.environments
				this.count = res.total

				this.loading = false
				this.isChangeSyncStatus = false

			} catch (error) {
				if (error.response?.status === 401) this.$emit("show-login")
				if (error.response) {
					this.showError(error)
					
				}
				else {
					this.successTitle = "SUCCESS"
					this.successDetail = 'Success message'
					this.loading = false
				}
			}
		},
		rowStyle(item) {
      return item.syncEnabled && item.syncErrorTotal > 0 ? 'error-row' : 'clear-row'
    }
	}
}
</script>
