<template>
  <v-tooltip top open-delay="500" color="white" max-width="300px" content-class="tooltip-arrow-top">
    <template v-slot:activator="{ on, attrs }">
      <v-card flat class="card" color="transparent" v-bind="attrs" v-on="on" @click="loadApplication()">
        <v-badge bordered color="error" :content="badgeCount" overlap v-if="badgeCount>0"></v-badge>
        <div class="d-flex align-center img-container pointer" style="height: 70%" :style="{'background':application.fields.colour.de + ' !important'}">
          <v-img style="opacity:0.5;" :height="imgSize" :width="imgSize" contain :src="getAssetUrl(application.fields.icon.de)"/>
        </div>

        <div style="height: 30%" class="d-flex align-center justify-center px-1 text-center label pointer grey--text text--darken-2">
          <span :style="{ 'font-size': fontSize}">{{application.fields.title[locale]}}</span>
        </div>
      </v-card>
    </template>
    <span>{{ application.fields.tooltip ? application.fields.tooltip[locale] : '' }}</span>
  </v-tooltip>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	name: "ApplicationCard",
	mixins: [ Common ],
	props: {
    locale: {
      default: 'en',
      type: String
    },
		application: Object
	},

  data() {
		return {
      badgeCount: 0
    }
  },

  computed: {
    imgSize () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 50
        case 'sm': return 60
        case 'md': return 70
        case 'lg': return 80
        case 'xl': return 80
        default: return 100
      }
    },
    fontSize () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '8pt'
        case 'sm': return '10pt'
        case 'md': return '12pt'
        case 'lg': return '14pt'
        case 'xl': return '16pt'
        default: return '14pt'
      }
    }
  },

  methods: {
    async loadApplication() {
      await this.$store.commit('setSelectedAppComponent', this.application)
      this.$emit('load-application')
    }
    
  }
}
</script>

<style scoped>
.card {
  height: 100%;
  width: 100%;
}
.label {
  width: 100% !important;
  background-color: white !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.img-container {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.pointer {
  cursor: pointer;
}
</style>

<style lang="scss">
.v-badge {
  padding: 0 !important;
  position: absolute !important;
}
.v-tooltip__content {
  color: black !important;
  opacity: 1 !important;
  padding: 24px !important;
  z-index: 999 !important;
}
.tooltip-arrow-top::before {
  border-top: solid 8px white;
  border-right: solid 8px transparent;
  border-left: solid 8px transparent;
  transform: translateX(-50%);
  position: absolute;
  z-index: -21;
  content: '';
  top: 100%;
  left: 50%;
  height: 0;
  width: 0;
}
</style>
