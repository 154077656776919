<template>
	<v-footer absolute class="footer">
		<v-row class="content">
			<v-spacer />
      <label>{{ $store.getters.appVersion }}</label>
		</v-row>
	</v-footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer {
  position: fixed;
	bottom: 0;
	z-index: 5;
  padding: 0px;
  background: #e9e9e9 !important;
  height: 34px;
  width: 100%;
}
.footer .content {
  padding: 20px;
  margin-right: 0px;
}
</style>
