<template>
	<div class="chipLabel"
		:style="{
			'background-color': bgColor,
			'color': textColor,
			'font-weight': 'bold'
		}">
		{{ value.replace('_', ' ') }}
	</div>
</template>

<script>
export default {
	name: 'ChipLabel',
	props: {
		value: String,
		color: String,
		dark: String
	},
	computed: {
		bgColor() {
			return this.color?.length ? this.color : '#efefef'
		},
		textColor() {
			return this.dark === true ? '#ffffff' : '#000000'
		}
	}
}
</script>

<style scoped>
.chipLabel {
	padding: 7px 20px;
	border-radius: 10px;
	font-size: 13pt;
	text-align: center;
	cursor: default;
	line-height: initial;
	max-width: 380px;
}
</style>